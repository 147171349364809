import { useWindowSize } from 'react-use';
import { useEffect, useState } from 'react';
import { devices } from '../types/devices';

export interface UseMediaQueryResult {
  device: string;
}

const useMediaQuery = (): UseMediaQueryResult => {
  const { width } = useWindowSize();
  const [device, setDevice] = useState<string>(devices.desktop);

  useEffect(() => {
    if (width > 1064) setDevice(devices.desktop);
    else setDevice(devices.mobile);
  }, [width]);

  return { device };
};

export default useMediaQuery;
