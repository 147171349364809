import { FC, memo } from 'react';
import {
  Chat, Channel, MessageInput, MessageList, Window, CustomClasses,
} from 'stream-chat-react';
import { DefaultGenerics, StreamChat, Channel as ChannelType } from 'stream-chat';
import { useWindowSize } from 'react-use';
import Modal from '../../components/Modal/Modal';
import ModalHeader from '../../components/Modal/ModalHeader/ModalHeader';
import classNames from './ChatModal.module.css';
import 'stream-chat-react/dist/css/index.css';
import MessageInputComponent from './components/MessageInput/MessageInput';
import MessageComponent from './components/Message/Message';
import useMediaQuery from '../../hooks/useMediaQuery';
import { devices } from '../../types/devices';

export interface ChatModalProps {
  onClose: () => void;
  isOpen: boolean;
  chatClient?: StreamChat<DefaultGenerics> | null;
  channelClient?: ChannelType<DefaultGenerics> | null;
  isDisconnect?: boolean;
}

const ChatModal: FC<ChatModalProps> = memo((props) => {
  const {
    onClose = () => undefined,
    isOpen = false,
    chatClient,
    channelClient,
    isDisconnect = true,
  } = props;

  const { device } = useMediaQuery();
  const { width, height } = useWindowSize();

  const customClasses: CustomClasses = {
    chat: classNames.chat,
    channel: classNames.channel,
    messageList: classNames[`messageList_${device}`],
    message: classNames.message,
  };

  return (
    <div>
      <Modal
        height={device === devices.desktop ? '630px' : '100%'}
        width={device === devices.desktop ? '410px' : `${width - 8}px`}
        isOpen={isOpen}
        position={{
          bottom: device === devices.desktop ? '108px' : '0',
          left: device === devices.desktop ? '44px' : '0',
          right: 'auto',
          top: 'auto',
        }}
      >
        <div style={{ height: device === devices.desktop ? '610px' : '100%' }}>
          <ModalHeader
            title="Meeting Chat"
            onClose={onClose}
          />
          {isDisconnect ? <div>load...</div>
            : <>
              <div className={classNames.divider} />
              <Chat
                customClasses={customClasses}
                client={chatClient as StreamChat}
              >
                <Channel channel={channelClient as ChannelType<DefaultGenerics>}>
                  <Window>
                    <MessageList
                      hasMore
                      disableDateSeparator
                      Message={MessageComponent}
                    />
                    <div className={classNames.divider} />
                    <MessageInput Input={MessageInputComponent} />
                  </Window>
                </Channel>
              </Chat>
            </>}
        </div>
      </Modal>
    </div>
  );
});

export default ChatModal;
