import { FC, useCallback, useMemo } from 'react';
import { Box } from '@mui/material';
import { Participant } from '@voxeet/voxeet-web-sdk/types/models/Participant';
import classNames from './UnionModal.module.css';
import UserConfig from './UserConfig';
import { AudioContextType } from '../../context/AudioProvider/AudioProviderType';
import useMediaQuery from '../../hooks/useMediaQuery';
import { devices } from '../../types/devices';

export interface UserListProps {
  participants: Participant[];
  participantsStatus: AudioContextType['participantsStatus'];
  addIsSpeakingListener: AudioContextType['addIsSpeakingListener'];
  onKickParticipant: (item: Participant) => void;
  isAdmin: boolean;
}

const UserList: FC<UserListProps> = (props) => {
  const {
    participants,
    isAdmin = false,
    participantsStatus,
    onKickParticipant = () => undefined,
    addIsSpeakingListener,
  } = props;

  const hasParticipant = useMemo(() => {
    // eslint-disable-next-line array-callback-return
    return participants.filter((value) => {
      if (value.status !== 'Left' && value.status !== 'Kicked') {
        return value;
      }
    });
  }, [participants]);

  return (
    <Box className={classNames.userList}>
      {hasParticipant.map((participant) => (
        <UserConfig
          addIsSpeakingListener={addIsSpeakingListener}
          isAdmin={isAdmin}
          onKickParticipant={onKickParticipant}
          participantsStatus={participantsStatus}
          participant={participant}
          userName={participant.info.name || ''}
          key={participant.id}
        />
      ))}
    </Box>
  );
};

export default UserList;
