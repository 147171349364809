import { Quaternion, Vector3 } from 'three';
import { System } from '../System';
import { Entity } from '../Entity';
import NetworkTransformComponent from '../components/NetworkTransform.component';
import NetworkObjectComponent from '../components/NetworkObject.component';
import TransformVariable, { TransformVariableType } from '../network/variables/TransformVariable';
import { NetworkObjectStatus } from '../network/NetworkObject';
import SystemObject from '../network/SystemObject';
import { SyncVariableUpdateStatus } from '../network/SyncVariable';
import NetworkAnimatorComponent from '../components/NetworkAnimator.component';
import { AnimatorComponent } from '../components/Animator.component';
import AnimatorVariable, { AnimatorVariableType } from '../network/variables/AnimatorVariable';

export default class NetworkSystem extends System {
  static get code(): string {
    return 'network';
  }

  // use on fixed update
  onUpdate(ts: number) {
    if (!this.app.networkManager) return;

    // TODO: need own system and base NetworkVariableComponent
    // TODO: apply transforms to rigid body
    this.componentManager.getComponentsByType(NetworkTransformComponent).forEach((transformComponent) => {
      const netComponent = transformComponent.entity.getComponent(NetworkObjectComponent);
      if (!netComponent) return;
      if (!transformComponent.enabled) return;

      const variable = (
        transformComponent.variable
        || netComponent.netObject?.getVariableByName<TransformVariableType>(transformComponent.variableName)
      ) as TransformVariable;

      if (!variable) return;
      const { entity } = transformComponent;
      let isSet = false;
      if (transformComponent.isSourceType) {
        isSet = variable.set({
          position: transformComponent.isWorldCoordsSystem
            ? entity.getWorldPosition(new Vector3()) : entity.position.clone(),
          quaternion: transformComponent.isWorldCoordsSystem
            ? entity.getWorldQuaternion(new Quaternion()) : entity.quaternion.clone(),
        });

        const animatorNetComponent = transformComponent.entity.getComponent(NetworkAnimatorComponent);
        const animatorVariable = !animatorNetComponent ? null
          : <AnimatorVariable>netComponent.netObject?.getVariableByName<AnimatorVariableType>(animatorNetComponent.variableName);
        if (animatorNetComponent && animatorVariable) {
          // TODO: fix parent
          const animatorComponent = (animatorNetComponent.entity.children[0] as Entity).getComponent(AnimatorComponent);
          if (!animatorComponent) return;
          if (animatorNetComponent.isSourceType) {
            if (isSet) {
              animatorVariable.set({
                actionName: animatorComponent.actionName,
                parameters: animatorComponent.parameters,
              }, true);
            }
          }
          // else {
          //   animatorVariable.set({
          //     actionName: 'idle',
          //     parameters: {},
          //   }, true);
          // }
        }
      }
      if (transformComponent.isTargetType
        && variable.value
        // && variable.needUpdateStatus === SyncVariableUpdateStatus.NeedUpdateFromNetwork
      ) {
        const { parent } = entity;
        if (transformComponent.isWorldCoordsSystem) {
          this.app.sceneManager.currentThreeScene?.attach(entity);
        }
        entity.position.copy(variable.getInterpolatePosition(Date.now(), ts * 1000, entity.getWorldPosition(new Vector3())));
        // entity.position.copy(variable.value.position);
        entity.quaternion.copy(variable.value.quaternion);
        if (transformComponent.isWorldCoordsSystem) {
          parent?.attach(entity);
        }
        // variable.resetNeedUpdate();
      }
    });

    // animator

    this.componentManager.getComponentsByType(NetworkAnimatorComponent).forEach((animatorNetComponent) => {
      const netComponent = animatorNetComponent.entity.getComponent(NetworkObjectComponent);
      if (!netComponent) return;
      if (!animatorNetComponent.enabled) return;

      const variable = animatorNetComponent.variable
          || <AnimatorVariable>netComponent.netObject?.getVariableByName<AnimatorVariableType>(animatorNetComponent.variableName);
      if (!variable) return;
      // TODO: fix parent
      const animatorComponent = (animatorNetComponent.entity.children[0] as Entity).getComponent(AnimatorComponent);
      if (!animatorComponent) return;
      if (animatorNetComponent.isSourceType) {
        variable.set({
          actionName: animatorComponent.actionName,
          parameters: animatorComponent.parameters,
        });
      }
      if (animatorNetComponent.isTargetType
        && variable.value
        // && variable.needUpdateStatus === SyncVariableUpdateStatus.NeedUpdateFromNetwork
      ) {
        // console.log('set from network', variable.value);
        const value = variable.getInterpolatedValue(Date.now(), ts * 1000, animatorComponent.entity);
        if (value) {
          animatorComponent.actionName = value.actionName;
          animatorComponent.parameters = value.parameters;
        }
        // variable.resetNeedUpdate();
      }
    });

    // sync variable to scene

    // spawn object
    if (this.app.sceneManager.currentThreeScene) {
      this.app.networkManager.objects.filter((obj) => obj.isNeedSpawn && !obj.isOwner()).forEach((obj) => {
        if (obj instanceof SystemObject) {
          obj.setApplication(this.app);
        }
        const entity = obj.spawn() as Entity;
        if (entity) {
          this.app.sceneManager.currentThreeScene?.add(entity);
        }
      });
    }

    this.componentManager.getComponentsByType(NetworkObjectComponent).forEach((netComponent) => {
      if (netComponent.netObject?.status === NetworkObjectStatus.Removed) {
        // TODO: need cleanup
        const { entity } = netComponent;
        setTimeout(() => {
          this.app.componentManager.destroyComponentsForEntity(entity);
          entity.removeFromParent();
        }, netComponent.removeTimeout);
      }
    });

    this.componentManager.getComponentsByType(NetworkObjectComponent).forEach((netComponent) => {
      (netComponent.netObject?.variables || []).forEach((variable) => {
        // TODO: case then in one render frame we have network and local update ?
        if (!variable.autoSync) return;
        if (variable.needUpdateStatus === SyncVariableUpdateStatus.NeedUpdateFromLocal) {
          if (variable.updateFromLocal()) {
            variable.resetNeedUpdate();
          }
        }
        if (variable.needUpdateStatus === SyncVariableUpdateStatus.NeedUpdateFromNetwork
          && !netComponent.netObject?.isWriter(variable)
        ) {
          if (variable.updateFromNetwork()) {
            variable.resetNeedUpdate();
          }
        }
      });
    });
  }
}
