import EventEmitter from 'eventemitter3';
import { v4 as uuidv4 } from 'uuid';
import { Object3D } from 'three';
import { NetworkId } from './types';
import NetworkManager from './NetworkManager';
import { SyncVariable } from './SyncVariable';

export type NetworkObjectSerialized = {
  type: string;
  uuid: string;
  ownerId: string;
  status: NetworkObjectStatus;
  lifeTimeType: NetworkObjectLifeTimeTypes;
  code: string;
  isNeedSpawn: boolean;
};

export enum NetworkObjectLifeTimeTypes {
  Owner = 'Owner',
  Shared = 'Shared',
}

export enum NetworkObjectStatus {
  Active = 'Active',
  Removed = 'Removed',
}

export type NetworkObjectEventTypes = {
  onRemove: () => void;
};

export default class NetworkObject {
  public uuid = '';

  public static type = 'base';

  public ownerId: NetworkId = '';

  public code = '';

  public status = NetworkObjectStatus.Active;

  public lifeTimeType = NetworkObjectLifeTimeTypes.Owner;

  public manager: NetworkManager;

  public isNeedSpawn = true;

  public isCreated = false;

  protected _events: EventEmitter<NetworkObjectEventTypes> = new EventEmitter<NetworkObjectEventTypes>();

  public variables: SyncVariable<any>[] = [];

  public cratedTime = Date.now();

  // public existsInNetwork: NetworkId[] = [];

  constructor(manager: NetworkManager, lifeTimeType = NetworkObjectLifeTimeTypes.Owner) {
    this.manager = manager;
    this.lifeTimeType = lifeTimeType;
    // this.manager.events.on('receiveVariable', this.onVariableReceive, this);
  }

  public get enabled() {
    return this.manager.enabled;
  }

  public reset() {
    if (this.isOwner()) {
      this.variables.forEach((variable) => variable.reset());
    }
  }

  public get isInitialized(): boolean {
    // FIXME: TIME hack
    return this.manager.isRoomHost
      ? this.isCreated && (Date.now() - this.cratedTime > 5000)
      : !this.isNeedSpawn && (Date.now() - this.cratedTime > 4000);
  }

  public isLife(removedOwnerId: NetworkId | null) {
    return this.lifeTimeType === NetworkObjectLifeTimeTypes.Shared
      || (this.ownerId !== removedOwnerId && this.lifeTimeType === NetworkObjectLifeTimeTypes.Owner);
  }

  public get isShared() {
    return this.lifeTimeType === NetworkObjectLifeTimeTypes.Shared;
  }

  public get events(): EventEmitter<NetworkObjectEventTypes> {
    return this._events;
  }

  public broadcastVariables() {
    this.manager.broadcastVariables(this.variables);
  }

  public getRemoteVariable() {
    return this.manager.sendSuccessReceiveNewObject(this);
  }

  // FIXME: need variable type, not value type
  public getVariableByName<T = any>(name: string) {
    return this.variables.find((vr) => vr.name === name) as SyncVariable<T>;
  }

  public getVariableByUuid<T = any>(uuid: string) {
    return this.variables.find((vr) => vr.uuid === uuid) as SyncVariable<T>;
  }

  // TODO: create separate variable collection service
  public addVariable<T = any>(variable: SyncVariable<T>) {
    // TODO: validate name?
    this.variables.push(variable);
    variable.setOwnerNetObject(this);
    variable.events.on('changed', this.onVariableChanged, this);
    // TODO: maybe broadcast init variable OR serialize variable in NetworkObject
    // this.manager.broadcastVariable<T>(variable);
  }

  onVariableChanged<T>({ variable }: { variable: SyncVariable<T> }) {
    variable.writerId = this.manager.networkId;
    // TODO: send via data channel & receive
    // TODO: implement pause state
    this.manager.broadcastVariable<T>(variable);
  }

  public remove() {
    console.warn('remove', this.code);
    // TODO: clear memory ...
    this.status = NetworkObjectStatus.Removed;
    this.reset();
    this.events.emit('onRemove');
  }

  public initialize() {
    this.ownerId = this.manager.networkId;
    this.uuid = uuidv4();
    // this.isNeedSpawn = false;
    // this.existsInsNetwork.push(this.manager.networkId);
    return this;
  }

  public setOwner(id: NetworkId) {
    this.ownerId = id;
  }

  public isOwner() {
    return this.ownerId === this.manager.networkId;
  }

  public isWriter(variable: SyncVariable<any>) {
    return this.manager.networkId === variable.writerId;
  }

  public spawn(): Object3D | undefined {
    if (this.isNeedSpawn && this.enabled) {
      this.isNeedSpawn = false;
      const entity = this.spawnEntity();
      if (!entity) {
        this.isNeedSpawn = true;
      }
      this.getRemoteVariable();
      return entity;
    }
  }

  public spawnEntity(): Object3D | undefined {
    return new Object3D();
  }

  // TODO: create serialize
  public serialize(): NetworkObjectSerialized {
    return {
      type: (this.constructor as any).type,
      uuid: this.uuid,
      ownerId: this.ownerId,
      status: this.status,
      lifeTimeType: this.lifeTimeType,
      code: this.code,
      isNeedSpawn: this.isNeedSpawn,
    };
  }

  public deserialize({
    uuid, ownerId, status, lifeTimeType, code, isNeedSpawn,
  }: NetworkObjectSerialized) {
    this.uuid = uuid;
    this.ownerId = ownerId;
    this.status = status;
    this.lifeTimeType = lifeTimeType;
    this.code = code;
    // this.isNeedSpawn = isNeedSpawn;
    return this;
  }
}
