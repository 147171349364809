interface Button {
  rootIcon: {
    width: number;
    height: number;
  };
  innerIcon: {
    width: number;
    height: number;
  };
}

export const mobileButton: Button = {
  rootIcon: {
    width: 40,
    height: 40,
  },
  innerIcon: {
    width: 12.23,
    height: 12.23,
  },
};

export const desktopButton: Button = {
  rootIcon: {
    width: 56,
    height: 56,
  },
  innerIcon: {
    width: 22.23,
    height: 22.23,
  },
};
