import { Component, ComponentOptions } from '../Component';
import AnimatorVariable from '../network/variables/AnimatorVariable';

export type NetworkAnimatorComponentOptions = ComponentOptions & {
    data?: {
        variable?: AnimatorVariable;
        variableName?: string;
        type?: NetworkAnimatorComponentTypes;
    };
};

export enum NetworkAnimatorComponentTypes {
    Target = 'Target',
    Source = 'Source',
}

export default class NetworkAnimatorComponent extends Component {
    public variable: AnimatorVariable | null;

    public type: NetworkAnimatorComponentTypes;

    public variableName: string;

    public enabled = true;

    static get code(): string {
        return 'netanimator';
    }

    constructor(options: NetworkAnimatorComponentOptions) {
        super(options);
        this.variable = options.data?.variable || null;
        this.variableName = options.data?.variableName || '';
        this.type = options.data?.type || NetworkAnimatorComponentTypes.Target;
    }

    public setSourceType() {
        this.type = NetworkAnimatorComponentTypes.Source;
    }

    public setTargetType() {
        this.type = NetworkAnimatorComponentTypes.Target;
    }

    public get isSourceType() {
        return this.type === NetworkAnimatorComponentTypes.Source;
    }

    public get isTargetType() {
        return this.type === NetworkAnimatorComponentTypes.Target;
    }

    public disable() {
        this.enabled = false;
    }

    public enable() {
        this.enabled = true;
    }
}
