import { Quaternion, Vector3 } from 'three';
import { SyncVariable, SyncVariableSerialized } from '../SyncVariable';
import PositionBuffer from '../interpolation/PositionBuffer';

export type TransformVariableType = {
  position: Vector3;
  quaternion: Quaternion;
};

export default class TransformVariable extends SyncVariable<TransformVariableType> {
  public static type = 'transform';

  public buffer: PositionBuffer = new PositionBuffer();

  constructor(name = 'transform') {
    super(name);
  }

  saveValueFromNetwork(data: SyncVariableSerialized<TransformVariableType>, sendTime: number, receiveTime: number) {
    super.saveValueFromNetwork(data, sendTime, receiveTime);
    if (this.value?.position) {
      this.buffer.setValue(this.value?.position, receiveTime);
    }
  }

  getInterpolatePosition(t: number, ts: number, position: Vector3): Vector3 {
    // TODO: interpolate from position from prev frame to network position
    return this.buffer.getValue(t - 300 - ts);
    // return this.buffer.getPosition(t, ts, position);
  }

  public isChanged(prevValue: TransformVariableType | null, newValue: TransformVariableType) {
    // return true;
    if (!prevValue) return true;
    const eps = 0.03;
    const prevRotation = new Vector3(...prevValue.quaternion.toArray());
    const newRotation = new Vector3(...newValue.quaternion.toArray());
    return prevValue.position.distanceTo(newValue.position) > eps
      || prevRotation.distanceTo(newRotation) > eps;
  }

  public deserializeValue(value: TransformVariableType | null) {
    const pos = value?.position;
    const rot = value?.quaternion as unknown as { _x: number; _y: number; _z: number; _w: number };
    this.value = {
      position: new Vector3(pos?.x, pos?.y, pos?.z),
      quaternion: new Quaternion(rot?._x, rot?._y, rot?._z, rot?._w),
    };
  }
}
