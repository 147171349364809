import * as Three from 'three';
import { Event, Object3D } from 'three';
import { Application } from '../engine/Application';
import { PanelComponent } from '../domain/components/Panel.component';
import NetworkObjectComponent from '../engine/components/NetworkObject.component';
import RaycastComponent from '../domain/components/Raycast.component';
import { DashboardSystem } from '../domain/systems/Dashboard.system/Dashboard.system';
import { DashboardComponent } from '../domain/components/Dashboard.component';
import { PlayerComponent } from '../domain/components/Player.component';
import { Entity } from '../engine/Entity';
import { UIDocumentComponent } from '../engine/components/UIDocument.component';
import { ButtonId } from '../domain/systems/Dashboard.system/enum/ButtonId';
import { SelectPlayerType } from '../types/SelectPlayersType';
import { PlayerTypePanel } from '../types/PlayerType';
import { PlayerControlsSystem } from '../domain/systems/PlayerControls.system';
import { PlayerControlsComponent } from '../domain/components/PlayerControls.component';
import { TPControllerComponent } from '../domain/components/TPController.component';
import { FPControllerComponent } from '../domain/components/FPController.component';
import { InputSystem } from '../engine/systems/InputSystem';

// todo: think about it

class SceneInteraction {
  public app: Application | null;

  public characterEntity: Object3D<Event> | undefined;

  public scene: Three.Scene | undefined | null;

  public camera: Three.Camera | null | undefined;

  constructor(app: Application | null) {
    this.app = app;
    this.characterEntity = this.app?.sceneManager.currentScene?.threeScene.children.find((item) => item.name === 'CharacterEntity');
    this.scene = this.app?.sceneManager.currentThreeScene;
    this.camera = this.app?.camera;
  }

  public showHideSeats(state: boolean): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.scene?.traverse((child: Object3D) => {
      if ((<Three.Mesh> child).isMesh) {
        if ((<any>child).isSeating) {
          child.visible = state;
        }
      }
    });
  }

  public checkPlayerIsSeated = (): void => {
    if (this.app !== null && this.scene) {
      const system = this.app?.getSystem(PlayerControlsSystem);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (this.scene.playerWatchingVideo) {
        if (system) system.moveIsBlock = true;
      } else if (system) system.moveIsBlock = false;
    }
  };

  public checkPlayerIsWatching = (): boolean => {
    let isWatch = false;
    if (this.app !== null && this.camera) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (this.scene.playerWatchingVideo) {
        this.app.componentManager.getComponentsByType(PlayerControlsComponent).forEach((playerControlsComponent) => {
          isWatch = true;
          const tPControllerComponent = playerControlsComponent.entity.getComponentOrFail(TPControllerComponent);
          const fPControllerComponent = playerControlsComponent.entity.getComponentOrFail(FPControllerComponent);
          tPControllerComponent.enabled = false;
          fPControllerComponent.enabled = true;
        });
      } else isWatch = false;
    }
    return isWatch;
  };

  public blockScene(state: boolean): void {
    if (this.app !== null) {
      this.app.componentManager.getComponentsByType(PlayerControlsComponent).forEach((component) => {
        const system = this.app?.getSystem(PlayerControlsSystem);
        if (system) system.sceneIsBlock = state;
      });
    }
  }

  public blockMove(state: boolean): void {
    if (this.app !== null) {
      this.app.componentManager.getComponentsByType(PlayerControlsComponent).forEach((component) => {
        const system = this.app?.getSystem(PlayerControlsSystem);
        if (system) system.moveIsBlock = state;
      });
    }
  }

  public getPlayer(): SelectPlayerType {
    const system = this.app?.getSystem(DashboardSystem);
    let playerData: SelectPlayerType = { networkId: '', isClick: false, ownerId: '' };
    if (this.app !== null) {
      const entities = this.app.componentManager.getComponentsByType(DashboardComponent).map((c) => c.entity);
      entities.forEach((entity) => {
        const raycast = entity.getComponent(RaycastComponent);
        const player = entity.getComponent(NetworkObjectComponent)?.netObject;
        if (raycast?.isActive) {
          system?.togglePanels(true, player?.ownerId || '');
          playerData = { ownerId: player?.ownerId || '', isClick: true, networkId: player?.manager.networkId };
        } else system?.togglePanels(false, player?.ownerId || '');
      });
    }
    return playerData;
  }

  public updateMicro(isAudio: boolean, id: string) {
    if (this.app !== null) {
      const system = this.app.getSystem(DashboardSystem);
      const components = this.app.componentManager.getComponentsByType(PlayerComponent)
        .filter((component) => {
          return (component.entity.parent as Entity).getComponent(NetworkObjectComponent)?.netObject?.ownerId === id;
        });
      components.forEach((component) => {
        if (system) system.updateMicrophone(component, isAudio);
      });
    }
  }

  public updateName(data: PlayerTypePanel): void {
    const system = this.app?.getSystem(DashboardSystem);
    system?.updateName(data.id, data.name);
  }

  public logout(): boolean {
    let click = false;
    if (this.app !== null) {
      const entities = this.app.componentManager.getComponentsByType(PanelComponent).map((c) => c.entity);
      entities.forEach((entity) => {
        const { isActive, state } = entity.getComponentOrFail(RaycastComponent);
        if (isActive && state.intersections) {
          entity.traverse((child) => {
            if (child.name === 'logout') {
              state.intersections?.forEach((value) => {
                if (value.object.parent?.uuid === child.uuid) {
                  click = true;
                }
              });
            }
          });
        }
      });
    }
    return click;
  }

  public toggleAudio = (isAudio: boolean, id: string): void => {
    if (this.app !== null) {
      const system = this.app.getSystem(DashboardSystem);
      const components = this.app.componentManager.getComponentsByType(PlayerComponent)
        .filter((component) => {
          return (component.entity.parent as Entity).getComponent(NetworkObjectComponent)?.netObject?.ownerId === id;
        });
      components.forEach((component) => {
        system?.toggleAudio(component, isAudio);
      });
    }
  };

  public lookAt(position: Three.Vector3): void {
    const system = this.app?.getSystem(DashboardSystem);
    system?.lookAt(position);
  }

  public setHost(isHost: boolean): void {
    if (this.app !== null) {
      this.app.componentManager.getComponentsByType(PlayerComponent).forEach((component) => {
        const document = component.entity.getComponentOrFail(UIDocumentComponent);
        const logout = document.getElementById(ButtonId.Logout);
        const offAudio = document.getElementById(ButtonId.OffAudio);
        const onAudio = document.getElementById(ButtonId.OnAudio);
        if (logout && offAudio && onAudio) {
          logout.visible = isHost;
          if (!isHost) {
            offAudio.position.x = -0.2;
            onAudio.position.x = -0.2;
          }
        }
      });
    }
  }

  public toggleCamera = (): void => {
    if (this.app !== null) {
      this.app.componentManager.getComponentsByType(PlayerControlsComponent).forEach((component) => {
        const system = this.app?.getSystem(PlayerControlsSystem);
        system?.toggleCharacterController(component);
      });
    }
  };

  public moveCharacter = (x: number, y: number): void => {
    if (this.app !== null) {
      this.app.componentManager.getComponentsByType(PlayerControlsComponent).forEach((playerControlsComponent) => {
        const tPControllerComponent = playerControlsComponent.entity.getComponentOrFail(TPControllerComponent);
        const fPControllerComponent = playerControlsComponent.entity.getComponentOrFail(FPControllerComponent);
        const movementDirection = new Three.Vector2(0, 0);

        let controller: TPControllerComponent | FPControllerComponent | undefined;

        if (tPControllerComponent.enabled) controller = tPControllerComponent;
        if (fPControllerComponent.enabled) controller = fPControllerComponent;

        movementDirection.set(x, y);
        if (controller) controller.movementVector.copy(movementDirection);
      });
    }
  };

  public throwStuff = () => {
    if (this.app !== null) {
    }
  };
}

export default SceneInteraction;
