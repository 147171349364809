import { ChangeEvent, FC, useCallback, useState } from 'react';
import classNames from './Input.module.css';

export interface InputProps {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  width: string;
  placeholder: string;
}

const Input: FC<InputProps> = (props) => {
  const {
    onChange = () => undefined,
    width = 'auto',
    placeholder,
  } = props;

  const [value, setValue] = useState<string>('');

  const changeHandler = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    onChange(e);
    setValue(e.target.value);
  }, [value]);

  return (
    <input
      style={{ width }}
      placeholder={placeholder}
      className={classNames.root}
      value={value}
      onChange={changeHandler}
    />
  );
};

export default Input;
