import * as THREE from 'three';
import ThreeMeshUI from 'three-mesh-ui';
import VRControl from 'three-mesh-ui/examples/utils/VRControl.js';
import FontJSON from 'three-mesh-ui/examples/assets/Roboto-msdf.json';
import FontImage from 'three-mesh-ui/examples/assets/Roboto-msdf.png';

class WallOfEcommerce {
  constructor(scene, camera, renderer) {
    this.camera = camera;
    this.scene = scene;
    this.renderer = renderer;
    this.realCamObj = this.scene.realCamObj;

    this.raycaster = new THREE.Raycaster();
    this.textureLoader = new THREE.TextureLoader();
    this.mouse = new THREE.Vector2();
    this.mouse.x = this.mouse.y = null;
    this.container = undefined;
    this.container2 = undefined;
    this.shopCatalog = undefined;
    this.productInfo = undefined;

    this.objsToTest = [];
    this.objsToTestButton = [];
    this.renderer.xr.enabled = true;

    window.addEventListener('pointermove', (event) => {
      this.mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
      this.mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
    });

    window.addEventListener('pointerdown', () => {
      this.selectState = true;
    });

    window.addEventListener('pointerup', () => {
      this.selectState = false;
    });

    window.addEventListener('touchstart', (event) => {
      this.selectState = true;
      this.mouse.x = (event.touches[0].clientX / window.innerWidth) * 2 - 1;
      this.mouse.y = -(event.touches[0].clientY / window.innerHeight) * 2 + 1;
    });

    window.addEventListener('touchend', () => {
      this.selectState = false;
      this.mouse.x = null;
      this.mouse.y = null;
    });

    this.vrControl = VRControl(this.renderer, this.camera, this.scene);

    scene.add(this.vrControl.controllerGrips[0], this.vrControl.controllers[0]);

    this.vrControl.controllers[0].addEventListener('selectstart', () => {
      this.selectState = true;
    });
    this.vrControl.controllers[0].addEventListener('selectend', () => {
      this.selectState = false;
    });
  }

  updatePictureShop(nameProduct) {
    this.textureLoader.load(`/dop/${nameProduct}.svg`, (texture) => {
      this.container.add(
        new ThreeMeshUI.Block({
          height: 145,
          width: 125,
          backgroundTexture: texture,
          border: 0,
          borderColor: new THREE.Color(0xffffff),
        }),
      );
    });
  }

  updatePictureInfo(nameProduct) {
    this.textureLoader.load(`/dop/${nameProduct}.svg`, (texture) => {
      this.container2.add(
        new ThreeMeshUI.Block({
          height: 145,
          width: 125,
          backgroundTexture: texture,
          border: 0,
          borderColor: new THREE.Color(0xffffff),
        }),
      );
    });
  }

  generate() {
    const containerMain = new THREE.Object3D();

    const container = new ThreeMeshUI.Block({
      height: 130,
      width: 110,
      justifyContent: 'center',
      fontSize: 0.07,
      backgroundOpacity: 1,
      backgroundColor: new THREE.Color(0xffffff),
      borderRadius: 11,
    });

    container.backgroundMaterial.depthTest = true;
    container.backgroundMaterial.depthWrite = false;

    const container2 = new ThreeMeshUI.Block({
      height: 130,
      width: 110,
      justifyContent: 'center',
      backgroundOpacity: 1,
      fontSize: 0.07,
      backgroundColor: new THREE.Color(0xffffff),
      borderRadius: 11,
    });

    container2.backgroundMaterial.depthTest = true;
    container2.backgroundMaterial.depthWrite = false;

    const shopCatalog = new ThreeMeshUI.Block({
      height: 620,
      width: 1238,
      alignItems: 'end',
      backgroundOpacity: 1,
      backgroundColor: new THREE.Color(0xffffff),
      borderRadius: 20,
    });

    shopCatalog.backgroundMaterial.depthTest = true;
    shopCatalog.backgroundMaterial.depthWrite = false;
    const productInfo = new ThreeMeshUI.Block({
      height: 777,
      width: 928,
      alignItems: 'end',
      backgroundOpacity: 1,
      backgroundColor: new THREE.Color(0xffffff),
      borderRadius: 0.2,
    });

    productInfo.backgroundMaterial.depthTest = true;
    productInfo.backgroundMaterial.depthWrite = false;

    const exit = new ThreeMeshUI.Block({
      height: 50,
      justifyContent: 'space-around',
      alignItems: 'center',
      width: 300,
      backgroundOpacity: 0.6,
      contentDirection: 'row',
      backgroundColor: new THREE.Color(0xACB7D9),
      borderRadius: 20,
      border: 4,
      borderColor: new THREE.Color(0xACB7D9),
    });

    exit.backgroundMaterial.depthTest = true;
    exit.backgroundMaterial.depthWrite = false;

    const exitIcon = new ThreeMeshUI.Block({
      height: 24,
      width: 24,
      backgroundOpacity: 1,
      borderRadius: 0.2,
    });

    const exit1 = new ThreeMeshUI.Block({
      height: 80,
      justifyContent: 'space-around',
      alignItems: 'center',
      width: 300,
      backgroundOpacity: 0.6,
      contentDirection: 'row',
      backgroundColor: new THREE.Color(0xACB7D9),
      borderRadius: 20,
      border: 4,
      borderColor: new THREE.Color(0xACB7D9),
    });

    exit1.backgroundMaterial.depthTest = true;
    exit1.backgroundMaterial.depthWrite = false;

    const text = new ThreeMeshUI.Block({
      height: 24,
      alignItems: 'center',
      backgroundColor: new THREE.Color(0xACB7D9),
      width: 100,
      fontFamily: FontJSON,
      fontTexture: FontImage,
      backgroundOpacity: 0.1,
    });

    const text1 = new ThreeMeshUI.Block({
      height: 24,
      alignItems: 'center',
      backgroundColor: new THREE.Color(0xACB7D9),
      width: 100,
      fontFamily: FontJSON,
      fontTexture: FontImage,
      backgroundOpacity: 0.1,
    });

    exit.add(text);
    exit1.add(text1);

    text.add(new ThreeMeshUI.Text({ content: 'Close', fontSize: 20 }));
    text1.add(new ThreeMeshUI.Text({ content: 'Close', fontSize: 20 }));

    const exitIcon1 = new ThreeMeshUI.Block({
      height: 24,
      width: 24,
      backgroundOpacity: 1,
      borderRadius: 0.2,
    });

    shopCatalog.add(exit);
    productInfo.add(exit1);
    exit.add(exitIcon);
    exit1.add(exitIcon1);

    this.textureLoader.load('/picture/Icon.png', (texture) => {
      exitIcon.set({ backgroundTexture: texture });
    });

    this.textureLoader.load('/picture/Icon.png', (texture) => {
      exitIcon1.set({ backgroundTexture: texture });
    });

    this.textureLoader.load('/picture/MeshUICatalogue.png', (texture) => {
      shopCatalog.set({ backgroundTexture: texture });
    });

    this.textureLoader.load('/picture/MeshUISpecificProduct.png', (texture) => {
      productInfo.set({ backgroundTexture: texture });
    });

    this.objsToTest.push(container);
    this.objsToTest.push(container2);
    this.objsToTest.push(exit);
    this.objsToTest.push(exit1);

    container.scale.set(0.007, 0.007, 0.007);
    container2.scale.set(0.007, 0.007, 0.007);
    shopCatalog.scale.set(0.0041, 0.0041, 0.0041);
    productInfo.scale.set(0.0035, 0.0035, 0.0035);

    container.rotation.y = 1.6;
    container2.rotation.y = 1.6;
    shopCatalog.rotation.y = 1.6;
    productInfo.rotation.y = 1.6;

    this.container = container;
    this.container2 = container2;

    this.shopCatalog = shopCatalog;
    this.productInfo = productInfo;

    container.setupState({
      state: 'selected',
      onSet: () => {
        this.container.visible = false;
        this.container2.visible = false;
        this.shopCatalog.visible = true;
      },
    });

    const hoveredStateButton = {
      state: 'hovered',
      attributes: {},
      onSet: () => {
        this.updatePictureShop('Btn-shop');
        this.renderer.domElement.style.cursor = 'pointer';
      },
    };

    const idleStateButton = {
      state: 'idle',
      attributes: {},
      onSet: () => {
        this.updatePictureShop('Btn-shop2');
        this.renderer.domElement.style.cursor = 'default';
      },
    };
    container.setupState(idleStateButton);
    container.setupState(hoveredStateButton);
    // =================================
    container2.setupState({
      state: 'selected',
      onSet: () => {
        this.productInfo.visible = true;
        this.container.visible = false;
        this.container2.visible = false;
      },
    });

    container2.setupState({
      state: 'hovered',
      onSet: () => {
        this.updatePictureInfo('Btn-shop-details2');
      },
    });

    container2.setupState({
      state: 'idle',
      attributes: {},
      onSet: () => {
        this.updatePictureInfo('Btn-shop-details');
      },
    });

    //= =================
    exit.setupState({
      state: 'selected',
      onSet: () => {
        this.productInfo.visible = false;
        this.shopCatalog.visible = false;
        this.container.visible = true;
        this.container2.visible = true;
      },
    });

    exit.setupState({
      state: 'hovered',
      onSet: () => {
        this.updatePictureInfo('Btn-shop-details2');
      },
    });

    exit.setupState({
      state: 'idle',
      attributes: {},
      onSet: () => {
        this.updatePictureInfo('Btn-shop-details');
      },
    });
    //= =============
    exit1.setupState({
      state: 'selected',
      onSet: () => {
        this.productInfo.visible = false;
        this.shopCatalog.visible = false;
        this.container.visible = true;
        this.container2.visible = true;
      },
    });

    exit1.setupState({
      state: 'hovered',
      onSet: () => {
        this.updatePictureInfo('Btn-shop-details2');
      },
    });

    exit1.setupState({
      state: 'idle',
      attributes: {},
      onSet: () => {
        this.updatePictureInfo('Btn-shop-details');
      },
    });

    containerMain.add(container);
    containerMain.add(container2);
    containerMain.add(productInfo);
    containerMain.add(shopCatalog);

    this.scene.add(containerMain);
  }

  changePositionRight(position) {
    const { x, y, z } = position;
    this.container2.position.set(x - 0.18, y, z + 2.25);
    this.container.position.set(x - 0.18, y, z + 1);
    this.shopCatalog.position.set(x - 0.18, y + 0.2, z + 1.5);
    this.productInfo.position.set(x - 0.18, y + 0.5, z + 1.5);
  }

  changePositionLeft(position) {
    const { x, y, z } = position;
    this.container2.position.set(x - 0.18, y, z - 2.25);
    this.container.position.set(x - 0.18, y, z - 1);
    this.shopCatalog.position.set(x - 0.18, y + 0.2, z - 1.5);
    this.productInfo.position.set(x - 0.18, y + 0.5, z - 1.5);
  }

  updateButtons() {
    ThreeMeshUI.update();

    let intersect;
    if (this.renderer.xr.isPresenting) {
      this.vrControl.setFromController(0, this.raycaster.ray);
      intersect = this.raycast();
    } else if (this.mouse.x !== null && this.mouse.y !== null) {
      this.raycaster.setFromCamera(this.mouse, this.realCamObj);

      intersect = this.raycast();
    }

    if (intersect && intersect.object.isUI) {
      if (intersect.distance > 10) return;

      if (this.selectState) {
        intersect.object.setState('selected');
      } else {
        intersect.object.setState('hovered');
      }
    }

    this.objsToTest.forEach((obj) => {
      if ((!intersect || obj !== intersect.object) && obj.isUI) {
        obj.setState('idle');
      }
    });

    let intersectButton;
    if (this.renderer.xr.isPresenting) {
      this.vrControl.setFromController(0, this.raycaster.ray);

      intersectButton = this.raycastButton();

      // Position the little white dot at the end of the controller pointing ray
      // if ( intersect ) this.vrControl.setPointerAt( 1, intersect.point );
    } else if (this.mouse.x !== null && this.mouse.y !== null) {
      this.raycaster.setFromCamera(this.mouse, this.realCamObj);

      intersectButton = this.raycastButton();
    }
    if (intersectButton && intersectButton.object.isUI) {
      if (intersectButton.distance > 10) return;

      if (this.selectState) {
        // Component.setState internally call component.set with the options you defined in component.setupState
        intersectButton.object.setState('selected');
      } else {
        // Component.setState internally call component.set with the options you defined in component.setupState
        intersectButton.object.setState('hovered');
      }
    }

    this.objsToTestButton.forEach((obj) => {
      if ((!intersectButton || obj !== intersectButton.object) && obj.isUI) {
        // Component.setState internally call component.set with the options you defined in component.setupState
        obj.setState('idle');
      }
    });
  }

  raycast() {
    return this.objsToTest.reduce((closestIntersection, obj) => {
      const intersection = this.raycaster.intersectObject(obj, true);

      if (!intersection[0]) return closestIntersection;

      if (!closestIntersection || intersection[0].distance < closestIntersection.distance) {
        intersection[0].object = obj;
        if (intersection[0].object.visible === true) return intersection[0];
      }

      return closestIntersection;
    }, null);
  }

  raycastButton() {
    return this.objsToTestButton.reduce((closestIntersection, obj) => {
      const intersection = this.raycaster.intersectObject(obj, true);

      if (!intersection[0]) return closestIntersection;

      if (!closestIntersection || intersection[0].distance < closestIntersection.distance) {
        intersection[0].object = obj;
        return intersection[0];
      }
      return closestIntersection;
    }, null);
  }
}

export { WallOfEcommerce };
