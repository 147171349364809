import { v4 as uuidv4 } from 'uuid';

export type MessageData = {
  type: string;
  payload?: any;
};

export type Message = {
  uid: string;
  sendTime: number;
  receiveTime: number;
  serverSendTime: number;
  timeDiff?: number;
  required: boolean;
  variableUid?: string;
  data: MessageData;
};

export type MessagesByTpe = { [key: string] : Message[] };

export default class MessagesPool {
  public messages: Message[] = [];

  public requiredMessages: Message[] = [];

  public savedMessages: Message[] = [];

  public saveMessage(message: Message) {
    this.savedMessages.push(message);
  }

  public addMessage(message: Message) {
    this.messages.push(message);
  }

  public flushMessages() {
    const messages = [...this.savedMessages];
    this.savedMessages = [];
    return messages;
  }

  public getByUid(uid: string) {
    return this.messages.find((ms) => ms.uid === uid) || this.requiredMessages.find((ms) => ms.uid === uid);
  }

  public removeByUid(uid: string) {
    this.messages = this.messages.filter((ms) => ms.uid !== uid);
    this.requiredMessages = this.requiredMessages.filter((ms) => ms.uid !== uid);
  }

  getMessagesByType(messages: Message[] | null = null): MessagesByTpe {
    const result: MessagesByTpe = {};
    const messagesToProcess = messages || this.messages;
    messagesToProcess.forEach((message) => {
      if (!result[message.data.type]) result[message.data.type] = [];
      result[message.data.type].push(message);
    });
    return result;
  }

  public replaceMessageByType(message: Message) {
    const typedMessages = this.getMessagesByType(this.messages)[message.data.type];
    if (typedMessages && typedMessages.length > 0) {
      typedMessages.forEach((ms) => this.removeByUid(ms.uid));
    }
    this.messages.push(message);
  }

  clearPoolByReceiveTime(timeLimit = 6000) {
    const now = Date.now();
    this.messages = this.messages.filter((message) => now - message.receiveTime < timeLimit);
  }

  clearPoolBySendTime(timeLimit = 8000) {
    // return;
    const now = Date.now();
    this.messages = this.messages.filter((message) => now - message.sendTime < timeLimit);
  }

  clearRequiredMessages() {
    this.requiredMessages = [];
  }

  clear() {
  }

  public static createMessage({ type, required, payload }: MessageData & Pick<Message, 'required'>): Message {
    return {
      sendTime: Date.now(),
      receiveTime: 0,
      serverSendTime: 0,
      uid: uuidv4(),
      required,
      data: {
        type,
        payload,
      },
    };
  }
}
