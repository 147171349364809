import type { ConferenceStatus } from '@voxeet/voxeet-web-sdk/types/models/Conference';
import type { Participant } from '@voxeet/voxeet-web-sdk/types/models/Participant';
import {
  createContext, useState, useEffect, useMemo, useCallback, ReactNode, useRef,
} from 'react';
import VoxeetSDK from '@voxeet/voxeet-web-sdk';
import { ParticipantInfo } from '@voxeet/voxeet-web-sdk/types/models/Options';
import conferenceService from '../../services/dolby/conference';
import { AudioContextType } from './AudioProviderType';
import { PlayerPosition } from '../../constans/PlayerPosition';
import getDolbyToken from '../../api/dolby/auth/getClientToken';
import SceneInteraction from '../../Scene/SceneInteraction';
import { Application } from '../../engine/Application';
import { PlayerComponent } from '../../domain/components/Player.component';
import { CoordinatesType } from '../../types/DolbyTypes/CoordinateType';

type DolbyIoWindow = {
  dolbyio: {
    isInitialized: boolean;
  };
};

const dioWindow = window as Window & typeof globalThis & DolbyIoWindow;
dioWindow.dolbyio = {
  isInitialized: false,
};

export interface CommsProviderProps {
  children: ReactNode;
}

export const AudioContext = createContext<AudioContextType>({} as AudioContextType);

const AudioProvider = ({ children }: CommsProviderProps) => {
  const [participant, setParticipant] = useState<AudioContextType['participant']>(null);
  const [participantsStatus, setParticipantsStatus] = useState<AudioContextType['participantsStatus']>({});
  const [conferenceStatus, setConferenceStatus] = useState<AudioContextType['conferenceStatus']>(null);
  const [participants, setParticipants] = useState<Map<string, Participant>>(new Map());
  const [isAudio, setIsAudio] = useState<boolean>(true);
  const [isConference, setIsConference] = useState<boolean>(false);
  const [isLocalAudioLoading, setIsLocalAudioLoading] = useState(false);

  const [application, setApplication] = useState<Application | null>(null);

  const sceneInteraction = new SceneInteraction(application);

  const [token, setToken] = useState<string>('');
  const [refreshToken, setRefreshToken] = useState('');

  useEffect(() => {
    const map: AudioContextType['participantsStatus'] = {};
    setParticipantsStatus((prev) => {
      participants.forEach((p) => {
        map[p.id] = {
          isSpeaking: !!participantsStatus[p.id]?.isSpeaking,
          isLocal: p.id === participant?.id,
          isRemoteAudio: p.id === participant?.id ? p.audioTransmitting : p.audioReceivingFrom,
          isLocalAudio: prev[p.id] ? prev[p.id].isLocalAudio : true,
        };
      });
      return map;
    });
  }, [participants, participant]);

  const setParticipiantHandler = useCallback((item: Participant) => {
    setParticipant(item);
  }, []);

  const stopParticipantAudio = async (participantItem: Participant): Promise<void> => {
    const p = conferenceService.participants().get(participantItem.id);
    if (p) {
      await conferenceService.stopAudio(p);
      setParticipantsStatus((participants_status) => {
        sceneInteraction.toggleAudio(false, participantItem.info.externalId || '');
        return {
          ...participants_status,
          [participantItem.id]: {
            ...participants_status[participantItem.id],
            isLocalAudio: false,
          },
        };
      });
    }
  };

  const startParticipantAudio = async (participantItem: Participant): Promise<void> => {
    const p = conferenceService.participants().get(participantItem.id);
    if (p) {
      await conferenceService.startAudio(p);
      setParticipantsStatus((participants_status) => {
        sceneInteraction.toggleAudio(true, participantItem.info.externalId || '');
        return {
          ...participants_status,
          [participantItem.id]: {
            ...participants_status[participantItem.id],
            isLocalAudio: true,
          },
        };
      });
    }
  };

  const toggleAudio = async (participantItem: Participant): Promise<void> => {
    if (participantItem) {
      const localUser = conferenceService.participants().get(participantItem.id);
      if (localUser) {
        if (localUser.audioTransmitting) {
          setIsLocalAudioLoading(true);
          await VoxeetSDK.conference.stopAudio(localUser);
          setIsLocalAudioLoading(false);
          setIsAudio(false);
        } else {
          setIsLocalAudioLoading(true);
          await startParticipantAudio(localUser);
          setIsLocalAudioLoading(false);
          setIsAudio(true);
        }
      }
    }
  };

  const resetAudio = () => {
    setIsAudio(true);
  };

  const addIsSpeakingListener = (participantItem: Participant) => {
    const interval = setInterval(() => {
      if (VoxeetSDK.conference.current !== null) {
        conferenceService.isSpeaking(participantItem, (speakingState: boolean) => {
          setParticipantsStatus((participants_status) => {
            const status = participants_status[participantItem.id];
            if (status && status?.isSpeaking !== speakingState) {
              sceneInteraction.updateMicro(speakingState, participantItem.info.externalId || '');
              return {
                ...participants_status,
                [participantItem.id]: {
                  ...status,
                  isSpeaking: speakingState,
                },
              };
            }
            return participants_status;
          });
        });
      }
    }, 500);
    return () => {
      clearInterval(interval);
    };
  };

  const onConferenceStatusChange = (status: ConferenceStatus) => {
    setConferenceStatus(status);
  };

  const onParticipantsChange = (participantItem: Participant) => {
    setParticipants((participantsValue) => {
      const p = participantsValue.get(participantItem.id);
      return new Map(
        participantsValue.set(participantItem.id, {
          ...p,
          ...participantItem,
          audioReceivingFrom: participantItem.audioReceivingFrom,
        } as Participant),
      );
    });
  };

  const closeSession = useCallback(() => {
    return VoxeetSDK.session.close();
  }, []);

  const kickParticipantHandler = useCallback((participantItem: Participant) => {
    VoxeetSDK.conference.kick(participantItem);
    if (participantItem.info.externalId && application !== null) {
      application.networkManager?.sendRemoveUser(participantItem.info.externalId);
      application.componentManager.getComponentsByType(PlayerComponent).forEach((c) => {
        c.isLogoutClick = false;
      });
    }
  }, [application]);

  const openSession = useCallback((data: ParticipantInfo) => {
    return VoxeetSDK.session.open(data);
  }, []);

  const leaveConference = useCallback(async (): Promise<void> => {
    try {
      setParticipantsStatus({});
      setConferenceStatus(null);
      setParticipants(new Map());
    } catch (error) {
      console.log(error);
    }
  }, []);

  const getRefreshToken = useCallback(() => {
    getDolbyToken()
      .then((response) => {
        setToken(response.access_token);
        setRefreshToken(response.refresh_token);
        return response.refresh_token;
      });
  }, []);

  useEffect(() => {
    try {
      const unsubscribers: Array<() => void> = [
        conferenceService.onConferenceStatusChange(onConferenceStatusChange),
        conferenceService.onParticipantsChange(onParticipantsChange),
      ];
      return () => {
        unsubscribers.forEach((u) => u());
      };
    } catch (error) {
      console.log(error);
    }
  }, []);

  const contextValue: AudioContextType = useMemo(
    () => ({
      leaveConference,
      kickParticipantHandler,
      setApplication,
      participant,
      conferenceStatus,
      participants: Array.from(participants.values()),
      participantsStatus,
      isAudio: participant?.id && participantsStatus[participant.id]
        ? !!participantsStatus[participant.id]?.isRemoteAudio
        : isAudio,
      toggleAudio,
      startParticipantAudio,
      isConference,
      setIsConference,
      token,
      refreshToken,
      closeSession,
      getRefreshToken,
      openSession,
      stopParticipantAudio,
      addIsSpeakingListener,
      isLocalAudioLoading,
      resetAudio,
      setParticipiantHandler,
    }),
    [
      participant,
      isConference,
      token,
      refreshToken,
      participants,
      participantsStatus,
      conferenceStatus,
      isAudio,
      isLocalAudioLoading,
    ],
  );

  return (
    <AudioContext.Provider value={contextValue}>{children}</AudioContext.Provider>
  );
};

export default AudioProvider;
