import React, {
  ChangeEvent, FC, useCallback, useEffect, useRef, useState,
} from 'react';
import AvatarFrame from '../../assets/picture/Avatar.png';

const AvatarSettings: FC = (props) => {
  const [avatar, setAvatar] = useState<string>();
  const inputFile = useRef<HTMLInputElement>(null);

  const changeFileHandler = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const { target } = event;
    if (!target) return;
    const { files } = target;
    if (!files) return;
    const reader = new FileReader();
    reader.onload = function (fe) {
      setAvatar(fe.target?.result as string);
    };
    reader.readAsDataURL(files[0]);
  }, []);

  const changeAvatarHandler = useCallback(() => {
    inputFile.current?.click();
  }, []);

  return (
    <div>
      <img
        style={{ width: 54, height: 54, borderRadius: '50%' }}
        src={avatar || AvatarFrame}
        onClick={changeAvatarHandler}
        alt="Current user avatar"
      />
      <div>
        <input
          type="file"
          name="file"
          accept=".jpg, .png, .jpeg"
          style={{ display: 'none' }}
          ref={inputFile}
          onChange={changeFileHandler}
        />
      </div>
    </div>
  );
};

export default AvatarSettings;
