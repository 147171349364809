import * as ThreeVrm from '@pixiv/three-vrm';
import * as Three from 'three';
import * as Ammo from 'ammo.js';
import { Quaternion } from 'three';
import { System } from '../../engine/System';
import { RigidBodyComponent } from '../../engine/components/RigidBody.component';
import { CameraComponent } from '../../engine/components/Camera.component';
import { XRInputSystem } from '../../engine/systems/XRInputSystem';
import { XRFPControllerComponent } from '../components/XRFPController.component';
import { MeshRendererComponent } from '../../engine/components/MeshRenderer.component';
import { AnimatorComponent } from '../../engine/components/Animator.component';
/**
 * XR First person controller
 */
export class XRFPControllerSystem extends System {
  protected lastXRPosePosition: Three.Vector3 = new Three.Vector3();

  // todo: fix bug with prev scene moving, think about it
  protected movementReady = false;

  protected get xRInputSystem(): XRInputSystem {
    return this.app.getSystemOrFail(XRInputSystem);
  }

  static get code(): string {
    return 'x_r_f_p_controller';
  }

  public onXRSessionEnd() {
    this.componentManager.getComponentsByType(XRFPControllerComponent).forEach((component) => {
      component.isInitialized = false;
    });
  }

  public onUpdate(dt: number) {
    if (!this.app.renderer.xr.isPresenting) return;

    this.componentManager.getComponentsByType(XRFPControllerComponent).forEach((component) => {
      this.setupVRMCameraMode(component);

      if (!component.isInitialized && this.app.renderer.xr.getFrame()) {
        this.initializeComponent(component);
        return;
      }

      this.applyHMDMovementDelta(component);
      this.handleYawControl(dt);
      this.handleMovementControl(dt, component);
      this.updateCameraPosition(component);
      this.updateAnimations(component);
    });
  }

  protected initializeComponent(xRFPControllerComponent: XRFPControllerComponent): void {
    this.initComponentRotation(xRFPControllerComponent);
    this.initComponentPosition(xRFPControllerComponent);
    xRFPControllerComponent.isInitialized = true;
  }

  protected handleMovementControl(dt: number, component: XRFPControllerComponent): void {
    const rb = component.entity.getComponentOrFail(RigidBodyComponent);

    const velocityVector = new Three.Vector3(0, 0, 0);

    const { baseVelocity } = component;
    const xVelocity = this.xRInputSystem.getRightXrStandardThumbstick().xAxis * baseVelocity;
    const yVelocity = this.xRInputSystem.getRightXrStandardThumbstick().yAxis * baseVelocity;

    velocityVector.setX(xVelocity);
    velocityVector.setZ(yVelocity);

    if (velocityVector.length() === 0) {
      this.movementReady = true;
    }

    if (!this.movementReady) return;

    velocityVector.clampLength(-baseVelocity, baseVelocity).applyEuler(component.entity.rotation);

    rb.getBtRigidBodyOrFail().setLinearVelocity(new Ammo.btVector3(
      velocityVector.x,
      rb.getBtRigidBodyOrFail().getLinearVelocity().y(),
      velocityVector.z,
    ));
  }

  protected applyHMDMovementDelta(component: XRFPControllerComponent): void {
    const xrPosePosition = this.getXRPosePosition();
    const xrPosePositionDelta = this.lastXRPosePosition.sub(xrPosePosition);
    const cameraEntity = component.getCameraEntityOrFail();
    const { threeCamera } = cameraEntity.getComponentOrFail(CameraComponent);

    this.lastXRPosePosition = xrPosePosition;

    // apply HMD delta position to entity
    component.entity.position.sub(xrPosePositionDelta);

    const cameraDirection = new Three.Vector3(0, 0, -1).applyQuaternion(threeCamera.quaternion);
    const rotor = new Three.Matrix4().lookAt(
      new Three.Vector3(0, 0, 0),
      new Three.Vector3(cameraDirection.x, 0, cameraDirection.z),
      new Three.Vector3(0, 1, 0),
    );

    // sync entity y rotation with HMD (camera)
    component.entity.rotation.setFromRotationMatrix(rotor);

    const rigidBodyComponent = component.entity.getComponentOrFail(RigidBodyComponent);
    const transform = rigidBodyComponent.getBtRigidBodyOrFail().getWorldTransform();
    const origin = transform.getOrigin();
    origin.setX(origin.x() - xrPosePositionDelta.x);
    origin.setZ(origin.z() - xrPosePositionDelta.z);
    transform.setOrigin(origin);

    // apply HMD delta position to rigid body directly
    rigidBodyComponent.getBtRigidBodyOrFail().setWorldTransform(transform);
  }

  protected handleYawControl(dt: number): void {
    const yawValue = this.xRInputSystem.getLeftXrStandardThumbstick().xAxis;

    const yawK = Math.PI / 2;
    const yawAngle = yawK * yawValue * dt;
    const refSpace = this.app.renderer.xr.getReferenceSpace();
    const poseQuaternion = this.getXRPoseQuaternion();
    const rotor = new Quaternion().setFromAxisAngle(new Three.Vector3(0, 1, 0), yawAngle);
    const rotatedPoseQuaternion = rotor.clone().multiply(poseQuaternion.clone());
    const rotationOffset = rotatedPoseQuaternion.multiply(poseQuaternion.clone().invert());

    if (!refSpace) return;

    if (yawValue === 0) return;

    const offset = refSpace.getOffsetReferenceSpace(new XRRigidTransform(
      new Three.Vector4(0, 0, 0, 1),
      rotationOffset,
    ));

    this.app.renderer.xr.setReferenceSpace(offset);
  }

  protected getXRPosePosition(): Three.Vector3 {
    const refSpace = this.app.renderer.xr.getReferenceSpace();

    if (!refSpace) return new Three.Vector3();

    const viewerPose = this.app.renderer.xr.getFrame().getViewerPose(refSpace);

    if (!viewerPose) return new Three.Vector3();

    return new Three.Vector3(
      viewerPose.transform.position.x,
      viewerPose.transform.position.y,
      viewerPose.transform.position.z,
    );
  }

  protected getXRPoseQuaternion(): Three.Quaternion {
    const refSpace = this.app.renderer.xr.getReferenceSpace();

    if (!refSpace) return new Three.Quaternion();

    const viewerPose = this.app.renderer.xr.getFrame().getViewerPose(refSpace);

    if (!viewerPose) return new Three.Quaternion();

    return new Three.Quaternion(
      viewerPose.transform.orientation.x,
      viewerPose.transform.orientation.y,
      viewerPose.transform.orientation.z,
      viewerPose.transform.orientation.w,
    );
  }

  protected initComponentPosition(xRFPControllerComponent: XRFPControllerComponent): void {
    const characterPosition = xRFPControllerComponent.entity.position;
    const { cameraEntity } = xRFPControllerComponent;

    const xRPosePosition = this.getXRPosePosition();

    cameraEntity.position.copy(characterPosition.clone().add(xRPosePosition));

    this.lastXRPosePosition.copy(xRPosePosition);
  }

  protected initComponentRotation(xRFPControllerComponent: XRFPControllerComponent): void {
    const refSpace = this.app.renderer.xr.getReferenceSpace();
    if (!refSpace) return;

    const xRPoseQuaternion = this.getXRPoseQuaternion();

    const characterDirection = new Three.Vector3(0, 0, -1)
      .applyQuaternion(xRFPControllerComponent.entity.quaternion)
      .applyQuaternion(xRPoseQuaternion.invert());
    const yRotor = new Three.Matrix4().lookAt(
      new Three.Vector3(0, 0, 0),
      new Three.Vector3(characterDirection.x, 0, characterDirection.z),
      new Three.Vector3(0, 1, 0),
    );
    const yEuler = new Three.Euler(0, 0, 0, 'YXZ').setFromRotationMatrix(yRotor);

    const targetQuaternion = xRPoseQuaternion.clone().premultiply(new Three.Quaternion().setFromAxisAngle(
      new Three.Vector3(0, 1, 0),
      yEuler.y,
    ));
    const rotationOffset = xRPoseQuaternion.clone().multiply(targetQuaternion.invert());

    const offset = refSpace.getOffsetReferenceSpace(new XRRigidTransform(
      new Three.Vector4(0, 0, 0, 1),
      rotationOffset,
    ));

    this.app.renderer.xr.setReferenceSpace(offset);
  }

  protected setupVRMCameraMode(component: XRFPControllerComponent): void {
    if (!component.avatarEntity) return;

    const cameraComponent = component.getCameraEntityOrFail().getComponentOrFail(CameraComponent);
    this.app.renderer.xr.getCamera().layers.enable(ThreeVrm.VRMFirstPerson.DEFAULT_FIRSTPERSON_ONLY_LAYER);
    this.app.renderer.xr.getCamera().layers.disable(ThreeVrm.VRMFirstPerson.DEFAULT_THIRDPERSON_ONLY_LAYER);
    this.app.renderer.xr.getCamera().cameras.forEach((camera) => {
      camera.layers.enable(ThreeVrm.VRMFirstPerson.DEFAULT_FIRSTPERSON_ONLY_LAYER);
      camera.layers.disable(ThreeVrm.VRMFirstPerson.DEFAULT_THIRDPERSON_ONLY_LAYER);
    });
  }

  // todo: refactoring
  protected updateCameraPosition(xRFPControllerComponent: XRFPControllerComponent): void {
    const headPosition = xRFPControllerComponent.entity.position.clone(); // entity center
    const poseQuaternion = this.getXRPoseQuaternion();

    const vrm = xRFPControllerComponent?.avatarEntity?.getComponentOrFail(MeshRendererComponent).getVRM();

    if (vrm && vrm.humanoid && poseQuaternion) {
      const head = vrm.humanoid.getBoneNode('head');

      if (!head) return;

      head
        .applyQuaternion(xRFPControllerComponent.entity.quaternion.clone().invert())
        .applyQuaternion(poseQuaternion);

      headPosition.copy(head.getWorldPosition(new Three.Vector3()));
      const eyesOffset = new Three.Vector3(0, 0, -0.15).applyQuaternion(xRFPControllerComponent.entity.quaternion);
      headPosition.add(eyesOffset);
    }

    xRFPControllerComponent.getCameraEntityOrFail().position
      .copy(headPosition)
      .sub(this.lastXRPosePosition);
  }

  protected updateAnimations(component: XRFPControllerComponent): void {
    const avatarAnimatorComponent = component.getAvatarEntityOrFail().getComponentOrFail(AnimatorComponent);
    const velocity = component.baseVelocity;
    const movementVector = new Three.Vector3(
      this.xRInputSystem.getRightXrStandardThumbstick().xAxis,
      0,
      this.xRInputSystem.getRightXrStandardThumbstick().yAxis,
    );

    if (movementVector.length() === 0) {
      avatarAnimatorComponent.actionName = 'idle';
      return;
    }

    avatarAnimatorComponent.actionName = 'walk';
    const walkVelocityMultiplier = velocity / component.baseVelocity;
    const movementMultiplier = movementVector.clone().clampLength(0, 1).length();
    const normalizedMovement = movementVector.clone().normalize();

    const { parameters } = avatarAnimatorComponent;

    parameters.forwardWeight = normalizedMovement.z < 0 ? Math.abs(normalizedMovement.z) : 0;
    parameters.backwardWeight = normalizedMovement.z > 0 ? Math.abs(normalizedMovement.z) : 0;

    if (parameters.backwardWeight > 0) {
      parameters.leftBackStrafeWeight = normalizedMovement.x > 0 ? Math.abs(normalizedMovement.x) : 0;
      parameters.rightBackStrafeWeight = normalizedMovement.x < 0 ? Math.abs(normalizedMovement.x) : 0;
      parameters.leftStrafeWeight = 0;
      parameters.rightStrafeWeight = 0;
    } else {
      parameters.leftBackStrafeWeight = 0;
      parameters.rightBackStrafeWeight = 0;
      parameters.leftStrafeWeight = normalizedMovement.x < 0 ? Math.abs(normalizedMovement.x) : 0;
      parameters.rightStrafeWeight = normalizedMovement.x > 0 ? Math.abs(normalizedMovement.x) : 0;
    }

    parameters.speed = movementMultiplier * walkVelocityMultiplier;
    parameters.strafeSpeed = movementMultiplier * walkVelocityMultiplier;
    parameters.backStrafeSpeed = movementMultiplier * walkVelocityMultiplier * -1;
  }
}
