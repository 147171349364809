import EventEmitter from 'eventemitter3';
import { AnimationAction, AnimationClip, AnimationMixer } from 'three';
import * as THREE from 'three';
import { Component, ComponentEventTypes, ComponentOptions } from '../../engine/Component';
import { MeshRendererComponent } from '../../engine/components/MeshRenderer.component';

export type AnimationComponentOptions = ComponentOptions & {
  data?: {
    animations: AnimationClip[];
  };
};

export type AnimationComponentEventTypes = ComponentEventTypes & {
  ready: () => void;
};

export class AnimationComponent extends Component {
  public animations: AnimationClip[] = [];

  protected _events: EventEmitter<AnimationComponentEventTypes> = new EventEmitter<AnimationComponentEventTypes>();

  public mixer: AnimationMixer;

  static get code(): string {
    return 'animation';
  }

  constructor(options: AnimationComponentOptions) {
    super(options);
    this.mixer = new THREE.AnimationMixer(this.entity);
    if (!options.data?.animations) {
      const mesh = this.entity.getComponentOrFail(MeshRendererComponent);
      mesh.events.once('contentAdded', ({ content, animations }) => {
        this.animations = animations || [];
        this.events.emit('ready');
      });
    } else {
      this.animations = options.data?.animations || [];
      this.events.emit('ready');
    }
  }

  public get events(): EventEmitter<AnimationComponentEventTypes> {
    return this._events;
  }

  getActionByName(name: string): AnimationAction {
    return this.mixer.clipAction(this.findClipByName(name));
  }

  findClipByName(name: string): AnimationClip {
    return THREE.AnimationClip.findByName(this.animations, name);
  }

  playAction(name: string): void {
    this.getActionByName(name).play();
  }

  stopAction(name: string): void {
    this.getActionByName(name).stop();
  }

  update(ts: number) {
    this.mixer.update(ts);
  }
}
