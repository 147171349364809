import { Object3D } from 'three/src/core/Object3D';
import * as THREE from 'three';
import NetworkObjectComponent from '../../../engine/components/NetworkObject.component';
import NetworkTransformComponent, {
  NetworkTransformComponentTypes,
} from '../../../engine/components/NetworkTransform.component';
import { Application } from '../../../engine/Application';
import NetworkManager from '../../../engine/network/NetworkManager';
import TransformVariable, { TransformVariableType } from '../../../engine/network/variables/TransformVariable';
import { Entity } from '../../../engine/Entity';
import SystemObject from '../../../engine/network/SystemObject';
import SpeakerVariable, { SpeakerVariableType } from '../variables/SpeakerVariable';
import { AssetSourceType, MeshRendererComponent } from '../../../engine/components/MeshRenderer.component';
import { AnimatorComponent } from '../../../engine/components/Animator.component';
import { TPControllerComponent } from '../../components/TPController.component';
import { FPControllerComponent } from '../../components/FPController.component';
import { XRFPControllerComponent } from '../../components/XRFPController.component';
import { ColliderComponent, ColliderType } from '../../../engine/components/Collider.component';
import { PlayerControlsComponent } from '../../components/PlayerControls.component';
import {
  RigidBodyActivationState,
  RigidBodyComponent,
  RigidBodyType,
} from '../../../engine/components/RigidBody.component';
import CollisionFilters from '../../constants/collisionFilters';
import { PrimitiveComponent } from '../../../engine/components/Primitive.component';
import { DashboardSystem } from '../../systems/Dashboard.system/Dashboard.system';
import RaycastComponent from '../../components/Raycast.component';
import { DashboardComponent } from '../../components/Dashboard.component';
import AnimatorVariable, { AnimatorVariableType } from '../../../engine/network/variables/AnimatorVariable';
import NetworkAnimatorComponent, {
  NetworkAnimatorComponentTypes,
} from '../../../engine/components/NetworkAnimator.component';

export default class CharacterObject extends SystemObject {
  public static type = 'character';

  static register(manager: NetworkManager) {
    manager.objectsTypes[CharacterObject.type] = CharacterObject;
  }

  static buildNetworkObject(app: Application): CharacterObject | null {
    if (!app.networkManager) {
      return null;
    }
    const netObj = app.networkManager?.buildNetObject<CharacterObject>(CharacterObject.type);
    netObj.app = app;
    netObj.addVariables();
    return netObj;
  }

  public addVariables() {
    this.addVariable<TransformVariableType>(new TransformVariable());
    this.addVariable<SpeakerVariableType>(new SpeakerVariable());
    this.addVariable<AnimatorVariableType>(new AnimatorVariable());
  }

  public attachToEntity(entity: Entity,
    transformType = NetworkTransformComponentTypes.Source,
    animatorType = NetworkAnimatorComponentTypes.Source) {
    entity.addComponent(NetworkObjectComponent, {
      netObject: this,
      // special fo reconnect
      removeTimeout: 2500,
    });
    entity.addComponent(NetworkTransformComponent, {
      variableName: 'transform',
      type: transformType,
    });
    entity.addComponent(NetworkAnimatorComponent, {
      variableName: 'animator',
      type: animatorType,
    });
  }

  static createCharacterEntity(app: Application) {
    const characterEntity = app.entityManager.makeEntity();
    characterEntity.rotation.order = 'YXZ';
    // characterEntity.position.y = 2;
    characterEntity.position.set(-0, 2, -7);
    characterEntity.name = 'CharacterEntity';
    return characterEntity;
  }

  static buildAvatarEntity(app: Application, needFirstPersonSetup = true) {
    const avatarEntity = app.entityManager.makeEntity();

    avatarEntity.addComponent(MeshRendererComponent, {
      sourceData: {
        type: AssetSourceType.VRM,
        url: 'avatars/VRM_model_FINAL/Female3.vrm',
        // url: '/assets/AliciaSolid.vrm', // nice girl
      },
      needFirstPersonSetup,
    });

    avatarEntity.addComponent(AnimatorComponent, {
      initialActionName: 'idle',
      animationSources: [
        { url: 'avatars/Animations/walk.fbx', clipName: 'walk' },
        { url: 'avatars/Animations/idle.fbx', clipName: 'idle' },
        { url: 'avatars/Animations/back.fbx', clipName: 'back' },
        { url: 'avatars/Animations/left.fbx', clipName: 'left' },
        { url: 'avatars/Animations/right.fbx', clipName: 'right' },
        { url: 'avatars/Animations/seated.fbx', clipName: 'seated' },
        { url: 'avatars/Animations/cheer.fbx', clipName: 'cheer' },
      ],
      actions: [
        {
          name: 'walk',
          clipsData: [
            {
              name: 'walk',
              clipName: 'walk',
              bindings: {
                activeWeight: 'forwardWeight',
                speedMultiplier: 'speed',
              },
            },
            {
              name: 'back',
              clipName: 'back',
              resizeTo: 0.9666666388511658,
              startAt: 0.96 / 2,
              bindings: {
                activeWeight: 'backwardWeight',
                speedMultiplier: 'speed',
              },
            },
            {
              name: 'left',
              clipName: 'left',
              resizeTo: 0.9666666388511658,
              bindings: {
                activeWeight: 'leftStrafeWeight',
                speedMultiplier: 'speed',
              },
            },
            {
              name: 'right',
              clipName: 'right',
              resizeTo: 0.9666666388511658,
              startAt: 0.96 / 2,
              bindings: {
                activeWeight: 'rightStrafeWeight',
                speedMultiplier: 'speed',
              },
            },
            {
              name: 'leftBack',
              clipName: 'left',
              resizeTo: 0.9666666388511658,
              startAt: 0.96 / 2,
              bindings: {
                activeWeight: 'leftBackStrafeWeight',
                speedMultiplier: 'backStrafeSpeed',
              },
            },
            {
              name: 'rightBack',
              clipName: 'right',
              resizeTo: 0.9666666388511658,
              bindings: {
                activeWeight: 'rightBackStrafeWeight',
                speedMultiplier: 'backStrafeSpeed',
              },
            },
          ],
        },
        {
          name: 'idle',
          clipsData: [{
            name: 'idle',
            clipName: 'idle',
          }],
        },
        {
          name: 'seated',
          clipsData: [{
            name: 'seated',
            clipName: 'seated',
            bindings: {
              activeWeight: 'seatedWeight',
            },
          }],
        },
        {
          name: 'cheer',
          clipsData: [{
            resizeTo: 3.6,
            startAt: 0.1,
            name: 'cheer',
            clipName: 'cheer',
            bindings: {
              activeWeight: 'cheerWeight',
            },
          }],
        },
      ],
      parameters: {
        speed: 1,
        backStrafeSpeed: 1,
        forwardWeight: 1,
        backwardWeight: 1,
        leftStrafeWeight: 1,
        rightStrafeWeight: 1,
        leftBackStrafeWeight: 1,
        rightBackStrafeWeight: 1,
        seatedWeight: 1,
        cheerWeight: 1,
      },
    });
    avatarEntity.position.y = -1;

    return avatarEntity;
  }

  static buildEntity(app: Application, characterEntity: Entity, cameraEntity: Entity) {
    const avatarEntity = CharacterObject.buildAvatarEntity(app);

    const tpControllerComponent = characterEntity.addComponent(TPControllerComponent);
    tpControllerComponent.cameraEntity = cameraEntity;
    tpControllerComponent.avatarEntity = avatarEntity;

    tpControllerComponent.lookAtEntity = app.entityManager.makeEntity();
    tpControllerComponent.lookAtEntity.position.y = 0.72;
    tpControllerComponent.lookAtEntity.position.z = -0.05;

    const fpControllerComponent = characterEntity.addComponent(FPControllerComponent);
    fpControllerComponent.cameraEntity = cameraEntity;
    fpControllerComponent.avatarEntity = avatarEntity;
    fpControllerComponent.enabled = false;

    const xRFPControllerComponent = characterEntity.addComponent(XRFPControllerComponent);
    xRFPControllerComponent.cameraEntity = cameraEntity;
    xRFPControllerComponent.avatarEntity = avatarEntity;
    characterEntity.addComponent(PlayerControlsComponent);
    characterEntity.add(avatarEntity);

    characterEntity.add(tpControllerComponent.lookAtEntity);

    characterEntity.addComponent(ColliderComponent, {
      shapeData: {
        type: ColliderType.Capsule,
        radius: 0.3,
        height: 1.3,
      },
    });
    characterEntity.addComponent(RigidBodyComponent, {
      type: RigidBodyType.Dynamic,
      mass: 1,
      activationState: RigidBodyActivationState.AlwaysActive,
      mask: CollisionFilters.StaticFilter,
      group: CollisionFilters.CharacterFilter,
    });

    // add network
    const netObject = CharacterObject.buildNetworkObject(app);
    if (netObject) {
      netObject.isNeedSpawn = false;
      netObject.attachToEntity(characterEntity);
    }
  }

  public spawnEntity(): Object3D | undefined {
    if (!this.app) return;
    if (!this.getVariableByName('transform')) return;
    const entity = this.app.entityManager.makeEntity();
    const avatarEntity = CharacterObject.buildAvatarEntity(this.app, false);
    entity.add(avatarEntity);
    const primitiveComponent = entity.addComponent(PrimitiveComponent);
    entity.addComponent(RaycastComponent, { target: primitiveComponent.data });
    entity.addComponent(DashboardComponent);
    const dashboardEntity = this.app.entityManager.makeEntity();
    const dashboardSystem = this.app.getSystem(DashboardSystem);
    if (dashboardSystem) {
      this.app.getSystemOrFail(DashboardSystem).setupUIEntity(dashboardEntity, 0.001);
      dashboardEntity.position.y = 1.1;
      dashboardEntity.rotateX(-Math.PI / 6);
    }

    entity.add(dashboardEntity);

    // FIXME: T-pose to idle visible
    entity.visible = false;
    setTimeout(() => {
      entity.visible = true;
    }, 1000);

    this.attachToEntity(entity, NetworkTransformComponentTypes.Target, NetworkAnimatorComponentTypes.Target);
    entity.position.set(-13, 0.95, -2);

    return entity;
  }

  public remove() {
    // Detach from character entity
    // TODO: refactoring
    const component = this.app?.componentManager.getComponentsByType(NetworkObjectComponent).find((netComponent) => {
      return netComponent.netObject?.uuid === this.uuid;
    });
    if (component) {}
    super.remove();
  }
}
