import React, { FC } from 'react';
import { Button } from '@mui/material';
import classNames from './NotificationSnackbar.module.css';

export interface NotificationSnackbarProps {
  title: string;
  onChangeFullScreen: () => void;
  onHideNotistack: (flag: boolean) => void;
}

const SnackbarNotification: FC<NotificationSnackbarProps> = (props) => {
  const {
    onHideNotistack,
    onChangeFullScreen = () => undefined,
    title,
  } = props;
  return (
    <div className={classNames.root}>
      <p>{title}</p>
      <Button
        variant="outlined"
        color="success"
        onClick={onChangeFullScreen}
      >
        Yes
      </Button>
      <Button
        variant="outlined"
        color="error"
        onClick={() => {
          onHideNotistack(false);
        }}
      >
        No
      </Button>
    </div>
  );
};

export default SnackbarNotification;
