import { FC, memo } from 'react';
import { IconsType } from './IconsType';

const SunIcon: FC<IconsType> = memo((props) => {
  const {
    width = '34',
    height = '34',
    color = '#FFFFFF',
  } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9987 20.0327C11.5464 20.0327 11.1797 20.3994 11.1797 20.8517V23.1801C11.1797 23.6324 11.5464 23.9991 11.9987 23.9991C12.4511 23.9991 12.8176 23.6324 12.8176 23.1801V20.8517C12.8176 20.3993 12.4509 20.0327 11.9987 20.0327Z"
        fill="white"
      />
      <path
        d="M11.9987 3.96658C12.4511 3.96658 12.8176 3.5999 12.8176 3.14761V0.818907C12.8176 0.366618 12.4511 -6.10352e-05 11.9987 -6.10352e-05C11.5464 -6.10352e-05 11.1797 0.366618 11.1797 0.818907V3.14761C11.1797 3.5999 11.5464 3.96658 11.9987 3.96658Z"
        fill="white"
      />
      <path
        d="M5.16156 17.6794L3.51445 19.3257C3.19451 19.6454 3.1944 20.164 3.51413 20.4838C3.6741 20.6438 3.88375 20.7238 4.09341 20.7238C4.30296 20.7238 4.51239 20.6439 4.67236 20.4841L6.31947 18.8379C6.63942 18.5182 6.63953 17.9996 6.3198 17.6798C5.99986 17.3598 5.4814 17.3598 5.16156 17.6794Z"
        fill="white"
      />
      <path
        d="M18.2584 6.55869C18.4679 6.55869 18.6774 6.47876 18.8374 6.3189L20.4843 4.67266C20.8041 4.35283 20.8042 3.83437 20.4845 3.51443C20.1647 3.19448 19.6462 3.19437 19.3263 3.51432L17.6794 5.16055C17.3596 5.48028 17.3595 5.99885 17.6792 6.31868C17.839 6.47876 18.0487 6.55869 18.2584 6.55869Z"
        fill="white"
      />
      <path
        d="M3.96642 11.9998C3.96642 11.5475 3.59975 11.1808 3.14746 11.1808H0.818968C0.366679 11.1808 0 11.5475 0 11.9998C0 12.4521 0.366679 12.8188 0.818968 12.8188H3.14746C3.59975 12.8188 3.96642 12.4521 3.96642 11.9998Z"
        fill="white"
      />
      <path
        d="M23.1808 11.1808H20.8512C20.3988 11.1808 20.0322 11.5475 20.0322 11.9998C20.0322 12.4521 20.3988 12.8188 20.8512 12.8188H23.1808C23.6332 12.8188 23.9997 12.4521 23.9997 11.9998C23.9997 11.5476 23.6332 11.1808 23.1808 11.1808Z"
        fill="white"
      />
      <path
        d="M5.1615 6.31904C5.32136 6.4789 5.53102 6.55883 5.74057 6.55883C5.95011 6.55883 6.15977 6.4789 6.31963 6.31904C6.63946 5.9992 6.63946 5.48063 6.31963 5.1608L4.6734 3.51456C4.35356 3.19473 3.83499 3.19473 3.51527 3.51456C3.19543 3.8344 3.19543 4.35297 3.51527 4.67269L5.1615 6.31904Z"
        fill="white"
      />
      <path
        d="M18.8386 17.6794C18.5188 17.3596 18.0002 17.3595 17.6804 17.6793C17.3605 17.9991 17.3605 18.5177 17.6803 18.8374L19.3263 20.4837C19.4863 20.6436 19.6958 20.7236 19.9055 20.7236C20.115 20.7236 20.3247 20.6436 20.4845 20.4838C20.8045 20.1639 20.8045 19.6454 20.4847 19.3256L18.8386 17.6794Z"
        fill="white"
      />
      <path
        d="M11.9991 5.62527C8.4844 5.62527 5.625 8.48489 5.625 11.9998C5.625 15.5146 8.4844 18.3741 11.9991 18.3741C15.5141 18.3741 18.3737 15.5146 18.3737 11.9998C18.3737 8.48489 15.514 5.62527 11.9991 5.62527ZM11.9991 16.7362C9.38756 16.7362 7.26294 14.6114 7.26294 11.9998C7.26294 9.38805 9.38756 7.26321 11.9991 7.26321C14.6109 7.26321 16.7358 9.38805 16.7358 11.9998C16.7358 14.6114 14.6109 16.7362 11.9991 16.7362Z"
        fill="white"
      />
    </svg>

  );
});

export default SunIcon;
