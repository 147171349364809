import React, {
  useCallback, useContext, useEffect, useMemo, useRef, useState,
} from 'react';
import VoxeetSDK from '@voxeet/voxeet-web-sdk';
import * as Pluto from 'pluto/packages/pluto_client/src';
import { Application } from '../../engine/Application';
import { load } from '../../engine/physics/AmmoLoader';
import { MainScene } from '../../domain/scenes/MainScene';
import SportActions from '../../containers/SportActions/SportActions';
import useCreateVoice from '../../hooks/audio/useCreateVoiceChatRoom';
import useMovePlayer from '../../hooks/useMovePlayer';
import { AudioContext } from '../../context/AudioProvider/AudioProvider';
import { getUrl } from '../../helpers/getUrl';
import TransportPlutoNeo from '../../engine/network/TransportPlutoNeo';
import NetworkManager from '../../engine/network/NetworkManager';
import useConference from '../../hooks/audio/useConference';
import useMicrophone from '../../hooks/audio/useMicrophone';

// todo: надо вынести инициализацию чатов на компоненту ниже

function SportView() {
  const [ammoIsLoaded, setAmmoIsLoaded] = useState(false);

  const dcConfig = new Pluto.Dc_config('chat');
  // dcConfig.ordered = true;
  const plutoConfig = useRef(new Pluto.Connection_config(
    { url: process.env.REACT_APP_PUBLIC_WSS_CONNECT || '' },
    dcConfig,
  ));
  plutoConfig.current.pc.iceServers = [{ urls: [process.env.REACT_APP_STUN_CONNECT || ''] }];

  const [transport, setTransport] = useState<TransportPlutoNeo>();
  const [userId, setUserId] = useState('');

  const [isConnect, setIsConnect] = useState<boolean>(false);
  const [App, setApp] = useState<null | Application>(null);
  const [userName, setUserName] = useState<string>('');
  const [urlRoomId, setUrlRoomId] = useState<string>('');
  const [roomId, setRoomId] = useState<string>('');

  const { isConference } = useConference();

  const audioRef = useRef<HTMLAudioElement>(null);

  const { rejoinGroupHandler, joinInfo } = useCreateVoice();

  const { getMicrophonePermission } = useMicrophone();

  const {
    setApplication,
    getRefreshToken,
    token,
    refreshToken,
  } = useContext(AudioContext);

  useEffect(() => {
    setUrlRoomId(new URLSearchParams(window.location.search).get('room') || '');
    setUserName(new URLSearchParams(window.location.search).get('username') || 'userTest');
  }, []);

  useEffect(() => getRefreshToken(), []);

  useEffect(() => {
    if (token && refreshToken) VoxeetSDK.initializeToken(token, getRefreshToken);
  }, [token, refreshToken]);

  useEffect(() => {
    if (token && urlRoomId && userId && userName && App !== null) {
      if (!VoxeetSDK.session.participant) {
        getMicrophonePermission().then((response) => {
          if (response) rejoinGroupHandler(urlRoomId, userName, userId);
        });
      }
    }
  }, [token, userId, urlRoomId, userName, App]);

  useEffect(() => {
    if (roomId && urlRoomId !== roomId) {
      const newRelativePathQuery = getUrl(roomId);
      setUrlRoomId(roomId);
      window.history.pushState(null, '', newRelativePathQuery);
    }
  }, [roomId, urlRoomId]);

  const isAdmin = useMemo(() => {
    if (isConference) return VoxeetSDK.conference.current?.isNew || false;
    return false;
  }, [isConference]);

  const initApp = useCallback((plutoTransport: TransportPlutoNeo) => {
    const currentApp = new Application({ xrEnabled: true });
    setApp(currentApp);
    setApplication(currentApp);
    const network = new NetworkManager(plutoTransport);
    currentApp.setNetworkManager(network);

    network.run();

    return network.syncUsersInRoom()
      .then(() => currentApp.sceneManager.loadScene(new MainScene()))
      .then(() => {
        currentApp.run();
        setApp(currentApp);

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.web3devApp = currentApp;
        // eslint-disable-next-line global-require
        require('../../modules/js/script.js');
      });
  }, []);

  const connectRoom = useCallback(() => {
    if (!isConnect) {
      return Pluto.Client.create(plutoConfig.current).then((client) => {
        const plutoTransport = new TransportPlutoNeo(client, plutoConfig.current);
        setTransport(plutoTransport);
        setUserId(client.id);
        setIsConnect(true);
        return plutoTransport.createOrJointRoom(urlRoomId).then(({ roomId: createdRoomId }) => {
          setRoomId(createdRoomId);

          plutoTransport.listRoomConnections().then(({ connectionIds }) => {
            console.log('connectionIds', connectionIds);
          });

          return plutoTransport;
        });
      });
    }
  }, [isConnect, urlRoomId]);

  useEffect(() => {
    load.then((data) => {
      setAmmoIsLoaded(true);
    });
  }, []);

  useEffect(() => {
    if (!ammoIsLoaded) return;

    connectRoom()?.then((plutoTransport) => initApp(plutoTransport));
  }, [ammoIsLoaded, connectRoom, initApp]);

  useEffect(() => {
    return () => {
      transport?.closeSession(process.env.NODE_ENV !== 'development');
      App?.destroy();
    };
  }, []);

  // FIXME: huck for foreground tab
  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.volume = 0.01;
    }
    const playAudio = () => {
      if (audioRef.current && audioRef.current.paused) {
        audioRef.current.play().catch(() => null);
      }
    };

    document.body.addEventListener('mousemove', playAudio);
    return () => {
      document.body.removeEventListener('mousemove', playAudio);
    };
  }, []);

  return (
    <div className="App">
      {App !== null && roomId && joinInfo && <SportActions
        app={App}
        isAdmin={isAdmin}
        roomId={roomId}
        userId={userId}
        userName={userName}
      />}
    </div>
  );
}

export default SportView;
