import { FC, useCallback, useMemo } from 'react';
import { Participant } from '@voxeet/voxeet-web-sdk/types/models/Participant';
import IconButton from '../IconButton/IconButton';
import useAudio from '../../hooks/audio/useAudio';

type LocalToggleAudioButtonProps = {
  participant: Participant;
  onToggleAudio: (item: Participant) => void;
  disabled: boolean;
  size: 'Xs' | 'Md';
  isHasAudio: boolean;
};

const MircophoneToggleButton: FC<LocalToggleAudioButtonProps> = (props) => {
  const {
    size,
    participant,
    onToggleAudio = () => undefined,
    disabled = false,
    isHasAudio = false,
  } = props;


  const iconName = useMemo(() => {
    if (isHasAudio) return 'Microphone';
    if (!isHasAudio) return 'MicrophoneSlash';
    return 'Mirophone';
  }, [isHasAudio]);

  const toggleAudioHandler = useCallback(() => {
    onToggleAudio(participant);
  }, [participant]);

  return (
    <IconButton
      size={size}
      disabled={disabled}
      isActive={!isHasAudio}
      onCLick={toggleAudioHandler}
      icon={iconName}
    />
  );
};

export default MircophoneToggleButton;
