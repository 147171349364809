import * as Three from 'three';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader';
import { Sky } from 'three/examples/jsm/objects/Sky';
import { Application } from '../../../engine/Application';

// todo: refactor to components
export function setupEnvironment(app: Application, threeScene: Three.Scene, iSGenerate = true, isDay = true): void {
  if (iSGenerate) {
    const pmremGenerator = new Three.PMREMGenerator(app.renderer);

    new RGBELoader()
      .load('./3d/hdr/fouriesburg_mountain_cloudy_1k.hdr', (texture) => {
        threeScene.environment = pmremGenerator.fromEquirectangular(texture).texture;
        // threeScene.environment.needsUpdate = true; // maybe not need
        pmremGenerator.dispose();
      });

    const hemilight = new Three.HemisphereLight();
    hemilight.position.set(0, 50, 0);
    hemilight.color = new Three.Color(0.87, 0.77, 0.61);
    hemilight.groundColor = new Three.Color(0.058, 0.058, 0.058);
    hemilight.intensity = 0.9;

    threeScene.add(hemilight);
    threeScene.hemilight = hemilight;
    const sunlight = new Three.DirectionalLight();
    sunlight.position.set(-0.11, 1, 0.33);
    sunlight.intensity = 0.9;
    threeScene.background = new Three.Color(0xffffff);
  }

  const sky = new Sky();
  sky.scale.setScalar(450000);
  threeScene.add(sky);
  const { uniforms } = sky.material;
  uniforms.turbidity.value = 0.4;
  uniforms.rayleigh.value = 0.127;
  uniforms.mieCoefficient.value = 0.005;
  uniforms.mieDirectionalG.value = 0.8;
  const phi = Three.MathUtils.degToRad(2);
  const theta = Three.MathUtils.degToRad(90);
  if (isDay) {
    const sun = new Three.Vector3();
    sun.setFromSphericalCoords(1, phi, theta);
    uniforms.sunPosition.value.copy(sun);

    threeScene.hemilight.color = new Three.Color(0.87, 0.77, 0.61);
    threeScene.hemilight.groundColor = new Three.Color(0.058, 0.058, 0.058);
    threeScene.hemilight.intensity = 0.9;

    const pmremGenerator = new Three.PMREMGenerator(app.renderer);
    new RGBELoader()
      .load('./3d/hdr/fouriesburg_mountain_cloudy_1k.hdr', (texture) => {
        threeScene.environment = pmremGenerator.fromEquirectangular(texture).texture;
        // threeScene.environment.needsUpdate = true; // maybe not need
        pmremGenerator.dispose();
      });

    threeScene.traverse((child) => {
      if (child.isMesh) {
        child.material.envMapIntensity = 0.85;
      }
    });
  } else {
    threeScene.hemilight.color = new Three.Color(0.8, 0.8, 0.8);
    threeScene.hemilight.groundColor = new Three.Color(0, 0, 0);
    threeScene.hemilight.intensity = 0.5;

    const pmremGenerator = new Three.PMREMGenerator(app.renderer);
    new RGBELoader()
      .load('./3d/hdr/st_peters_square_night_1k.hdr', (texture) => {
        threeScene.environment = pmremGenerator.fromEquirectangular(texture).texture;
        // threeScene.environment.needsUpdate = true; // maybe not need
        pmremGenerator.dispose();
      });

    threeScene.traverse((child) => {
      if (child.isMesh) {
        child.material.envMapIntensity = 0.50;
      }
    });
  }
}
