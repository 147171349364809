import { FC, memo } from 'react';
import { IconsType } from './IconsType';

const ChatIcon: FC<IconsType> = memo((props) => {
  const {
    width = '34',
    height = '34',
    color = '#FFFFFF',
  } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 0C5.36761 0 0 5.367 0 12C0 18.6323 5.367 24 12 24C18.6324 24 24 18.633 24 12C24 5.36761 18.633 0 12 0ZM12 22.3256C6.30647 22.3256 1.67442 17.6936 1.67442 12C1.67442 6.30642 6.30647 1.67442 12 1.67442C17.6936 1.67442 22.3256 6.30642 22.3256 12C22.3256 17.6936 17.6936 22.3256 12 22.3256Z"
        fill="white"
      />
      <path
        d="M11.6438 15.1833C10.9804 15.1833 10.4434 15.7362 10.4434 16.3996C10.4434 17.0472 10.9646 17.6159 11.6438 17.6159C12.323 17.6159 12.86 17.0472 12.86 16.3996C12.86 15.7362 12.3072 15.1833 11.6438 15.1833Z"
        fill="white"
      />
      <path
        d="M11.85 5.97021C9.71759 5.97021 8.73828 7.23392 8.73828 8.08686C8.73828 8.70289 9.25953 8.98723 9.686 8.98723C10.539 8.98723 10.1915 7.77092 11.8026 7.77092C12.5924 7.77092 13.2243 8.11845 13.2243 8.84506C13.2243 9.698 12.3397 10.1877 11.8184 10.6299C11.3603 11.0247 10.7601 11.6724 10.7601 13.0309C10.7601 13.8522 10.9813 14.0892 11.6289 14.0892C12.4028 14.0892 12.5608 13.7417 12.5608 13.4415C12.5608 12.6201 12.5766 12.1463 13.4454 11.4671C13.8718 11.1354 15.2144 10.0612 15.2144 8.57646C15.2144 7.0917 13.8718 5.97021 11.85 5.97021Z"
        fill="white"
      />
    </svg>

  );
});

export default ChatIcon;
