import * as THREE from 'three';
import ThreeMeshUI from 'three-mesh-ui';
import FontJSON from 'three-mesh-ui/examples/assets/Roboto-msdf.json';
import FontImage from 'three-mesh-ui/examples/assets/Roboto-msdf.png';
import { SVGLoader } from 'three/examples/jsm/loaders/SVGLoader.js';
import VRControl from 'three-mesh-ui/examples/utils/VRControl.js';
import { VRMHumanBoneName } from '@pixiv/three-vrm';
import { MeshRendererComponent } from '../../engine/components/MeshRenderer.component';

class MeshUI {
  constructor(scene, dolly, camera, upArr, renderer, videoController) {
    upArr.push(this);
    this.scene = scene;
    this.camera = camera;
    this.dolly = dolly;
    // this.normCamera = camera.children[0].children[0];
    this.renderer = renderer;
    this.vidLib = this.scene.vidLib;
    this.videoController = videoController;
    this.inVR = this.scene.inVR;
    this.realCamObj = this.scene.realCamObj;

    // if(this.videoController.obj.meshUi) this.videoController.obj.meshUi.terminate();
    // this.videoController.obj.meshUi = this;

    this.raycaster = new THREE.Raycaster();
    this.textureLoader = new THREE.TextureLoader();
    this.mouse = new THREE.Vector2();
    this.svgLoader = new SVGLoader();
    this.mouse.x = this.mouse.y = null;

    this.containerGlobal = undefined;
    this.objsToTest = [];
    this.objsToTestVidImg = [];
    this.objsToTestPageNav = [];
    this.objsToTestBotMenu = [];
    this.selectState = false;

    window.addEventListener('pointermove', (event) => {
      this.mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
      this.mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
    });

    window.addEventListener('pointerdown', () => {
      this.selectState = true;
    });

    window.addEventListener('pointerup', () => {
      this.selectState = false;
    });

    window.addEventListener('touchstart', (event) => {
      this.selectState = true;
      this.mouse.x = (event.touches[0].clientX / window.innerWidth) * 2 - 1;
      this.mouse.y = -(event.touches[0].clientY / window.innerHeight) * 2 + 1;
    });

    window.addEventListener('touchend', () => {
      this.selectState = false;
      this.mouse.x = null;
      this.mouse.y = null;
    });

    this.vidCategories = [];
    this.videosInCat = {};
    Object.keys(this.vidLib).forEach((item) => {
      this.vidCategories.push(this.vidLib[item].name);
      this.videosInCat[this.vidLib[item].name] = this.vidLib[item].videos;
    });

    this.currentCategory = this.vidCategories[0];
    this.currentPage = 1;
    this.contentRowGlobal = undefined;
    this.pageNavRowGlobal = undefined;
    this.botButtonsRowGlobal = undefined;
    this.vidRow1Gobal = undefined;
    this.vidRow2Gobal = undefined;
    this.vidButtons = {};
    this.pageNavButtons = {};
    this.playButton = undefined;
    this.libButton = undefined;
    this.libOpen = false;
    this.libObjects = [];

    this.playingVideo = null;

    this.vrControl = VRControl(this.renderer, this.camera, this.scene);

    // scene.add( this.vrControl.controllerGrips[ 0 ], this.vrControl.controllers[ 0 ] );

    this.vrControl.controllers[0].addEventListener('selectstart', () => {
      this.selectState = true;
    });
    this.vrControl.controllers[0].addEventListener('selectend', () => {
      this.selectState = false;
    });
  }

  getTargetPosition() {
    const root = this.dolly.children[0];
    const bone = root
      .getComponent(MeshRendererComponent)
      .getVRM().humanoid.getBoneNode(VRMHumanBoneName.Head);
    // bone.updateWorldMatrix(true, true);
    return bone.getWorldPosition(new THREE.Vector3());
  }

  getTargetDirection() {
    return this.dolly.getWorldDirection(new THREE.Vector3()).normalize().multiplyScalar(-1);
  }

  vidUI() {
    if (this.containerGlobal !== undefined) {
      this.terminate(true);
      return;
    }
    const dist = 1.8;

    const cwd = this.getTargetDirection();
    const cameraPosition = this.getTargetPosition();
    cwd.multiplyScalar(dist);
    cwd.add(cameraPosition);

    const container = new ThreeMeshUI.Block({
      justifyContent: 'center',
      contentDirection: 'row',
      fontFamily: FontJSON,
      fontTexture: FontImage,
      fontSize: 0.07,
      padding: 0.02,
      borderRadius: 0.11,
      backgroundOpacity: 0,
    });
    this.containerGlobal = container;
    this.scene.rayCastGroup.push(this.containerGlobal);

    container.position.set(cwd.x, cwd.y, cwd.z);
    container.lookAt(cameraPosition);
    // container.setRotationFromQuaternion(this.camera.quaternion);
    // container.rotation.x = -0.55;
    this.scene.add(container);

    // const leftCol = new ThreeMeshUI.Block( {
    //     justifyContent: 'center',
    //     contentDirection: 'column',
    //     fontFamily: FontJSON,
    //     fontTexture: FontImage,
    //     fontSize: 0.05,
    //     padding: 0.02,
    //     borderRadius: 0.07,
    //     margin: 0.02,
    //     backgroundColor: new THREE.Color( 0xffffff ),
    //     backgroundOpacity: 0.2
    // } );

    const rightCol = new ThreeMeshUI.Block({
      justifyContent: 'center',
      contentDirection: 'column',
      fontFamily: FontJSON,
      fontTexture: FontImage,
      fontSize: 0.07,
      padding: 0.02,
      borderRadius: 0.11,
      margin: 0.02,
      backgroundOpacity: 0,
    });

    container.add(rightCol);

    const headerRow = new ThreeMeshUI.Block({
      justifyContent: 'center',
      contentDirection: 'row',
      fontFamily: FontJSON,
      fontTexture: FontImage,
      fontSize: 0.05,
      padding: 0.02,
      borderRadius: 0.07,
      margin: 0.02,
      backgroundOpacity: 0,
    });
    headerRow.name = 'headerRow';

    const contentRow = new ThreeMeshUI.Block({
      height: 0.7,
      justifyContent: 'center',
      contentDirection: 'column',
      fontFamily: FontJSON,
      fontTexture: FontImage,
      fontSize: 0.07,
      padding: 0.02,
      borderRadius: 0.07,
      margin: 0.02,
      backgroundOpacity: 0,
    });
    contentRow.name = 'contentRow';
    this.contentRowGlobal = contentRow;

    const pageNavRow = new ThreeMeshUI.Block({
      justifyContent: 'center',
      contentDirection: 'row',
      fontFamily: FontJSON,
      fontTexture: FontImage,
      fontSize: 0.07,
      padding: 0.02,
      borderRadius: 0.07,
      margin: 0.01,
      backgroundOpacity: 0,
    });
    pageNavRow.name = 'pageNavRow';
    this.pageNavRowGlobal = pageNavRow;

    const botButtonsRow = new ThreeMeshUI.Block({
      justifyContent: 'center',
      contentDirection: 'row',
      fontFamily: FontJSON,
      fontTexture: FontImage,
      fontSize: 0.07,
      padding: 0.02,
      borderRadius: 0.05,
      margin: 0.02,
      backgroundOpacity: 0.3,
      backgroundColor: new THREE.Color(0xffffff),
    });
    this.botButtonsRowGlobal = botButtonsRow;

    rightCol.add(headerRow, contentRow, pageNavRow, botButtonsRow);

    const vidRow1 = new ThreeMeshUI.Block({
      justifyContent: 'center',
      contentDirection: 'row',
      fontFamily: FontJSON,
      fontTexture: FontImage,
      fontSize: 0.07,
      padding: 0.02,
      borderRadius: 0.11,
      margin: 0.005,
      backgroundOpacity: 0,
    });

    this.vidRow1Gobal = vidRow1;

    const vidRow2 = new ThreeMeshUI.Block({
      justifyContent: 'center',
      contentDirection: 'row',
      fontFamily: FontJSON,
      fontTexture: FontImage,
      fontSize: 0.07,
      padding: 0.02,
      borderRadius: 0.11,
      margin: 0.005,
      backgroundOpacity: 0,
    });

    this.vidRow2Gobal = vidRow2;

    contentRow.add(vidRow1, vidRow2);

    // BUTTON STYLE

    const buttonOptions = {
      width: 0.5,
      height: 0.1,
      justifyContent: 'center',
      offset: 0.01,
      margin: 0.01,
      borderRadius: 0.05,
      backgroundOpacity: 0.6,
    };

    const selectedDefStateAttributes = {
      state: 'selected',
      attributes: {
        offset: 0.001,
        backgroundColor: new THREE.Color(0x2586FF),
        fontColor: new THREE.Color(0xffffff),
      },
    };

    const hoveredStateAttributes = {
      state: 'hovered',
      attributes: {
        offset: 0.005,
        backgroundColor: new THREE.Color(0x57A2FF),
        backgroundOpacity: 1,
        fontColor: new THREE.Color(0xffffff),
      },
    };

    const idleStateAttributes = {
      state: 'idle',
      attributes: {
        offset: 0.01,
        backgroundColor: new THREE.Color(0xffffff),
        backgroundOpacity: 0.3,
        fontColor: new THREE.Color(0x000),
      },
    };

    //= ==========================================================
    // TOP BUTTONS
    //= ===========================================================

    const topMenuButtons = {};

    this.vidCategories.forEach((category, i) => {
      topMenuButtons[`headerRow_${i}`] = new ThreeMeshUI.Block(buttonOptions);
      const item = topMenuButtons[`headerRow_${i}`];
      item.name = category;

      const selectStateCategories = {
        state: 'selected',
        attributes: {
          offset: 0.001,
          backgroundColor: new THREE.Color(0x2586FF),
          fontColor: new THREE.Color(0xffffff),
        },
        onSet: () => {
          this.changeCategory(category);
        },
      };

      item.setupState(selectStateCategories);
      item.setupState(hoveredStateAttributes);
      item.setupState(idleStateAttributes);

      item.add(
        new ThreeMeshUI.Text({ content: category }),
      );

      this.libObjects.push(item);
      headerRow.add(item);
      this.objsToTest.push(item);
    });

    topMenuButtons[`headerRow_${this.vidCategories.indexOf(this.currentCategory)}`].setState('selected');

    //= ==========================================================
    // LEFT BUTTONS
    //= ===========================================================

    // const buttonTV = new ThreeMeshUI.Block( buttonOptions );
	    // const buttonFeatured = new ThreeMeshUI.Block( buttonOptions );
    // const buttonLibrary = new ThreeMeshUI.Block( buttonOptions );

    // buttonTV.add(
    //     new ThreeMeshUI.Text( { content: 'TV' } )
    // );

    // buttonFeatured.add(
    //     new ThreeMeshUI.Text( { content: 'Featured' } )
    // );

    // buttonLibrary.add(
    //     new ThreeMeshUI.Text( { content: 'Library' } )
    // );

    // [buttonTV, buttonFeatured, buttonLibrary].forEach((item,i) => {
    //     item.setupState( selectedDefStateAttributes );
    //     item.setupState( hoveredStateAttributes );
    //     item.setupState( idleStateAttributes );
    // });

    // leftCol.add( buttonTV, buttonFeatured, buttonLibrary);

    // this.objsToTest.push( buttonTV, buttonFeatured, buttonLibrary);

    this.updateBlockContent(); // run bloch generation
    this.generateBotMenu();
  }

  // getCameraPosition() {
  //   return this.scene.inVR ? this.camera.getWorldPosition(new THREE.Vector3()).add(new THREE.Vector3(0, 0.5, 0))
  //     : this.normCamera.getWorldPosition(new THREE.Vector3());
  // }

  updateButtons() {
    if (this.scene.inVR === true)ThreeMeshUI.update();
    if (!this.containerGlobal) return;
    if (this.scene.playerWatchingVideo) return;

    const cameraPosition = this.getTargetPosition();

    this.containerGlobal.lookAt(cameraPosition);

    let termDist;
    if (this.inVR) {
      termDist = 4;
    } else {
      termDist = 3;
    }
    // if (this.containerGlobal.position.distanceTo(cameraPosition) > termDist) {
    //   this.terminate();
    // }

    let intersect;

    if (this.renderer.xr.isPresenting) {
      this.vrControl.setFromController(0, this.raycaster.ray);

      intersect = this.raycast();

      // Position the little white dot at the end of the controller pointing ray
      // if ( intersect ) vrControl.setPointerAt( 0, intersect.point );
    } else if (this.mouse.x !== null && this.mouse.y !== null) {
      this.raycaster.setFromCamera(this.mouse, this.realCamObj);

      intersect = this.raycast();
    }
    if (intersect && intersect.object.isUI) {
      if (this.selectState) {
        if (intersect.object.visible === false) return;
        if (intersect.object.name === this.currentCategory || intersect.object.name === (`pageNavRow_${this.currentPage}`)) return;
        // if(intersect.object === this.libButton && this.libOpen === true) return;
        // Component.setState internally call component.set with the options you defined in component.setupState
        intersect.object.setState('selected');
      } else {
        if (intersect.object.visible === false) return;
        if (intersect.object.name === this.currentCategory || intersect.object.name === (`pageNavRow_${this.currentPage}`)) return;
        // Component.setState internally call component.set with the options you defined in component.setupState
        intersect.object.setState('hovered');
      }
    }

    this.objsToTest.forEach((obj) => {
      if ((!intersect || obj !== intersect.object) && obj.isUI) {
        if (obj.name === this.currentCategory || obj.name === (`pageNavRow_${this.currentPage}`)) return;
        if (obj === this.libButton && this.libOpen === true) return;
        // Component.setState internally call component.set with the options you defined in component.setupState
        obj.setState('idle');
      }
    });
    this.objsToTestVidImg.forEach((obj) => {
      if ((!intersect || obj !== intersect.object) && obj.isUI) {
        // Component.setState internally call component.set with the options you defined in component.setupState
        obj.setState('idle');
      }
    });
    this.objsToTestPageNav.forEach((obj) => {
      if ((!intersect || obj !== intersect.object) && obj.isUI) {
        // Component.setState internally call component.set with the options you defined in component.setupState
        obj.setState('idle');
      }
    });

    if (this.libButton) {
      if (this.libOpen === false) {
        this.libObjects.forEach((obj) => {
          obj.visible = false;
        });
      } else {
        this.libObjects.forEach((obj) => {
          obj.visible = true;
        });
      }
    }
  }

  raycast() {
    return [...this.objsToTest, ...this.objsToTestVidImg, ...this.objsToTestPageNav].reduce((closestIntersection, obj) => {
      const intersection = this.raycaster.intersectObject(obj, true);

      if (!intersection[0]) return closestIntersection;

      if (!closestIntersection || intersection[0].distance < closestIntersection.distance) {
        intersection[0].object = obj;

        return intersection[0];
      }

      return closestIntersection;
    }, null);
  }

  updateBlockContent() {
    //= ==========================================================
    // IMAGES OF VIDEO
    //= ===========================================================
    const vidBoxOptions = {
      width: 0.4,
      fontFamily: FontJSON,
      fontTexture: FontImage,
      height: 0.3,
      justifyContent: 'end',
      offset: 0.01,
      fontSize: 0.04,
      textAlign: 'left',
      margin: 0.01,
      borderRadius: 0.05,
      backgroundColor: new THREE.Color(0xffffff),
    };
    const inVidText = {
      width: 0.35,
      height: 0.3,
      justifyContent: 'end',
      offset: 0.001,
      fontSize: 0.035,
      textAlign: 'left',
      margin: 0.02,
      whiteSpace: 'normal',
      backgroundOpacity: 0,
    };

    const hoveredStateVidImgAttributes = {
      state: 'hovered',
      attributes: {
        offset: 0.005,
      },
    };

    const idleStateVidImgAttributes = {
      state: 'idle',
      attributes: {
        offset: 0.01,
      },
    };

    const pageShift = (this.currentPage - 1) * 8;
    const indexOfCat = this.vidCategories.indexOf(this.currentCategory);

    for (let i = (pageShift + 1); i <= (pageShift + 4); i++) {
      if (!this.videosInCat[this.vidCategories[indexOfCat]][i]) break;
      this.vidButtons[`vidRow1_${i}`] = new ThreeMeshUI.Block(vidBoxOptions);
      const item = this.vidButtons[`vidRow1_${i}`];

      const selectStateVidImg = {
        state: 'selected',
        attributes: {
          offset: 0.001,
        },
        onSet: () => {
          this.chooseVideo(i,
            this.videosInCat[this.vidCategories[indexOfCat]][i]);
        },
      };

      item.setupState(selectStateVidImg);
      item.setupState(hoveredStateVidImgAttributes);
      item.setupState(idleStateVidImgAttributes);

      const itemTextContainer = new ThreeMeshUI.Block(inVidText);
      itemTextContainer.add(
        new ThreeMeshUI.Text({ content: this.videosInCat[this.vidCategories[indexOfCat]][i].name }),
      );
      item.add(itemTextContainer);

      this.textureLoader.load(this.videosInCat[this.vidCategories[indexOfCat]][i].img, (texture) => {
        item.set({ backgroundTexture: texture });
      });

      this.libObjects.push(item);
      this.vidRow1Gobal.add(item);
      this.objsToTestVidImg.push(item);
    }
    for (let i = (pageShift + 5); i <= (pageShift + 8); i++) {
      if (!this.videosInCat[this.vidCategories[indexOfCat]][i]) break;
      this.vidButtons[`vidRow1_${i}`] = new ThreeMeshUI.Block(vidBoxOptions);
      const item = this.vidButtons[`vidRow1_${i}`];

      const selectStateVidImg = {
        state: 'selected',
        attributes: {
          offset: 0.001,
        },
        onSet: () => {
          this.chooseVideo(i,
            this.videosInCat[this.vidCategories[indexOfCat]][i]);
        },
      };

      item.setupState(selectStateVidImg);
      item.setupState(hoveredStateVidImgAttributes);
      item.setupState(idleStateVidImgAttributes);

      const itemTextContainer = new ThreeMeshUI.Block(inVidText);
      itemTextContainer.add(
        new ThreeMeshUI.Text({ content: this.videosInCat[this.vidCategories[indexOfCat]][i].name }),
      );
      item.add(itemTextContainer);

      this.textureLoader.load(this.videosInCat[this.vidCategories[indexOfCat]][i].img, (texture) => {
        item.set({ backgroundTexture: texture });
      });
      this.libObjects.push(item);
      this.vidRow2Gobal.add(item);
      this.objsToTestVidImg.push(item);
    }

    //= ==========================================================
    // Navigation
    //= ===========================================================

    const pageNavOptions = {
      width: 0.075,
      height: 0.075,
      fontFamily: FontJSON,
      fontTexture: FontImage,
      fontSize: 0.035,
      justifyContent: 'center',
      offset: 0.01,
      textAlign: 'center',
      margin: 0.01,
      borderRadius: 0.015,
      fontColor: new THREE.Color(0xffffff),
      backgroundColor: new THREE.Color(0xffffff),
    };

    const hoveredStatePageNavAttributes = {
      state: 'hovered',
      attributes: {
        offset: 0.005,
        backgroundColor: new THREE.Color(0x57A2FF),
      },
    };

    const idleStatePageNavAttributes = {
      state: 'idle',
      attributes: {
        offset: 0.01,
        backgroundColor: new THREE.Color(0xffffff),
      },
    };

    let pageAmount = Math.ceil(Object.keys(this.videosInCat[this.currentCategory]).length / 8);
    if (pageAmount === 0) pageAmount = 1;

    for (let i = 1; i <= pageAmount; i++) {
      this.pageNavButtons[`pageNavRow_${i}`] = new ThreeMeshUI.Block(pageNavOptions);
      const item = this.pageNavButtons[`pageNavRow_${i}`];
      item.name = `pageNavRow_${i}`;

      const selectStateCategories = {
        state: 'selected',
        attributes: {
          offset: 0.001,
          backgroundColor: new THREE.Color(0x2586FF),
        },
        onSet: () => {
          this.changePage(i);
        },
      };

      item.add(
        new ThreeMeshUI.Text({ content: String(i) }),
      );

      item.setupState(selectStateCategories);
      item.setupState(hoveredStatePageNavAttributes);
      item.setupState(idleStatePageNavAttributes);

      this.libObjects.push(item);
      this.pageNavRowGlobal.add(item);
      this.objsToTest.push(item);
    }

    if (this.pageNavButtons[`pageNavRow_${this.currentPage}`]) this.pageNavButtons[`pageNavRow_${this.currentPage}`].setState('selected');
  }

  generateBotMenu() {
    const BotMenuOptions = {
      fontFamily: FontJSON,
      fontTexture: FontImage,
      width: 0.20,
      height: 0.20,
      justifyContent: 'end',
      offset: 0.01,
      fontSize: 0.04,
      textAlign: 'left',
      margin: 0.01,
      borderRadius: 0.05,
      backgroundColor: new THREE.Color(0xffffff),
    };
    const BotMenuIMGOptions = {
      fontFamily: FontJSON,
      fontTexture: FontImage,
      width: 0.20,
      height: 0.20,
      justifyContent: 'end',
      offset: 0.01,
      margin: 0,
      borderRadius: 0.05,
      backgroundColor: new THREE.Color(0xffffff),
    };

    const hoveredBotMenuAttributes = {
      state: 'hovered',
      attributes: {
        offset: 0.005,
        backgroundColor: new THREE.Color(0x57A2FF),
        backgroundOpacity: 1,
        fontColor: new THREE.Color(0xffffff),
      },
    };

    const idleBotMenuAttributes = {
      state: 'idle',
      attributes: {
        offset: 0.01,
        backgroundColor: new THREE.Color(0xffffff),
        backgroundOpacity: 0.3,
        fontColor: new THREE.Color(0x000),
      },
    };

    const buttonsNames = ['exit', 'live', 'library', 'fullscreen', 'view', 'sound', 'prev', 'play', 'next'];

    buttonsNames.forEach((btn, i) => {
      this.pageNavButtons[btn] = new ThreeMeshUI.Block(BotMenuOptions);
      const item = this.pageNavButtons[btn];
      item.name = btn;

      const selectStateCategories = {
        state: 'selected',
        attributes: {
          offset: 0.001,
          backgroundColor: new THREE.Color(0x2586FF),
        },
        onSet: () => {
          this.botButton(btn);
        },
      };

      item.setupState(selectStateCategories);
      item.setupState(hoveredBotMenuAttributes);
      item.setupState(idleBotMenuAttributes);

      const itemImgContainer = new ThreeMeshUI.Block(BotMenuIMGOptions);

      this.textureLoader.load(`./video_controller_icons/${btn}.svg`, (texture) => {
        itemImgContainer.set({ backgroundTexture: texture });
        if (btn === 'play') {
          this.playButton = itemImgContainer;
          if (this.videoController.video.paused === false) {
            this.textureLoader.load('./video_controller_icons/pause.svg', (texture) => {
              this.playButton.set({ backgroundTexture: texture });
            });
          }
        }
      });

      item.add(itemImgContainer);

      if (btn === 'library') this.libButton = item;

      this.botButtonsRowGlobal.add(item);
      this.objsToTest.push(item);

      // if(this.libOpen === true)this.libButton.setState( 'selected' );
    });
  }

  changeCategory(categoryName) {
    if (this.currentCategory === categoryName) return;
    this.currentCategory = categoryName;

    this.cleanNupdate();
  }

  changePage(pageNum) {
    if (this.currentPage === pageNum) return;
    this.currentPage = pageNum;

    this.cleanNupdate();
  }

  cleanNupdate() {
    Object.keys(this.vidButtons).forEach((key, i) => {
      if (!this.vidButtons[key]) return;
      this.vidButtons[key].clear();
      this.vidRow1Gobal.remove(this.vidButtons[key]);
      this.vidRow2Gobal.remove(this.vidButtons[key]);
      this.vidButtons[key] = undefined;
    });
    this.vidRow1Gobal.update(true, true, true);
    this.vidRow2Gobal.update(true, true, true);

    Object.keys(this.pageNavButtons).forEach((key, i) => {
      if (!this.pageNavButtons[key]) return;
      this.pageNavButtons[key].clear();
      this.pageNavRowGlobal.remove(this.pageNavButtons[key]);
      this.pageNavButtons[key] = undefined;
    });
    this.pageNavRowGlobal.update(true, true, true);

    this.objsToTestPageNav = [];
    this.objsToTestVidImg = [];

    if (this.inVR) {
      const session = this.renderer.xr.getSession();
      session.requestAnimationFrame(() => {
        this.updateBlockContent();
      });
    } else {
      window.requestAnimationFrame(() => {
        this.updateBlockContent();
      });
    }
  }

  terminate(redo) {
    if (!this.containerGlobal) return;
    // this.videoController.obj.showController = false;

    this.containerGlobal.clear();
    this.scene.remove(this.containerGlobal);

    this.containerGlobal.update(true, true, true);
    this.objsToTest = this.objsToTestVidImg = this.objsToTestPageNav = [];
    this.containerGlobal = undefined;
    this.scene.rayCastGroup.splice(this.scene.rayCastGroup.indexOf(this.containerGlobal), 1);

    window.requestAnimationFrame(() => {
      // this.terminate();
      if (redo) this.vidUI();
    });
  }

  chooseVideo(num, videoInfo) {
    if (!videoInfo) return;
    this.videoController.update([videoInfo.mp4, videoInfo.ogv]);
    this.playingVideo = num;
    this.textureLoader.load('./video_controller_icons/play.svg', (texture) => {
      this.playButton.set({ backgroundTexture: texture });
    });
  }

  botButton(btn) {
    console.log(btn);
    if (btn === 'exit') {
      this.terminate();
    } else if (btn === 'prev') {
      if (this.playingVideo === null) {
        const prevI = Object.keys(this.videosInCat[this.currentCategory]).indexOf('1');
        const prevK = Object.keys(this.videosInCat[this.currentCategory])[prevI];
        console.log(prevK);
        this.chooseVideo(prevK, this.videosInCat[this.currentCategory][prevK]);
      } else {
        const prevI = Object.keys(this.videosInCat[this.currentCategory]).indexOf(String(this.playingVideo)) - 1;
        if (prevI >= 1) {
          const prevK = Object.keys(this.videosInCat[this.currentCategory])[prevI];
          this.chooseVideo(prevK, this.videosInCat[this.currentCategory][prevK]);
        }
      }
    } else if (btn === 'next') {
      if (this.playingVideo === null) {
        const nextI = Object.keys(this.videosInCat[this.currentCategory]).indexOf('1');
        const nextK = Object.keys(this.videosInCat[this.currentCategory])[nextI];
        console.log(nextK);
        this.chooseVideo(nextK, this.videosInCat[this.currentCategory][nextK]);
      } else {
        const nextI = Object.keys(this.videosInCat[this.currentCategory]).indexOf(String(this.playingVideo)) + 1;
        if (nextI >= 1) {
          const nextK = Object.keys(this.videosInCat[this.currentCategory])[nextI];
          this.chooseVideo(nextK, this.videosInCat[this.currentCategory][nextK]);
        }
      }
    } else if (btn === 'play') {
      const { video } = this.videoController;
      console.log(video.src, video.paused);
      if (video.paused === true) {
        this.videoController.play();
            	// video.play();
        this.textureLoader.load('./video_controller_icons/pause.svg', (texture) => {
          this.playButton.set({ backgroundTexture: texture });
        });
      } else {
        this.videoController.pause();
        // video.pause();
        this.textureLoader.load('./video_controller_icons/play.svg', (texture) => {
          this.playButton.set({ backgroundTexture: texture });
        });
      }
    } else if (btn === 'view') {
      let toPlay;
      let videoInfo;
      if (this.playingVideo != null) {
        toPlay = Object.keys(this.videosInCat[this.currentCategory]).indexOf(String(this.playingVideo));
        videoInfo = this.videosInCat[this.currentCategory][toPlay];
      } else {
        toPlay = Object.keys(this.videosInCat[this.currentCategory]).indexOf('1');
        videoInfo = this.videosInCat['All Content'][1];
      }

      this.scene.videoScene.update([videoInfo.mp4_360, videoInfo.ogv_360], '360');
      this.scene.playerWatchingVideo = true;
      window.web3devApp.sceneManager.loadScene(window.web3devApp._videoContainerScene);
    } else if (btn === 'fullscreen') {
      let toPlay;
      let videoInfo;
      if (this.playingVideo != null) {
        toPlay = Object.keys(this.videosInCat[this.currentCategory]).indexOf(String(this.playingVideo));
        videoInfo = this.videosInCat[this.currentCategory][toPlay];
      } else {
        toPlay = Object.keys(this.videosInCat[this.currentCategory]).indexOf('1');
        videoInfo = this.videosInCat['All Content'][1];
      }

      this.scene.videoScene.update([videoInfo.mp4, videoInfo.ogv], 'full');
      this.scene.playerWatchingVideo = true;
      window.web3devApp.sceneManager.loadScene(window.web3devApp._videoContainerScene);
    } else if (btn === 'library') {
      if (this.libOpen === false) {
        this.libOpen = true;
      } else {
        this.libOpen = false;
      }
    }
  }
}

export { MeshUI };
