import * as THREE from 'three';
import ThreeMeshUI from 'three-mesh-ui';
import FontJSON from 'three-mesh-ui/examples/assets/Roboto-msdf.json';
import FontImage from 'three-mesh-ui/examples/assets/Roboto-msdf.png';
import { SVGLoader } from 'three/examples/jsm/loaders/SVGLoader.js';
import VRControl from 'three-mesh-ui/examples/utils/VRControl.js';
import { VRMHumanBoneName } from '@pixiv/three-vrm';
import { EcommerceEvents } from './EcommerceEvents.js';
import { MeshRendererComponent } from '../../engine/components/MeshRenderer.component';

class ProductAnno {
  constructor(scene, dolly, camera, upArr, renderer, obj) {
    upArr.push(this);
    this.scene = scene;
    this.camera = camera;
    this.dolly = dolly;
    // this.normCamera = camera.children[0].children[0];
    this.realCamObj = this.scene.realCamObj;
    this.renderer = renderer;
    this.annoLib = this.scene.annoLib;
    this.obj = obj;
    this.obj.addedAnno = this;
    this.inVR = this.scene.inVR;

    this.raycaster = new THREE.Raycaster();
    this.textureLoader = new THREE.TextureLoader();
    this.mouse = new THREE.Vector2();
    this.svgLoader = new SVGLoader();
    this.ecommerceEvents = new EcommerceEvents(this.renderer);
    this.mouse.x = this.mouse.y = null;

    this.containerGlobal = undefined;
    this.objsToTest = [];

    window.addEventListener('pointermove', (event) => {
      this.mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
      this.mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
    });

    window.addEventListener('pointerdown', () => {
      this.selectState = true;
    });

    window.addEventListener('pointerup', () => {
      this.selectState = false;
    });

    window.addEventListener('touchstart', (event) => {
      this.selectState = true;
      this.mouse.x = (event.touches[0].clientX / window.innerWidth) * 2 - 1;
      this.mouse.y = -(event.touches[0].clientY / window.innerHeight) * 2 + 1;
    });

    window.addEventListener('touchend', () => {
      this.selectState = false;
      this.mouse.x = null;
      this.mouse.y = null;
    });

    this.vrControl = VRControl(this.renderer, this.camera, this.scene);

    // scene.add( this.vrControl.controllerGrips[ 0 ], this.vrControl.controllers[ 0 ] );

    this.vrControl.controllers[0].addEventListener('selectstart', () => {
      this.selectState = true;
    });
    this.vrControl.controllers[0].addEventListener('selectend', () => {
      this.selectState = false;
    });
  }

  __drawSphere(pos) {
    const geometry = new THREE.SphereGeometry(0.1, 32, 16);
    const material = new THREE.MeshBasicMaterial({ color: 0xffff00 });
    const sphere = new THREE.Mesh(geometry, material);
    sphere.position.copy(pos);
    this.scene.add(sphere);
  }

  getTargetPosition() {
    const root = this.dolly.children[0];
    const bone = root
      .getComponent(MeshRendererComponent)
      .getVRM().humanoid.getBoneNode(VRMHumanBoneName.Head);
    // bone.updateWorldMatrix(true, true);
    return bone.getWorldPosition(new THREE.Vector3());
  }

  getTargetDirection() {
    return this.dolly.getWorldDirection(new THREE.Vector3()).normalize().multiplyScalar(-1);
  }

  anno(pos) {
    this.obj.annoExist = true;
    const dist = 1.3;
    const cwd = this.getTargetDirection();
    const cameraWorldPosition = this.getTargetPosition();
    cwd.multiplyScalar(dist);
    cwd.add(cameraWorldPosition);

    const container = new ThreeMeshUI.Block({
      height: 0.8,
      width: 0.5,
      justifyContent: 'end',
      contentDirection: 'column',
      fontFamily: FontJSON,
      fontTexture: FontImage,
      fontSize: 0.07,
      padding: 0.02,
      borderRadius: 0.11,
      backgroundOpacity: 1,
    });
    this.containerGlobal = container;
    this.scene.rayCastGroup.push(this.containerGlobal);

    container.position.set(pos[2][0], cwd.y + 0.15, -pos[2][1]);
    container.lookAt(cameraWorldPosition);
    // container.setRotationFromQuaternion(this.normCamera.quaternion);

    this.scene.add(container);

    console.log(this.annoLib[this.obj.annoName]);
    this.textureLoader.load(this.annoLib[this.obj.annoName], (texture) => {
      container.set({ backgroundTexture: texture });
    });

    const buttonCol = new ThreeMeshUI.Block({
      justifyContent: 'center',
      contentDirection: 'row',
      fontFamily: FontJSON,
      fontTexture: FontImage,
      fontSize: 0.07,
      padding: 0.02,
      borderRadius: 0.11,
      margin: 0.01,
      backgroundOpacity: 0,
    });

    container.add(buttonCol);

    const BotButtonOptions = {
      fontFamily: FontJSON,
      fontTexture: FontImage,
      width: 0.2,
      height: 0.1,
      justifyContent: 'center',
      offset: 0.01,
      fontSize: 0.04,
      textAlign: 'center',
      margin: 0.005,
      borderRadius: 0.05,
      backgroundColor: new THREE.Color(0xffffff),
    };

    const BotCartOptions = {
      fontFamily: FontJSON,
      fontTexture: FontImage,
      width: 0.1,
      height: 0.1,
      justifyContent: 'center',
      offset: 0.01,
      fontSize: 0.04,
      textAlign: 'center',
      margin: 0.005,
      borderRadius: 0.05,
      backgroundColor: new THREE.Color(0xffffff),
    };

    const hoveredBotMenuAttributes = {
      state: 'hovered',
      attributes: {
        offset: 0.005,
        backgroundColor: new THREE.Color(0x57A2FF),
        backgroundOpacity: 1,
        fontColor: new THREE.Color(0xffffff),
      },
    };

    const idleBotMenuAttributes = {
      state: 'idle',
      attributes: {
        offset: 0.01,
        backgroundColor: new THREE.Color(0x080808),
        backgroundOpacity: 0.3,
        fontColor: new THREE.Color(0x000),
      },
    };

    this.pageNavButtons = {};
    const buttonsNames = ['Buy Now', 'Cart'];

    buttonsNames.forEach((btn, i) => {
      if (btn === 'Buy Now') {
        this.pageNavButtons[`buttonCol_${i}`] = new ThreeMeshUI.Block(BotButtonOptions);
      } else if (btn === 'Cart') {
        this.pageNavButtons[`buttonCol_${i}`] = new ThreeMeshUI.Block(BotCartOptions);
      }
      const item = this.pageNavButtons[`buttonCol_${i}`];
      item.name = `buttonCol_${i}`;

      const selectStateCategories = {
        state: 'selected',
        attributes: {
          offset: 0.001,
          backgroundColor: new THREE.Color(0x2586FF),
        },
        onSet: () => {
          this.botButton(btn);
        },
      };
      if (btn === 'Buy Now') {
        item.add(new ThreeMeshUI.Text({ content: btn }));
      } else if (btn === 'Cart') {
        this.textureLoader.load(`./video_controller_icons/${btn}.svg`, (texture) => {
          item.set({ backgroundTexture: texture });
        });
      }

      item.setupState(selectStateCategories);
      item.setupState(hoveredBotMenuAttributes);
      item.setupState(idleBotMenuAttributes);

      buttonCol.add(item);
      this.objsToTest.push(item);
    });
  }

  updateButtons() {
    ThreeMeshUI.update();
    if (!this.containerGlobal) return;
    if (this.scene.playerWatchingVideo) return;

    const cameraPosition = this.getTargetPosition();

    // look at
    this.containerGlobal.lookAt(cameraPosition);

    let termDist;
    if (this.inVR) {
      termDist = 4;
    } else {
      termDist = 3;
    }

    if (this.containerGlobal.position.distanceTo(cameraPosition) > termDist) {
      this.terminate();
    }

    let intersect;

    if (this.renderer.xr.isPresenting) {
      this.vrControl.setFromController(0, this.raycaster.ray);

      intersect = this.raycast();

      // Position the little white dot at the end of the controller pointing ray
      // if ( intersect ) this.vrControl.setPointerAt( 1, intersect.point );
    } else if (this.mouse.x !== null && this.mouse.y !== null) {
      this.raycaster.setFromCamera(this.mouse, this.realCamObj);

      intersect = this.raycast();
    }

    if (intersect && intersect.object.isUI) {
      if (this.selectState) {
        // Component.setState internally call component.set with the options you defined in component.setupState
        intersect.object.setState('selected');
      } else {
        // Component.setState internally call component.set with the options you defined in component.setupState
        intersect.object.setState('hovered');
      }
    }

    this.objsToTest.forEach((obj) => {
      if ((!intersect || obj !== intersect.object) && obj.isUI) {
        // Component.setState internally call component.set with the options you defined in component.setupState
        obj.setState('idle');
      }
    });
  }

  raycast() {
    return this.objsToTest.reduce((closestIntersection, obj) => {
      const intersection = this.raycaster.intersectObject(obj, true);

      if (!intersection[0]) return closestIntersection;

      if (!closestIntersection || intersection[0].distance < closestIntersection.distance) {
        intersection[0].object = obj;

        return intersection[0];
      }

      return closestIntersection;
    }, null);
  }

  terminate() {
    this.obj.annoExist = false;
    if (!this.containerGlobal) return;

    this.containerGlobal.clear();
    this.scene.remove(this.containerGlobal);

    this.containerGlobal.update(true, true, true);
    this.objsToTest = [];
    this.containerGlobal = undefined;
    this.scene.rayCastGroup.splice(this.scene.rayCastGroup.indexOf(this.containerGlobal), 1);

    window.requestAnimationFrame(() => {
      this.terminate();
    });
  }

  botButton(btn) {
    console.log(btn);
    if (btn === 'Buy Now') {
      // this.terminate();
      this.ecommerceEvents.buttonEvents(this.obj.annoName, btn);
    } else if (btn === 'Cart') {
      // this.terminate();
      this.ecommerceEvents.buttonEvents(this.obj.annoName, btn);
    }
  }
}

export { ProductAnno };
