import EventEmitter from 'eventemitter3';
import { NetworkId, OnJoinedRoomPayload, OnLeftRoomPayload, OnMessagePayload, Transport } from './types';
import NetworkObject, { NetworkObjectLifeTimeTypes, NetworkObjectSerialized, NetworkObjectStatus } from './NetworkObject';
import { SyncVariable, SyncVariableSerialized } from './SyncVariable';
import TransformVariable from './variables/TransformVariable';
import TransportPlutoNeo from './TransportPlutoNeo';
import AnimatorVariable from './variables/AnimatorVariable';
import { WorkerMessage, WorkerMessageTypes } from './messages/WorkerTypes';
import MessagesPool, { Message, MessageData } from './messages/MessagesPool';
import MessagesWorker from './messages/MesagesWorker';
import ReceiveMessagesPool from './messages/ReceiveMessagesPool';
import SendMessagesPool from './messages/SendMessagesPool';

export enum MessageTypes {
  sendOwnedObjects = 'sendOwnedObjects',
  successReceiveNewObject = 'successReceiveNewObject',
  broadcastVariable = 'broadcastVariable',
  broadcastVariables = 'broadcastVariables',
  removeUser = 'removeUser',
  ping = 'Ping',
  broadcastMessagesBatch = 'broadcastMessagesBatch',
}

export type NetworkManagerEventTypes = {
  onReceiveObjects: () => void;
  onReceiveVariables: () => void;
};

export type UserData = {
  id: NetworkId;
  joinedTime: number;
};

// TODO: move all network from engine
export default class NetworkManager {
  public transport: TransportPlutoNeo;

  public receiveMessagesPool: ReceiveMessagesPool;

  public sendMessagesPool: SendMessagesPool;

  public enabled = true;

  // public runHandler: NodeJS.Timer | null = null;

  protected _events: EventEmitter<NetworkManagerEventTypes> = new EventEmitter<NetworkManagerEventTypes>();

  public networkId: NetworkId;

  public isRoomHost = false;

  public currentRoomId? = '';

  public messagesWorker: Worker | MessagesWorker;

  public lagTimeMS = 1000;

  public lastLagTime = 0;

  public lagsCount = 0;

  public lagsLimit = 6;

  public variableTypes: { [key: string]: typeof SyncVariable<any> } = {
    [SyncVariable.type]: SyncVariable,
    [TransformVariable.type]: TransformVariable,
    [AnimatorVariable.type]: AnimatorVariable,
  };

  public objectsTypes: { [key: string]: typeof NetworkObject } = {
    [NetworkObject.type]: NetworkObject,
  };

  // TODO: need object User
  public onlineUsers: UserData[] = [];

  // TODO: create service objectCollection
  public objects: NetworkObject[] = [];

  constructor(transport: Transport) {
    this.transport = transport as TransportPlutoNeo;
    if (!transport.networkId) {
      console.error('No networkId');
    }
    this.receiveMessagesPool = new ReceiveMessagesPool();
    this.sendMessagesPool = new SendMessagesPool();
    // TODO: reconnect
    this.networkId = transport.networkId;
    this.currentRoomId = this.transport.client.room_id;
    this.setupNewUser(this.networkId);
    this.setupTransport();
    // this.syncUsersInRoom();

    this.messagesWorker = new Worker(new URL('./workers/Messages.worker', import.meta.url));
    // this.messagesWorker = new MessagesWorker();
    this.setupMessagesWorker();
  }

  public reconnect() {
    if (!this.enabled) return Promise.resolve();
    console.warn('reconnect');
    this.enabled = false;
    const wait = (tm: number) => new Promise((resolve) => {
      setTimeout(() => resolve(null), tm);
    });
    const ownObjects = this.objects.filter((obj) => obj.isOwner());
    const otherObjects = this.objects.filter((obj) => !obj.isOwner());
    return this.transport.reconnect()
      .then(() => {
        this.onlineUsers = this.onlineUsers.filter((user) => user.id === this.networkId);
        this.networkId = this.transport.networkId;
        ownObjects.forEach((obj) => obj.setOwner(this.networkId));
      })
      .then(() => wait(300))
      .then(() => this.transport.createOrJointRoom(this.currentRoomId || null))
      .then(() => {
        this.currentRoomId = this.transport.client.room_id;
        this.enabled = true;
        otherObjects.forEach((obj) => this.sendSuccessReceiveNewObject(obj));
        // this.messagesPool.flushMessages()
        //   .forEach((message) => this.onReceive({ message, sender: this.networkId }));
      })
      // .then(() => wait(1000))
      .then(() => this.syncUsersInRoom())
      .then(() => ownObjects.forEach((obj) => obj.reset()))
      .then(() => this.sendOwnedObjects());
  }

  public reconnectToRoom() {
    if (!this.enabled) return Promise.resolve();
    console.log('reconnect');
    this.enabled = false;
    return this.transport.leaveRoom().then(() => {
      console.log(this.currentRoomId);
      if (this.currentRoomId) {
        return this.transport.joinRoom(this.currentRoomId);
      }
    });
  }

  public get events(): EventEmitter<NetworkManagerEventTypes> {
    return this._events;
  }

  public setupMessagesWorker() {
    if (this.messagesWorker instanceof Worker) {
      this.messagesWorker.onmessage = this.onWorkerMessage.bind(this);
    }
    if (this.messagesWorker instanceof MessagesWorker) {
      this.messagesWorker.events.on('onMessage', this.onWorkerMessage, this);
    }
  }

  public onWorkerMessage(e: MessageEvent<WorkerMessage> | WorkerMessage) {
    const message: WorkerMessage = e instanceof MessageEvent<WorkerMessage> ? e.data : e;
    switch (message.type) {
      // case WorkerMessageTypes.tick:
      //   this.sendData(MessageTypes.ping, {});
      //   break;
      case WorkerMessageTypes.processMessage:
        this.processMessage(message.data);
        break;
      case WorkerMessageTypes.broadcastMessagesBatch:
        this.broadcastMessagesBatch(message.data);
        break;
      default:
    }
  }

  public run() {
    this.messagesWorker.postMessage({ type: WorkerMessageTypes.startWorker });
  }

  public stop() {
    this.messagesWorker.postMessage({ type: WorkerMessageTypes.stopWorker });
    this.messagesWorker.terminate();
  }

  public buildNetObject<Type extends NetworkObject>(type: string, build: ((obj: Type) => void) | undefined = undefined): Type {
    const obj = new this.objectsTypes[type](this).initialize() as Type;
    if (build) {
      build(obj);
    }
    this.objects.push(obj);
    this.sendOwnedObjects();
    // console.log('createNetObject');
    // console.log([...this.objects]);
    return obj;
  }

  public buildSharedObject<Type extends NetworkObject>(
    type: string, code: string, build: ((obj: Type) => void) | undefined = undefined,
  ): Type {
    const netObj = this.objects.find((obj) => obj.isShared && obj.code === code);
    if (netObj) {
      return netObj as Type;
    }
    return this.buildNetObject<Type>(type, (obj) => {
      obj.code = code;
      obj.lifeTimeType = NetworkObjectLifeTimeTypes.Shared;
      if (build) build(obj);
    });
  }

  public getObjectByCode<Type extends NetworkObject = NetworkObject>(code: string): Type {
    return this.objects.find((obj) => obj.code === code) as Type;
  }

  public receiveVariable(data: SyncVariableSerialized<any>, sendTime: number, receiveTime: number) {
    const object = this.objects.find((obj) => (obj.uuid === data.netObjectUuid));
    // TODO: think about it
    if (!object) return;
    let variable = object.getVariableByUuid(data.uuid);
    // crate new variable
    if (!variable) {
      variable = new this.variableTypes[data.type](data.name);
      object.addVariable<typeof variable.value>(variable);
    }
    variable.deserialize(data);
    variable.saveValueFromNetwork(data, sendTime, receiveTime);
    variable.setNeedUpdateFromNetwork();
    this.events.emit('onReceiveVariables');
  }

  public receiveNetObject(data: NetworkObjectSerialized) {
    let object = this.objects.find((obj) => obj.uuid === data.uuid);
    // new object
    let isNewObject = false;
    if (!object && data.status !== NetworkObjectStatus.Removed) {
      object = new this.objectsTypes[data.type](this);
      this.objects.push(object);
      isNewObject = true;
    }
    // update object
    if (object) {
      object.deserialize(data);
    }
    // remove object
    if (object && data.status === NetworkObjectStatus.Removed) {
      this.objects = this.objects.filter((obj) => obj.uuid !== data.uuid);
      object.remove();
    }

    // if (isNewObject && object) {
    //   this.sendSuccessReceiveNewObject(object);
    // }
    if (isNewObject && object) {
      this.sendSuccessReceiveNewObject(object);
    }
  }

  public receiveSuccessReceiveNewObject({ netObjectUid }: { netObjectUid: string }) {
    const object = this.objects.find((obj) => obj.uuid === netObjectUid);
    // TODO: send only to one user
    if (!object) return;
    this.broadcastVariables(object.variables);
    // object.variables.forEach((vr) => this.broadcastVariable(vr));
  }

  public setupTransport() {
    this.transport.events.on('onJoinedRoom', this.onJoinedRoom, this);
    this.transport.events.on('onMessage', this.onReceive, this);
    this.transport.events.on('onData', this.onReceive, this);
    this.transport.events.on('onLeftRoom', this.onLeftRoom, this);
  }

  // one session -> many rooms ?
  public onJoinedRoom({ roomId, clientId }: OnJoinedRoomPayload) {
    console.warn('onJoinedRoom', clientId, this.networkId);
    if (this.currentRoomId && this.currentRoomId !== roomId) {
      // TODO: not our room, strange
      console.warn('wrong room');
      return;
    }
    this.currentRoomId = this.currentRoomId || roomId;
    this.syncUsersInRoom().then(() => this.sendOwnedObjects());
  }

  public syncUsersInRoom() {
    if (!this.currentRoomId) return Promise.resolve();
    return this.transport.listRoomConnections(this.currentRoomId).then(({ roomId, connectionIds }) => {
      if (this.currentRoomId !== roomId) return;
      if (connectionIds.length === 1 && this.networkId === connectionIds[0]) {
        // TODO: need to wait this before generate scene
        this.isRoomHost = true;
        console.warn('host');
      }

      connectionIds.forEach((userId) => {
        if (!this.isUserOnline(userId)) {
          this.setupNewUser(userId);
        }
        this.onlineUsers.forEach((user) => {
          if (connectionIds.indexOf(user.id) < 0) {
            this.removeUser(user.id);
          }
        });
      });
    });
  }

  public onLeftRoom({ roomId, connectionId }: OnLeftRoomPayload) {
    if (this.currentRoomId !== roomId) return;
    if (this.networkId === connectionId) {
      // TODO: reconnect
    }
    this.removeUser(connectionId);
  }

  public removeUser(userId: NetworkId) {
    console.warn('removeUser', userId);
    // TODO: clear variables
    this.onlineUsers = this.onlineUsers.filter((user) => user.id !== userId);
    this.removeObjectsByOwner(userId);
    // this.sendOwnedObjectsVariables();
  }

  public removeObjectsByOwner(ownerId: NetworkId) {
    // TODO: reset owner in shared object (by time or by server)
    // TODO: network objects and variables need timing
    // const lowTime = Math.min(...this.onlineUsers.map((user) => user.joinedTime));
    const newOwnerId = this.networkId;// this.onlineUsers.find((user) => user.joinedTime === lowTime)?.id || this.networkId;
    // console.warn('removeObjectsByOwner');
    // console.log('newOwnerId', newOwnerId);
    // console.log('netId', this.networkId);
    if (newOwnerId) {
      this.objects
        .filter((obj) => obj.isShared && !this.isUserOnline(obj.ownerId))
        .forEach((obj) => {
          obj.setOwner(newOwnerId);
          obj.reset();
        });
    }

    this.objects.filter((obj) => !obj.isLife(ownerId)).forEach((obj) => obj.remove());
    this.objects = this.objects.filter((obj) => obj.isLife(ownerId));
    // console.log(this.objects);
    // this.sendOwnedObjects();
  }

  public isUserOnline(userId: NetworkId): boolean {
    return !!this.onlineUsers.find((user) => user.id === userId);
  }

  public setupNewUser(userId: NetworkId) {
    this.onlineUsers.push({
      id: userId,
      joinedTime: Math.floor(Date.now() / 1000),
    });
  }

  // TODO: move to data channel
  public sendOwnedObjects(userId: NetworkId | null = null) {
    // console.warn('sendOwnedObjects',
    //   this.objects.filter((obj) => obj.isOwner()).map((obj) => obj.code));
    const payload = this.objects.filter((obj) => obj.isOwner()).map((obj) => (
      {
        toUser: userId,
        object: obj.serialize(),
      }
    ));
    if (payload.length > 0) {
      this.sendMessage(MessageTypes.sendOwnedObjects, payload, true);
    }
  }

  public sendOwnedObjectsVariables() {
    this.objects.filter((obj) => obj.isOwner())
      .forEach((obj) => this.broadcastVariables(obj.variables));
  }

  public sendSuccessReceiveNewObject(object: NetworkObject) {
    const payload = {
      netObjectUid: object.uuid,
    };
    this.sendMessage(MessageTypes.successReceiveNewObject, payload, true);
  }

  public broadcastVariable<T>(variable: SyncVariable<T>) {
    // if (variable.required) {
    //   return this.sendMessage(MessageTypes.broadcastVariable, {
    //     variable: variable.serialize(),
    //   }, variable.required);
    // }
    return this.sendData(MessageTypes.broadcastVariable, {
      variable: variable.serialize(),
    }, variable.required);
  }

  public broadcastVariables<T>(variables: SyncVariable<T>[]) {
    const required = variables.some((vr) => vr.required);
    // if (required) {
    //   this.sendMessage(MessageTypes.broadcastVariables, {
    //     variables: variables.map((variable) => variable.serialize()),
    //   }, required);
    // }
    return this.sendData(MessageTypes.broadcastVariables, {
      variables: variables.map((variable) => variable.serialize()),
    }, required);
  }

  public sendRemoveUser(userId: NetworkId) {
    this.sendData(MessageTypes.removeUser, {
      userId,
    }, true);
  }

  public receiveRemoveUser(userId: NetworkId) {
    if (this.networkId === userId) {
      this.transport.closeSession(false).then(() => {
        // this.objects.forEach((obj) => obj.remove());
      });
      this.objects.forEach((obj) => obj.remove());
      this.objects = [];
    }
  }

  public sendMessage(type: string, payload: any, required = false) {
    if (!this.enabled) return;
    this.transport.sendMessageInRoom(MessagesPool.createMessage({
      type,
      required,
      payload,
    }));
  }

  public sendData(type: string, payload: any, required = false) {
    // console.log('sendData', type, payload, required);
    // if (payload.variables) console.log(payload.variables.map((vr: any) => vr.name));
    if (!this.enabled) return;

    const message = MessagesPool.createMessage({
      type,
      required,
      payload,
    });

    // TODO: think about it
    if (type === MessageTypes.broadcastVariable) {
      message.variableUid = payload.variable.uuid;
    }

    const workerMessage = { ...message };
    workerMessage.data = { type: message.data.type };

    this.sendMessagesPool.addMessage(message);
    this.messagesWorker.postMessage({ type: WorkerMessageTypes.sendMessage, data: workerMessage });

    // this.transport.sendDataInRoom(message);
  }

  public onReceive({ message, sender, serverSendTime }: OnMessagePayload) {
    message.serverSendTime = serverSendTime;
    // console.log('onReceive', message.data.type, message);
    // if (message.data.payload.variables) console.log(message.data.payload.variables.map((vr: any) => vr.type));
    if (!this.enabled) {
      this.receiveMessagesPool.saveMessage(message);
      return;
    }
    if (!message.data && !(<Message>message).data.type) return;
    // save message with payload in main thread
    this.receiveMessagesPool.receiveMessage(message);

    // TODO: serverSendTime
    // console.log('onReceive (local - local)', message.receiveTime - message.sendTime);
    // console.log('onReceive (local - server)', message.receiveTime - message.serverSendTime);

    const lagTime = message.receiveTime - message.serverSendTime;
    if (message.receiveTime - message.serverSendTime > this.lagTimeMS
      && lagTime > this.lastLagTime
      && this.objects.length > 0 && this.objects.every((obj) => obj.isInitialized)
    ) {
      this.lagsCount += 1;
      if (this.lagsCount >= this.lagsLimit) {
        this.reconnect().then(() => {
          console.log('reconnect complete');
          this.lagsCount = 0;
        });
        return;
      }
    }

    this.lastLagTime = lagTime;

    const workerMessage = { ...message };
    workerMessage.data = { type: message.data.type };
    this.messagesWorker.postMessage({ type: WorkerMessageTypes.receiveMessage, data: workerMessage });
  }

  public processMessage(message: Message) {
    // console.log('processMessage', message.data.type, message);
    // if (message.data.payload && message.data.payload.variables)
    // console.log(message.data.payload.variables.map((vr: any) => vr.type));

    let { data } = message;
    if (!data.payload) {
      const messageFromPool = this.receiveMessagesPool.getByUid(message.uid);
      data = (messageFromPool || { data: null }).data as MessageData;
    }
    if (!data || !data.payload) return;

    this.receiveMessagesPool.removeByUid(message.uid);

    // const timeLag = (message.receiveTime - message.sendTime) / 1000;
    // if (timeLag > 1) {
    //   console.log(`message ${message.data.type}: ${timeLag} sec lag`);
    // }
    switch (data.type) {
      case MessageTypes.sendOwnedObjects:
        data.payload.forEach(({ object, toUser } : { object: NetworkObjectSerialized; toUser: NetworkId }) => {
          // TODO: resolve by server
          // if (toUser && this.networkId === toUser) {
          this.receiveNetObject(object);
          // }
        });
        // console.log('onReceiveObjects');
        // console.log([...this.objects]);
        this.events.emit('onReceiveObjects');
        break;
      case MessageTypes.successReceiveNewObject:
        this.receiveSuccessReceiveNewObject(data.payload);
        break;
      case MessageTypes.broadcastVariable:
        this.receiveVariable(
          data.payload.variable,
          message.serverSendTime + (message.timeDiff || 0),
          message.receiveTime,
        );
        break;
      case MessageTypes.broadcastVariables:
        data.payload.variables
          .forEach(
            (variable: SyncVariableSerialized<any>) => this.receiveVariable(
              variable,
              message.serverSendTime + (message.timeDiff || 0),
              message.receiveTime,
            ),
          );
        break;
      case MessageTypes.removeUser:
        this.receiveRemoveUser(data.payload.userId);
        break;
      case MessageTypes.broadcastMessagesBatch:
        data.payload.forEach((ms: Message) => {
          ms.serverSendTime = message.serverSendTime;
          this.onReceive({ message: ms, serverSendTime: message.serverSendTime, sender: '' });
        });
        break;
      default:
        console.warn(`Network: unknown message type ${data.type}`);
    }
  }

  public broadcastMessagesBatch(messages: Message[]) {
    const payload = messages.map((ms) => {
      const payloadMessage = this.sendMessagesPool.getByUid(ms.uid);
      // console.log(ms, payloadMessage);
      this.sendMessagesPool.removeByUid(ms.uid);
      return payloadMessage;
    });
    const message = MessagesPool.createMessage({
      type: MessageTypes.broadcastMessagesBatch,
      required: true,
      payload,
    });
    this.transport.sendDataInRoom(message);
  }
}
