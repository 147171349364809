import { FC, memo } from 'react';
import { IconsType } from './IconsType';

const MicrophoneIcon: FC<IconsType> = memo((props) => {
  const {
    width = '34',
    height = '34',
    color = '#FFFFFF',
  } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.9999 21.9C20.0939 21.9 22.5999 19.394 22.5999 16.3V8.6C22.5999 5.506 20.0939 3 16.9999 3C13.9059 3 11.3999 5.506 11.3999 8.6V16.3C11.3999 19.394 13.9059 21.9 16.9999 21.9Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.29004 13.709V16.089C6.29004 21.997 11.092 26.799 17 26.799C22.908 26.799 27.71 21.997 27.71 16.089V13.709"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.054 9.20197C16.314 8.73997 17.686 8.73997 18.946 9.20197"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.8799 12.1685C16.6219 11.9725 17.3919 11.9725 18.1339 12.1685"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 26.7988V30.9988"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
});

export default MicrophoneIcon;
