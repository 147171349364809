import { Vector3 } from 'three';
import { SyncVariable, SyncVariableSerialized } from '../SyncVariable';
import AnimatorBuffer from '../interpolation/AnimatorBuffer';
import { TransformVariableType } from './TransformVariable';
import { Entity } from '../../Entity';
import {AnimatorComponent} from "../../components/Animator.component";

export type AnimatorVariableType = {
  actionName: string;
  parameters: Record<string, boolean | number>;
};

export default class AnimatorVariable extends SyncVariable<AnimatorVariableType> {
  public static type = 'animator';

  public sendDataMaxAttempts = 10;

  public sendDataCurrentAttempts = 0;

  public buffer: AnimatorBuffer = new AnimatorBuffer();

  public required = false;

  public prevPosition: Vector3 | null = null;

  constructor(name = 'animator') {
    super(name);
  }

  saveValueFromNetwork(data: SyncVariableSerialized<AnimatorVariableType>, sendTime: number, receiveTime: number) {
    super.saveValueFromNetwork(data, sendTime, receiveTime);
    if (this.value) {
      this.buffer.setValue(this.value, receiveTime);
    }
  }

  getInterpolatedValue(t: number, ts: number, entity: Entity): AnimatorVariableType {
    // TODO: fix this shit
    if (!this.prevPosition) this.prevPosition = entity.getWorldPosition(new Vector3());
    const dist = entity.getWorldPosition(new Vector3()).distanceTo(this.prevPosition);
    if (dist < 0.01) {
      return { actionName: this.value && this.value.actionName === 'cheer' ? 'cheer' : 'idle', parameters: {} };
    }
    entity.getWorldPosition(this.prevPosition);

    // return this.buffer.buffer[0];
    return this.buffer.getValue(t - 300 - ts) as AnimatorVariableType;
  }

  // FIXME
  public isChanged(prevValue: AnimatorVariableType | null, newValue: AnimatorVariableType): boolean {
    // console.log(newValue, super.isChanged(prevValue, newValue));
    return super.isChanged(prevValue, newValue);
    // return true;
    // const isChanged = super.isChanged(prevValue, newValue);
    // // return isChanged;
    // if (isChanged) {
    //   this.sendDataCurrentAttempts = 0;
    //   return isChanged;
    // }
    // if (!isChanged && this.sendDataCurrentAttempts < this.sendDataMaxAttempts) {
    //   this.sendDataCurrentAttempts += 1;
    //   return true;
    // }
    // return isChanged;
  }
}
