import { useCallback, useState } from 'react';
import { Channel, DefaultGenerics, StreamChat } from 'stream-chat';

export interface UseCreateTextChatType {
  userId: string;
  userName: string;
  roomId: string;
}

export interface UseCreateTextChatResult {
  initializeChat: () => void;
  chatClient?: StreamChat<DefaultGenerics> | null;
  channelClient?: Channel<DefaultGenerics> | null;
  leaveChatHandler: () => void;
  isDisconnect: boolean;
}

const useCreateTextChatRoom = (props: UseCreateTextChatType): UseCreateTextChatResult => {
  const {
    userName = '',
    userId = '1',
    roomId,
  } = props;

  const [channelClient, setChannelClient] = useState<Channel<DefaultGenerics> | null>(null);
  const [chatClient, setChatClient] = useState<StreamChat<DefaultGenerics> | null>(null);
  const [isDisconnect, setIsDisconnect] = useState<boolean>(true);

  const initializeChat = async () => {
    const chat = await StreamChat.getInstance('t3n9sf8ctbve');
    await chat.connectUser(
      {
        id: userId,
        name: userName,
      },
      chat.devToken(userId),
    );
    const channel = chat.channel('messaging', roomId, {
      name: `Meet ${roomId}`,
    });

    channel.create();

    channel.watch()
      .then(() => {
        setIsDisconnect(false);
      });
    setChannelClient(channel);
    setChatClient(chat);
  };

  const leaveChatHandler = useCallback(() => {
    setIsDisconnect(true);
    chatClient?.disconnectUser();
  }, []);

  return {
    initializeChat,
    channelClient,
    leaveChatHandler,
    isDisconnect,
    chatClient,
  };
};

export default useCreateTextChatRoom;
