import { SyncVariable } from '../../../engine/network/SyncVariable';
import NetworkManager from '../../../engine/network/NetworkManager';

export type SpeakerVariableType = {
  isSpeaker: boolean;
};

export default class SpeakerVariable extends SyncVariable<SpeakerVariableType> {
  public static type = 'speaker';

  public static register(manager: NetworkManager) {
    manager.variableTypes[SpeakerVariable.type] = SpeakerVariable;
  }

  constructor(name = 'speaker') {
    super(name);
  }

  public getDefault():SpeakerVariableType {
    return {
      isSpeaker: false,
    };
  }

  public setSpeaker(value: boolean): void {
    this.value = {
      isSpeaker: value,
    };
  }

  public getSpeaker():SpeakerVariableType {
    return this.value ? { ...this.value } : this.getDefault();
  }
}
