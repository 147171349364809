import React, {
  FC, useCallback, useEffect, useState,
} from 'react';
import VoxeetSDK from '@voxeet/voxeet-web-sdk';
import { Snackbar } from '@mui/material';
import * as THREE from 'three';
import { IJoystickUpdateEvent } from 'react-joystick-component/build/lib/Joystick';
import { MathUtils } from 'three';
import { useAnimationFrame } from 'framer-motion';
import LeftSideComponent from './LeftSide/LeftSideComponent';
import RightSideComponent from './RightSide/RightSideComponent';
import useAudio from '../../hooks/audio/useAudio';
import useConference from '../../hooks/audio/useConference';
import useCreateTextChatRoom from '../../hooks/useCreateTextChatRoom';
import { Application } from '../../engine/Application';
import SceneInteraction from '../../Scene/SceneInteraction';
import useParticipants from '../../hooks/audio/useParticipants';
import { PlayerComponent } from '../../domain/components/Player.component';
import { SelectPlayerType } from '../../types/SelectPlayersType';
import { CameraComponent } from '../../engine/components/Camera.component';
import { TPControllerComponent } from '../../domain/components/TPController.component';
import useMediaQuery from '../../hooks/useMediaQuery';
import { devices } from '../../types/devices';
import { PlayerPosition } from '../../constans/PlayerPosition';
import { setupEnvironment } from '../../domain/scenes/helpers/setupEnvironment';
import SnackbarNotification from '../../components/NotificationSnackbar/NotificationSnackbar';

export interface BottomActionsProps {
  userId?: string;
  userName?: string;
  roomId?: string;
  isAdmin?: boolean;
  app: Application | null;
}

const SportActions: FC<BottomActionsProps> = (props) => {
  const {
    roomId = '',
    isAdmin = false,
    userName = '',
    userId = '',
    app,
  } = props;
  const sceneIntersections = new SceneInteraction(app);
  const [isOpenSnackbar, setIsOpenSnackbar] = useState<boolean>(false);
  const [isChatOpen, setIsChatOpen] = useState<boolean>(false);
  const [isOpenUnion, setIsOpenUnion] = useState<boolean>(false);
  const [player, setPLayer] = useState<SelectPlayerType>({
    ownerId: '',
    isClick: false,
    networkId: '',
  });
  const [isClickAudio, setIsCLickAudio] = useState(false);
  const [isClickLogout, setIsClickLogout] = useState<boolean>(false);
  const [isClickCamera, setIsClickCamera] = useState<boolean>(false);
  const { isConference } = useConference();
  const { device } = useMediaQuery();
  const [velocity, setVelocity] = useState({ x: 0, y: 0 });
  const [playerWatchingVideo, setPlayerWatchingVideo] = useState<boolean>(false);
  const { closeSession, leaveConference, setIsConference } = useConference();
  const [isHideNotification, setIsHideNotification] = useState(false);
  const { startParticipantAudio, stopParticipantAudio } = useAudio();
  const { participants, kickParticipantHandler, participantsStatus } = useParticipants();
  const [isDay, setIsDay] = useState<boolean>(true);

  const closeUnionModalHandler = useCallback(() => {
    setIsOpenUnion(false);
  }, [isOpenUnion]);

  useEffect(() => {
    if (player && app !== null && isClickLogout) {
      if (player.ownerId) {
        const participantItem = participants.find((value) => value.info.externalId === player?.ownerId);
        if (participantItem && participantItem.id) {
          kickParticipantHandler(participantItem);
          setIsClickLogout(false);
        }
      }
    }
  }, [app, player, isClickLogout]);

  useEffect(() => {
    if (device === devices.mobile) setIsHideNotification(true);
    else setIsHideNotification(false);
  }, [device]);

  const changeFullScreenHandler = useCallback(() => {
    if (isHideNotification) {
      if (document.body.requestFullscreen) document.body.requestFullscreen();
      setIsHideNotification(false);
    }
  }, [isHideNotification]);

  useEffect(() => {
    if (player && app !== null && isClickAudio) {
      if (player.ownerId) {
        const participantItem = participants.find((value) => value.info.externalId === player?.ownerId);
        if (participantItem && participantItem.id) {
          const { isLocalAudio } = participantsStatus[participantItem.id];
          setIsCLickAudio(false);
          if (isLocalAudio) stopParticipantAudio(participantItem);
          else startParticipantAudio(participantItem);
        }
      }
    }
  }, [player, app, isClickAudio]);

  useAnimationFrame(() => {
    if (sceneIntersections.app !== null) {
      sceneIntersections.app.componentManager.getComponentsByType(PlayerComponent).forEach((component) => {
        if (component.isLogoutClick) setIsClickLogout(true);
        if (component.isOffAudioClick) setIsCLickAudio(true);
      });
      sceneIntersections.app.componentManager.getComponentsByType(TPControllerComponent).forEach((component) => {
        const cameraEntity = component.getCameraEntityOrFail();
        const cameraComponent = cameraEntity.getComponentOrFail(CameraComponent);
        const lookVector = cameraComponent.threeCamera.getWorldPosition(new THREE.Vector3());
        sceneIntersections.lookAt(lookVector);
      });
    }
    const isWatch = sceneIntersections.checkPlayerIsWatching();
    setPlayerWatchingVideo(isWatch);
    sceneIntersections.checkPlayerIsSeated();
    if (velocity.x !== 0 || velocity.y !== 0) sceneIntersections.blockMove(true);
    sceneIntersections.moveCharacter(velocity.x, velocity.y);
    if (isConference) sceneIntersections.setHost(isAdmin);
    const characterEntity = app?.sceneManager.currentScene?.threeScene.children.find((item) => item.name === 'CharacterEntity');
    if (!characterEntity) return;
    const position = characterEntity && characterEntity.position !== null ? characterEntity.position : { x: PlayerPosition.x, y: PlayerPosition.y, z: PlayerPosition.z };
    const rotation = characterEntity ? characterEntity.rotation : { z: 0, y: 0, x: 0 };
    const x = (position !== null && position.x) ? PlayerPosition.x + position.x : PlayerPosition.x;
    const { y } = PlayerPosition;
    const z = (position !== null && position.z) ? PlayerPosition.z + position.z : PlayerPosition.z;
    if (participants.length > 0 && VoxeetSDK.session.participant && position !== null) {
      VoxeetSDK.conference.setSpatialPosition(VoxeetSDK.session.participant, { x, y, z });
    }
    if (participants.length > 0 && VoxeetSDK.session.participant.id) {
      VoxeetSDK.conference.setSpatialDirection(VoxeetSDK.session.participant, {
        x: 0,
        y: rotation.y * 60,
        z: 0,
      });
    }
  });

  useEffect(() => {
    if (isConference) {
      participants.forEach((participant) => {
        if (participant.info.name && participant.info.externalId) {
          sceneIntersections.updateName({
            name: participant.info.name,
            id: participant.info.externalId,
          });
        }
      });
    }
  }, [isConference, participants]);

  useEffect(() => {
    const clickHandler = () => {
      const playerCurrent = sceneIntersections.getPlayer();
      setPLayer(playerCurrent);
    };

    if (app !== null) document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  }, [app]);

  const closeChatModalHandler = useCallback(() => {
    setIsChatOpen(false);
  }, [isChatOpen]);

  const { toggleAudio, isAudio } = useAudio();

  const { chatClient, initializeChat, channelClient, leaveChatHandler, isDisconnect } = useCreateTextChatRoom({
    roomId,
    userId,
    userName,
  });

  useEffect(() => {
    if (isConference) initializeChat();
  }, [isConference]);

  const showSeatsHandler = useCallback((value: boolean) => {
    if (app !== null) sceneIntersections.showHideSeats(value);
  }, [app, sceneIntersections.scene]);

  useEffect(() => {
    if (isConference) {
      if (isChatOpen || isOpenUnion) sceneIntersections.blockScene(true);
      else sceneIntersections.blockScene(false);
    } else sceneIntersections.blockScene(true);
  }, [isChatOpen, isOpenUnion, isConference]);

  useEffect(() => {
    VoxeetSDK.conference.on('participantUpdated', () => {
      if (!VoxeetSDK.session.participant.info.name && VoxeetSDK.session.participant.status === 'Kicked') {
        try {
          closeSession()
            .then(() => {
              setIsOpenSnackbar(true);
              leaveChatHandler();
              leaveConference();
              setIsConference(false);
            });
        } catch (e) {
          console.log(e);
        }
      }
      setIsOpenSnackbar(false);
    });
  }, []);

  const toggleCameraHandler = useCallback(() => {
    if (app !== null) {
      setIsClickCamera(!isClickCamera);
      sceneIntersections.toggleCamera();
    }
  }, [app, isClickCamera]);

  const joystickMoveHandler = useCallback((event: IJoystickUpdateEvent) => {
    if (event.x !== null && event.y !== null) {
      const x = MathUtils.mapLinear(event.x, -50, 50, -1, 1);
      const z = MathUtils.mapLinear(event.y, -50, 50, -1, 1);
      setVelocity({
        x,
        y: z * -1,
      });
    }
  }, []);

  const toggleNightHandler = useCallback(() => {
    setIsDay(!isDay);
  }, [isDay]);

  useEffect(() => {
    if (app !== null && app.sceneManager.currentThreeScene !== null) setupEnvironment(app, app.sceneManager.currentThreeScene, false, isDay);
  }, [isDay]);

  const throwStuffHandler = useCallback(() => {
    sceneIntersections.throwStuff();
  }, [app]);

  const joystickStopHandler = useCallback(() => {
    setVelocity({
      x: 0,
      y: 0,
    });
  }, []);

  return (
    <>
      <LeftSideComponent
        playerIsWatchVideo={playerWatchingVideo}
        device={device}
        onJoystickStop={joystickStopHandler}
        onJoystickMove={joystickMoveHandler}
        isCameraClick={isClickCamera}
        isAudio={isAudio}
        onShowSeats={showSeatsHandler}
        onToggleMicrophone={toggleAudio}
        onToggleCamera={toggleCameraHandler}
        isOpen={isChatOpen}
        onClickChatButton={() => {
          if (isConference) setIsChatOpen(!isChatOpen);
        }}
        isChatOpen={isChatOpen}
        onCloseChatModal={closeChatModalHandler}
        chatClient={chatClient}
        channelClient={channelClient}
        isDisconnect={isDisconnect}
      />
      <RightSideComponent
        onToggleNight={toggleNightHandler}
        onToggleCamera={toggleCameraHandler}
        isCameraClick={isClickCamera}
        onThrowStuff={throwStuffHandler}
        device={device}
        isConference={isConference}
        isAdmin={isAdmin}
        roomId={roomId}
        onCloseUnionModal={closeUnionModalHandler}
        isOpen={isOpenUnion}
        onUnionClick={() => {
          if (isConference) setIsOpenUnion(!isOpenUnion);
        }}
      />
      <Snackbar
        open={isOpenSnackbar}
        autoHideDuration={1000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        message="you were kicked"
      />
      <Snackbar
        open={isHideNotification}
        autoHideDuration={1000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        message={<SnackbarNotification
          title="Enable full screen mode"
          onChangeFullScreen={changeFullScreenHandler}
          onHideNotistack={setIsHideNotification}
        />}
      />
    </>
  );
};

export default SportActions;
