import client from '../client';
import { GetTokenResponseType } from '../../../types/ResponseTypes';

const getDolbyToken = (): Promise<GetTokenResponseType> => {
  return client.post('/token', {
    grant_type: 'client_credentials',
  }, {
    headers: {
      Authorization: 'Basic YVhxMVB5eFdnb0RxWUd4MVpnVTZLZz09OmY5SHlzXzVuSDhqR2k4a2JCOVY4OGdrNklOcl9DeGxZWFN2VnFKVVhuTVk9',
    },
  });
};

export default getDolbyToken;
