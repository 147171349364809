import { useState } from 'react';
import VoxeetSDK from '@voxeet/voxeet-web-sdk';
import { SpatialAudioStyle } from '@voxeet/voxeet-web-sdk/types/models/SpatialAudio';
import useConference from './useConference';
import { PlayerPosition } from '../../constans/PlayerPosition';

export interface UseCreateVoiceChatRoomResult {
  rejoinGroupHandler: (roomId: string, userName: string, userId: string) => void;
  leaveGroupHandler: () => void;
  joinInfo: string;
  setJoinInfo: (value: string) => void;
  finishRoomId?: string;
}

const useCreateVoiceChatRoom = (): UseCreateVoiceChatRoomResult => {
  const myCoordinates = { x: PlayerPosition.x, z: PlayerPosition.z, y: PlayerPosition.y };
  const right = { x: 1.00, y: 0, z: 0 };
  const up = { x: 0, y: 1.00, z: 0 };
  const forward = { x: 0, y: 0, z: 1.00 };
  const scale = { x: 0.18, y: 0.18, z: 0.18 };

  const [joinInfo, setJoinInfo] = useState<string>('');

  const { setIsConference } = useConference();

  const rejoinGroupHandler = async (roomId: string, userName: string, userId: string) => {
    try {
      await VoxeetSDK.session.open({
        name: userName,
        externalId: userId,
        ownerId: userId,
      });

      const conference = await VoxeetSDK.conference.create({
        alias: roomId,
        params: {
          spatialAudioStyle: 'shared' as SpatialAudioStyle,
        },
      });
      console.log(1)
      await VoxeetSDK.conference.join(conference, {
        spatialAudio: true,
      }).then(() => {
        VoxeetSDK.conference.setSpatialPosition(VoxeetSDK.session.participant, myCoordinates);
        VoxeetSDK.conference.setSpatialDirection(VoxeetSDK.session.participant, { x: 0, y: 0, z: 0 });
        VoxeetSDK.conference.setSpatialEnvironment(scale, forward, up, right);
      })
        .then(() => {
          setJoinInfo(VoxeetSDK.session.participant.id);
        });

      setIsConference(true);
    } catch (e) {
      console.log('Conference is already opened');
    }
  };

  const leaveGroupHandler = async () => {
    try {
      VoxeetSDK.conference.leave();
      setIsConference(false);
    } catch (error) {
      console.log('participant left');
    }
  };

  return {
    rejoinGroupHandler,
    leaveGroupHandler,
    setJoinInfo,
    joinInfo,
  };
};

export default useCreateVoiceChatRoom;

