import * as THREE from 'three';
import { Vector3 } from 'three';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
import ThreeMeshUI from 'three-mesh-ui';
import Stats from 'three/examples/jsm/libs/stats.module';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { btSequentialImpulseConstraintSolver } from 'ammo.js';
import { MeshUI } from './interface.js';
import { ProductAnno } from './productAnno.js';
import { Extra } from './extra.js';
// import { CreateVideo } from './createVideo.js';
import { SeatingPlace } from './seatingPlace.js';
import { VideoScene } from './videoScene.js';
import { ProductsInCenter } from './ProductsInCenter.js';
import { EcommerceEvents } from './EcommerceEvents.js';
import { WallOfEcommerce } from './WallOfEcommerce.js';

// Post

// JSONs
import seating_places from '../../assets/json/seating_places.json';
import lightMapPaths from '../../assets/json/light_map.json';
import annoLib from '../../assets/json/annoLib.json';
import randomSport from '../../assets/json/randomSport.json';
import demoShop from '../../assets/json/demo_shop_ui.json';
import { System } from '../../engine/System';
import { ContainerScene } from '../../domain/scenes/ContainerScene';
import { RigidBodyComponent } from '../../engine/components/RigidBody.component';
import RaycastComponent from '../../domain/components/Raycast.component';
import VideoTextureComponent from '../../domain/components/VideoTexture.component';
import { prerenderScene } from '../../domain/helpers/prerenderScene';
import { TPControllerComponent } from '../../domain/components/TPController.component';
import { FPControllerComponent } from '../../domain/components/FPController.component';
import { XRFPControllerComponent } from '../../domain/components/XRFPController.component';

const APP = window.web3devApp;

// check if run on local
const devMode = process.env.NODE_ENV === 'development';
// var devMode = false;

let effectFXAA;

const beforeSeatPosition = new Vector3();

const prevGamePads = new Map();
const kkk = 10;
const speedFactor = [kkk * 0.5, kkk * 0.5, kkk * 0.5, kkk * 0.5];
const cameraVector = new THREE.Vector3();

let toVidDist; let
  toAnnoDist;
let selectedObjects = [];
const stats = new Stats();
let vidTex = [];
let adTex = [];
let mouseDown = false;
const meshUIarr = [];
const annoUIarr = [];
const scCounter = 0;
const tempMatrix = new THREE.Matrix4();

// Definithons
const extra = new Extra();
const clock = new THREE.Clock();
const scene = APP.sceneManager.currentThreeScene;

const raycaster = new THREE.Raycaster();
const textureLoader = new THREE.TextureLoader(); // TODO: ImageBitmapLoader

stats.showPanel(0);
stats.domElement.style.display = 'none';

const videoContainerScene = new ContainerScene();
const mainScene = APP.sceneManager.currentScene;
const characterEntity = mainScene.threeScene.getObjectByName('CharacterEntity');
document.body.appendChild(stats.dom);
APP._videoContainerScene = videoContainerScene;
// Camera
const { camera } = APP;
const { cameraEntity } = mainScene;
// const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
// camera.rotation.order = 'YXZ';
const cameramaxangle = 0.6;

scene.rayCastGroup = [];
scene.annoLib = annoLib;
scene.seating_places = seating_places;
scene.randomSport = randomSport;
// scene.productsInCenterArr = productsInCenterArr;
scene.inVR = false;
scene.realCamObj = camera;
scene.playerSeated = false;
scene.playerWatchingVideo = false;
scene.block = false;

// Renderer
const { renderer } = APP;

// Video scene
export const videoScene = new VideoScene(scene.inVR);
scene.videoScene = videoScene;
scene.videoScene.init(scene.inVR);

videoContainerScene.camera = videoScene.camera;
videoContainerScene.threeScene = videoScene.scene;

const container = renderer.domElement;
// Environment
let envMap;
const pmremGenerator = new THREE.PMREMGenerator(renderer);
new RGBELoader()
  .load('./3d/hdr/fouriesburg_mountain_cloudy_1k.hdr', (texture) => {
    envMap = pmremGenerator.fromEquirectangular(texture).texture;
    texture.dispose();
    pmremGenerator.dispose();
  });

// const seatingPlace = new SeatingPlace(scene, camera, renderer);
// const randomSportStuff = new RandomSportStuff(scene, camera, renderer);
const productsInCenter = new ProductsInCenter(scene, camera, renderer);
const ecommerceEvents = new EcommerceEvents(renderer);

let isVisibleFPSPanel = false;

// POST
const { composer } = mainScene;
const { outlinePass } = mainScene;

// walk settings
// const GRAVITY = 30;
// const STEPS_PER_FRAME = 5;
// const worldOctree = new Octree();
// const playerCollider = new Capsule(new THREE.Vector3(0, 0.35, 0), new THREE.Vector3(0, 1.5, 0), 0.35);
// const playerVelocity = new THREE.Vector3();
// const playerDirection = new THREE.Vector3();
const spawnPoint = new Vector3(-13, 1.7, -2);

// playerCollider.start.set(spawnPoint.x, spawnPoint.y, spawnPoint.z);
// playerCollider.end.set(spawnPoint.x, spawnPoint.y + 1, spawnPoint.z);
// playerCollider.radius = 0.35;

// const playerOnFloor = true;
const keyStates = {};

// const dolly = new THREE.Object3D();

const dolly = characterEntity;
// dolly.add(mainScene.cameraEntity);

// characterEntity.add(dolly);
// characterEntity.add(playerCollider);
// scene.add(dolly);

// const dummyCam = new THREE.Object3D();
// camera.add(dummyCam);
// characterEntity.position.copy(playerCollider.end);
// characterEntity.rotation.set(0, 0, 0);
// characterEntity.getComponent(RigidBodyComponent).applyEntityWorldMatrix();

function takeASeat(obj, target) {
  scene.playerSeated = true;

  beforeSeatPosition.copy(dolly.position);

  characterEntity.getComponent(RigidBodyComponent).disable();

  if (dolly.seatingOnObject)dolly.seatingOnObject.visible = true;

  const seatwp = new Vector3();

  obj.getWorldPosition(seatwp);

  dolly.position.set(seatwp.x, seatwp.y + 0.3, seatwp.z);


  obj.visible = false;

  dolly.lookAt(scene.getObjectByName(target).position);
  dolly.rotation.y += Math.PI;
  dolly.rotation.x = 0;

  dolly.seatingOnObject = obj;

  const tPControllerComponent = dolly.getComponentOrFail(TPControllerComponent);
  const fPControllerComponent = dolly.getComponentOrFail(FPControllerComponent);
  const xrfPControllerComponent = dolly.getComponentOrFail(XRFPControllerComponent);
  tPControllerComponent.isInitialized = false;
  fPControllerComponent.isInitialized = false;
  xrfPControllerComponent.isInitialized = false;
  // tPControllerComponent.enabled = true;
  // tPControllerComponent.enabled = false;

  // FIXME: animation not working & need to disable controller updateAnimations
  // const animator = dolly.children[0].getComponent(AnimatorComponent);
  // animator.actionName = 'seating';
}

function getIntersections(controller) {
  tempMatrix.identity().extractRotation(controller.matrixWorld);
  raycaster.ray.origin.setFromMatrixPosition(controller.matrixWorld);
  raycaster.ray.direction.set(0, 0, -1).applyMatrix4(tempMatrix);
  return raycaster.intersectObjects(scene.rayCastGroup, true);
}

function onSelectStart(event) {
  const controller = event.target;

  const intersections = getIntersections(controller);

  if (intersections.length > 0) {
    const intersection = intersections[0];

    // Annotations
    if (intersection.object.haveAnno) {
      if (intersection.distance > toAnnoDist) return;

      if (intersection.object.annoExist === true) return;
      const annoUi = new ProductAnno(scene, dolly, camera, annoUIarr, renderer, intersection.object);
      // annoUi.anno(scene.productsInCenterArr[intersection.object.annoName]);
    }
    // Video
    if (intersection.object.addedVideo) {
      if (intersection.distance > toVidDist) return;

      // let video = intersection.object.addedVideo.video;
      // if (video.paused === true) {
      // video.play();
      // } else { video.pause(); }

      if (!intersection.object.meshUi) {
        intersection.object.meshUi = new MeshUI(scene, dolly, camera, meshUIarr, renderer, intersection.object.addedVideo);
      }
      intersection.object.meshUi.vidUI();
      console.log('video!!!');
    }
    // Seat
    if (intersection.object.isSeating) {
      if (dolly.seatingOnObject === intersection.object.name) return;

      takeASeat(intersection.object, intersection.object.seatingTarget);
    }
  }
}

function onSelectEnd(event) {
  const controller = event.target;

  // var intersections = getIntersections(controller);
}

const wallOfEcommerce = new WallOfEcommerce(scene, dolly, renderer);
wallOfEcommerce.generate();
// VR controllers
// controllers

const controller1 = renderer.xr.getController(0);
// dolly.add(controller1);
controller1.addEventListener('selectstart', onSelectStart);
controller1.addEventListener('selectend', onSelectEnd);
controller1.addEventListener('connected', (e) => {
  controller1.gamepad = e.data.gamepad;
});

const controller2 = renderer.xr.getController(1);
// dolly.add(controller2);
controller2.addEventListener('selectstart', onSelectStart);
controller2.addEventListener('selectend', onSelectEnd);
controller2.addEventListener('connected', (e) => {
  controller2.gamepad = e.data.gamepad;
});

window.parent.addEventListener('side_btn', (e) => {
  console.log(e.detail);
  if (e.detail.name === 'download') {
    showHideSeatings(e.detail.isActive);
  }
});

// Control keys
document.addEventListener('keydown', (event) => {
  if (!keyStates[event.code]) {
    keyStates[event.code] = { down: false, pressed: false, up: false, updatedPreviously: false };
  }
});

document.addEventListener('keyup', (event) => {
  if (keyStates[event.code]) {
    keyStates[event.code].pressed = false;
  }
});

container.addEventListener('mousedown', (event) => {
  if (!scene.block) mouseDown = true;
});
container.addEventListener('mouseup', () => {
  if (mouseDown && !scene.block) {
    mouseDown = false;
  }
});

window.addEventListener('mousemove', (event) => {
  if (mouseDown) {
    if (scene.playerWatchingVideo && APP !== null) {
      videoScene.camera.rotation.y -= event.movementY / 500;
      videoScene.camera.rotation.x -= event.movementY / 500;
      // videoScene.camera.rotation.x = Math.min(camera.rotation.x, cameramaxangle);
      // videoScene.camera.rotation.x = Math.max(camera.rotation.x, -cameramaxangle);
    } else {
      camera.rotation.y -= event.movementX / 500;
      camera.rotation.x -= event.movementY / 500;
      camera.rotation.x = Math.min(camera.rotation.x, cameramaxangle);
      camera.rotation.x = Math.max(camera.rotation.x, -cameramaxangle);
    }
  }

  raycaster.setFromCamera(
    {
      x: (event.clientX / renderer.domElement.clientWidth) * 2 - 1,
      y: -(event.clientY / renderer.domElement.clientHeight) * 2 + 1,
    },
    camera,
  );
  const intersects = raycaster.intersectObjects(scene.rayCastGroup, true);

  if (intersects.length > 0) {
    if (intersects[0].object.isSeating || intersects[0].object.haveAnno
		|| intersects[0].object.sportStuff || intersects[0].object.addedVideo) {
      container.style.cursor = 'pointer';

      const selectedObject = intersects[0].object;
      addSelectedObject(selectedObject);
      // outlinePass.selectedObjects = selectedObjects;
      selectedObjects.forEach((obj) => (obj.material?.color?.fromArray([0, 0.9, 1])));
    }
  } else {
    if (!APP.componentManager.getComponentsByType(RaycastComponent).find((cm) => cm.isActive)) {
      container.style.cursor = 'default';
    }
    addSelectedObject(null);
    // outlinePass.selectedObjects = [];
  }
});

document.body.addEventListener('keydown', (event) => {
  if (event.code === 'KeyF') {
    isVisibleFPSPanel = !isVisibleFPSPanel;
    stats.domElement.style.display = isVisibleFPSPanel ? 'block' : 'none';
  }
});

const vibrateController = (index) => {
  if (!scene.inVR) return;

  return;

  if (index === 0) {
    controller1.gamepad.hapticActuators[0].pulse(0.3, 100);
  } else {
    controller2.gamepad.hapticActuators[0].pulse(0.3, 100);
  }
};

let oldIntercestHover;
function vrControllersMove(controller) {
  if (!scene.inVR) return;

  const intersects = getIntersections(controller);

  if (intersects.length > 0) {
    if (intersects[0].object.isSeating || intersects[0].object.haveAnno
		|| intersects[0].object.sportStuff || intersects[0].object.addedVideo) {
      if (oldIntercestHover === intersects[0].object) return;
      oldIntercestHover = intersects[0].object;
      vibrateController(0);
    }
  } else {
    oldIntercestHover = undefined;
  }
}

window.addEventListener('click', (event) => {
  raycaster.setFromCamera(
    {
      x: (event.clientX / renderer.domElement.clientWidth) * 2 - 1,
      y: -(event.clientY / renderer.domElement.clientHeight) * 2 + 1,
    },
    camera,
  );
  const found = raycaster.intersectObjects(scene.rayCastGroup, true);

  // For Video
  if (found.length < 1) return;
  if (found[0].object.addedVideo) {
    if (found[0].distance > toVidDist) return;

    if (!found[0].object.meshUi)found[0].object.meshUi = new MeshUI(scene, dolly, camera, meshUIarr, renderer, found[0].object.addedVideo);
    found[0].object.meshUi.vidUI();
  }
  // For annotations
  if (found[0].object.haveAnno) {
    if (found[0].distance > toAnnoDist) return;

    if (found[0].object.annoExist === true) return;
    const annoUi = new ProductAnno(scene, dolly, camera, annoUIarr, renderer, found[0].object);
    annoUi.anno(scene.productsInCenterArr[found[0].object.annoName]);
  }
  // Seat
  if (found[0].object.isSeating) {
    if (dolly.seatingOnObject === found[0].object.name) return;

    takeASeat(found[0].object, found[0].object.seatingTarget);
  }
});

function onWindowResize() {
  camera.aspect = window.innerWidth / window.innerHeight;
  camera.updateProjectionMatrix();

  renderer.setSize(window.innerWidth, window.innerHeight);
}

window.addEventListener('resize', onWindowResize);

window.addEventListener('resize', onWindowResize);

renderer.xr.addEventListener('sessionstart', (event) => {
  scene.inVR = true;
  console.log('enter vr');
  // dolly.position.copy(playerCollider.start);
});

renderer.xr.addEventListener('sessionend', (event) => {
  scene.inVR = false;
  console.log('exit vr');
  // camera.position.set(0, 0, 0);
});

// load rooms

function afterScenesLoaded() {
  console.log('really');
  // dolly.position.y = -26;
  // teleportPlayerIfOob();

  window.dispatchEvent(new CustomEvent('scene_loaded', {}));

  // Ecommerce wall

  // Seating places
  // seatingPlace.generate();

  // Random sport stuff
  // randomSportStuff.generate();

  // 5 itmes in center to generate
  productsInCenter.generate();

  // replace banners from json
  for (const key in adTex) {
    const obj = scene.getObjectByName(key);
    if (obj === undefined) break;
    const adTexture = textureLoader.load(adTex[key]);
    adTexture.flipY = false;
    obj.material.map = adTexture;

    obj.material.needsUpdate = true;
    renderer.initTexture(adTexture);
  }

  // set lightMaps
  for (const key in lightMapPaths) {
    const object = scene.getObjectByName(key);
    if (object) {
      const lm_tex = textureLoader.load(lightMapPaths[key]);
      renderer.initTexture(lm_tex);
      object.material.lightMap = lm_tex;
      object.material.lightMapIntensity = 1.0;
      object.material.lightMap.flipY = false;
      object.material.needsUpdate = true;
    }
  }

  // set envMaps

  const listOfAdPlaces = [];
  listOfAdPlaces.push(scene.getObjectByName('adsOne'));
  listOfAdPlaces.push(scene.getObjectByName('adsTwo'));
  listOfAdPlaces.push(scene.getObjectByName('street_el_5').children[1]);

  function hexToRgb(hex) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16) / 256,
      g: parseInt(result[2], 16) / 256,
      b: parseInt(result[3], 16) / 256,
    } : null;
  }

  function random(number) {
    return Math.floor(Math.random() * number) + 1;
  }

  function randomColorFromList() {
    const color_list = ['#63B79D', '#7AB3DE', '#FFC4AB', '#F8E6FF', '#5C3748', '#E5CDC4'];
    return color_list[random(6)];
  }

  function set_color(obj) {
    obj.children[0].material.color = hexToRgb(randomColorFromList());
  }

  scene.getObjectByName('street__main').position.z = -23.99;

  for (let i = 1; i <= 9; i++) {
    const new_clone = scene.getObjectByName('street__main').clone();
    new_clone.position.z = -23.99 - i * 8;
    scene.add(new_clone);
  }

  ['ads', 'alpha_1', 'alpha_2', 'crossroad', 'Plane', 'adsOne', 'adsTwo'].forEach((item) => {
    const new_clone = scene.getObjectByName(item).clone();
    new_clone.rotation.y += Math.PI;
    new_clone.position.z = -23.99 - 9 * 8 - 23.98;
    if (item === 'adsOne' || item === 'adsTwo') listOfAdPlaces.push(new_clone);
    scene.add(new_clone);
  });

  ['street_5', 'street_6', 'street_3', 'street_5', 'street_6', 'street_3', 'street_5', 'street_6', 'street_3', 'street_5'].forEach((item, i) => {
    if (i === 0) {
      scene.getObjectByName(item).position.z = -23.99;
    } else if (i === 1) {
      scene.getObjectByName(item).position.z = -23.99 - 1 * 8;
    } else if (i === 2) {
      scene.getObjectByName(item).position.z = -23.99 - 2 * 8;
    } else {
      const new_clone = scene.getObjectByName(item).clone();
      new_clone.position.z = -23.99 - i * 8;
      scene.add(new_clone);
    }
  });

  ['street_el_1', 'street_el_5', 'street_el_4', 'street_el_1', 'street_el_5', 'street_el_4', 'street_el_1', 'street_el_3', 'street_el_4', 'street_el_5'].forEach((item, i) => {
    if (i === 0) {
      scene.getObjectByName(item).position.z = -23.99 - 2.5;
      scene.getObjectByName(item).position.x = -5;
    } else if (i === 1) {
      scene.getObjectByName(item).position.z = -23.99 - 2.5 - 1 * 8;
      scene.getObjectByName(item).position.x = -5;
    } else {
      const new_clone = scene.getObjectByName(item).clone();
      new_clone.position.z = -23.99 - 2.5 - i * 8;
      new_clone.position.x = -5;
      if (item === 'street_el_1') new_clone.rotation.y += Math.PI / Math.random();
      if (item === 'street_el_5') listOfAdPlaces.push(new_clone.children[1]);
      scene.add(new_clone);
    }
  });

  ['street_el_3', 'street_el_4', 'street_el_1', 'street_el_1', 'street_el_3', 'street_el_5', 'street_el_4', 'street_el_5', 'street_el_1', 'street_el_4'].forEach((item, i) => {
    if (i === 0) {
      scene.getObjectByName(item).position.z = -23.99 - 2.5;
      scene.getObjectByName(item).position.x = 4.7;
    } else if (i === 1) {
      scene.getObjectByName(item).position.z = -23.99 - 2.5 - 1 * 8;
      scene.getObjectByName(item).position.x = 4.7;
    } else {
      const new_clone = scene.getObjectByName(item).clone();
      new_clone.position.z = -23.99 - 2.5 - i * 8;
      new_clone.position.x = 4.7;
      if (item === 'street_el_1') new_clone.rotation.y += Math.PI / Math.random();
      if (item === 'street_el_5') listOfAdPlaces.push(new_clone.children[1]);
      scene.add(new_clone);
    }
  });

  [['shop_1', 'midwall_1'], ['shop_2', 'midwall_2'], ['shop_3', 'midwall_3'], ['shop_4', 'midwall_2'], ['shop_5', 'midwall_2'],
    ['shop_6', 'midwall_3'], ['shop_1', 'midwall_1'], ['shop_3', 'midwall_1'], ['shop_2', 'midwall_2'], ['shop_5', 'midwall_2']].forEach((item, i) => {
    item.forEach((item2, i2) => {
      const new_clone = scene.getObjectByName(item2).clone();
      new_clone.position.z = -24 - i * 8;
      new_clone.position.x = -11;
      if (i2 === 0) {
        new_clone.children[0].material = scene.getObjectByName(item2).children[0].material.clone();
        set_color(new_clone);
      } else {
        listOfAdPlaces.push(new_clone.children[1]);
      }
      scene.add(new_clone);
    });
  });

  [['shop_5', 'midwall_1'], ['shop_2', 'midwall_2'], ['shop_3', 'midwall_3'], ['shop_1', 'midwall_2'], ['shop_6', 'midwall_2'],
    ['shop_5', 'midwall_3'], ['shop_4', 'midwall_1'], ['shop_3', 'midwall_1'], ['shop_2', 'midwall_2'], ['shop_1', 'midwall_2']].forEach((item, i) => {
    item.forEach((item2, i2) => {
      const new_clone = scene.getObjectByName(item2).clone();
      new_clone.position.z = -24 - i * 8;
      new_clone.position.x = 11;
      if (i2 === 0) {
        new_clone.children[0].material = scene.getObjectByName(item2).children[0].material.clone();
        set_color(new_clone);
      } else {
        listOfAdPlaces.push(new_clone.children[1]);
      }
      new_clone.rotation.y = Math.PI;
      scene.add(new_clone);
    });
  });

  ['shop_1', 'shop_2', 'shop_3', 'shop_4', 'shop_5', 'shop_6', 'midwall_1', 'midwall_2', 'midwall_3'].forEach((item, i) => {
    if (!scene.getObjectByName(item)) return;
    scene.getObjectByName(item).visible = false;
  });

  scene.traverse((child) => {
    if (child.isMesh) {
      child.material.envMapIntensity = 0.85;
      if (child.material.map) {
        child.material.map.anisotropy = 4;
        // child.material.map.encoding = THREE.LinearEncoding;
      }
    }
  });
  renderer.toneMapping = THREE.ACESFilmicToneMapping;
  renderer.outputEncoding = THREE.sRGBEncoding;

  // replace video screens from json
  listOfAdPlaces.forEach((item, i) => {
    const video = document.createElement('video');

    video.autoplay = false;
    video.controls = false;
    video.loop = true;
    video.muted = true;
    video.style = 'display:none';
    if (video.canPlayType('video/mp4')) {
      video.src = `./ads/ad${random(3)}.mp4`;
    } else if (video.canPlayType('video/ogg')) {
      video.src = `./ads/ad${random(3)}.ogv`;
    }
    document.body.appendChild(video);

    const videoTexture = new THREE.VideoTexture(video);
    videoTexture.flipY = false;
    // renderer.initTexture(videoTexture);
    const videoMaterial = new THREE.MeshBasicMaterial({ map: videoTexture, side: THREE.FrontSide, toneMapped: false });

    item.material = videoMaterial;
    item.material.needsUpdate = true;
    video.play();
  });

  setTimeout(() => {
    prerenderScene(scene, camera, renderer);
  }, 2000);
}

function init() {
  (async () => {
    mainScene.events.once('sceneLoaded', () => {
      afterScenesLoaded();
    });

    mainScene.events.on('onProgress', ({ percent }) => {
      window.dispatchEvent(new CustomEvent('scene_prg', {
        detail: { prg: percent },
      }));
    });

    // JSON video textures
    if (!devMode) {
      function getRestApiData(endpoint = '') {
        return fetch(`https://dev.revereal.com/wp-json/configurator/v1/${endpoint}`).then((res) => res.json());
      }
      vidTex = await getRestApiData('rev_video');
      adTex = await getRestApiData('ad_image');
      scene.vidLib = await getRestApiData('rev_program');
      // scene.vidLib = await extra.readJSON('./../json/vidLib.json');
    } else {
      vidTex = await extra.readJSON('./json/videos.json');
      adTex = await extra.readJSON('./json/ad_images.json');
      scene.vidLib = await extra.readJSON('./json/vidLib.json');
    }

    // setTimeout(()=>{
    //     showHideSeatings(true);
    // }, 15000);
  })();
}

init();
// animate();

function offASeat() {
  console.log('offASeat');
  scene.playerSeated = false;

  characterEntity.getComponent(RigidBodyComponent).enable();

  dolly.seatingOnObject.visible = true;
  dolly.seatingOnObject = undefined;

  // FIXME: !!!!!!
  dolly.position.copy(beforeSeatPosition);
  dolly.rotation.set(0, 0, 0);
  // let seatwp = new Vector3();
  // obj.getWorldPosition(seatwp);

  // console.log(seatwp);
  // dolly.position.set(seatwp.x,seatwp.y + 0.3,seatwp.z);

  // obj.visible = false;
  // camera.lookAt(scene.getObjectByName(target).position);
  APP.render();
}

// function teleportPlayerIfOob() {
//   if (dolly.position.y <= -25) {
//     playerCollider.start.set(spawnPoint.x, spawnPoint.y, spawnPoint.z);
//     playerCollider.end.set(spawnPoint.x, spawnPoint.y + 1.6, spawnPoint.z);
//     if (scene.inVR === true)dolly.position.copy(playerCollider.start);
//     if (scene.inVR === false)dolly.position.copy(playerCollider.end);
//     dolly.rotation.set(0, 0, 0);
//   }
// }

// function playerCollisions() {
//   const result = worldOctree.capsuleIntersect(playerCollider);
//
//   playerOnFloor = false;
//
//   if (result) {
//     playerOnFloor = result.normal.y > 0;
//
//     if (!playerOnFloor) {
//       playerVelocity.addScaledVector(result.normal, -result.normal.dot(playerVelocity));
//     }
//
//     playerCollider.translate(result.normal.multiplyScalar(result.depth));
//   }
// }

function updatePlayer(deltaTime) {
  if (scene.playerSeated === true || scene.playerWatchingVideo === true) return;
  // let damping = Math.exp(-4 * deltaTime) - 1;
  //
  // if (!playerOnFloor) {
  //   playerVelocity.y -= GRAVITY * deltaTime;
  //   // small air resistance
  //   damping *= 0.1;
  // }

  // playerVelocity.addScaledVector(playerVelocity, 2.3 * damping);

  // playerVelocity.clampLength(-1, 1);
  const rb = characterEntity.getComponent(RigidBodyComponent);
  if (rb) {
    const vel = rb.btRigidBody?.getLinearVelocity();
    // console.log(vel.x(), vel.y(), vel.z());
    rb.setLinearVelocity({
      x: playerVelocity.x,
      y: Math.min(0, vel ? vel.y() : 0),
      z: playerVelocity.z,
    });
  }

  // const deltaPosition = playerVelocity.clone().multiplyScalar(deltaTime);
  // playerCollider.translate(deltaPosition);

  // playerCollisions();

  // if (scene.inVR === true)dolly.position.copy(playerCollider.start);
  // if (scene.inVR === false)dolly.position.copy(playerCollider.end);
}

function getForwardVector() {
  if (scene.inVR === true) {
    const oldRotation = mainScene.cameraEntity.rotation.clone();
    mainScene.cameraEntity.rotation.set(0, 0, 0);
    camera.getWorldDirection(playerDirection);
    mainScene.cameraEntity.rotation.copy(oldRotation);
  }
  if (scene.inVR === false)camera.getWorldDirection(playerDirection);

  playerDirection.y = 0;
  playerDirection.normalize();

  return playerDirection;
}

function getSideVector() {
  const forward = getForwardVector();
  forward.cross(camera.up);

  return forward;
}

const testWalk = false;

function playerControls(deltaTime) {
  // if (!renderer.xr.isPresenting) {
  //   playerVelocity.set(0, 0, 0);
  // playerVelocity.set(0, 0, 0);
  // }

  if (keyStates.Escape?.down) {
    if (scene.playerSeated) offASeat();
    if (scene.playerWatchingVideo) {
      scene.playerWatchingVideo = false;
      APP.sceneManager.loadScene(mainScene);
    }
  }

  // let playerSpeed = 0;
  // gives a bit of air control
  // if (scene.playerSeated === true || scene.playerWatchingVideo === true) {
  //   playerSpeed = 0;
  // } else {
  //   playerSpeed = 135;
  // }

  // let speedDelta = (1 / 60) * (playerOnFloor ? playerSpeed : (playerSpeed / 5));

  // Run mode by shift
  // if (keyStates.ShiftLeft?.pressed || keyStates.ShiftRight?.pressed) {
  //   speedDelta *= 2;
  // }
  // else { speedDelta = playerSpeed }

  // if(scene.inVR && controller1.gamepad){
  // 	playerVelocity.add(getForwardVector().multiplyScalar(speedDelta * controller1.gamepad.axes[3]));
  // 	playerVelocity.add(getSideVector().multiplyScalar(speedDelta * -controller1.gamepad.axes[2]));

  // 	dolly.rotation.y -= controller2.gamepad.axes[2]*2 / 500;
  // }

  // if (keyStates.KeyK?.down) {
  //   testWalk = !testWalk;
  // }
  // if (testWalk) {
  //   playerVelocity.add(getForwardVector().multiplyScalar(speedDelta));
  // }
  //
  // if (keyStates.KeyW?.pressed || keyStates.ArrowUp?.pressed) {
  //   playerVelocity.add(getForwardVector().multiplyScalar(speedDelta));
  // }
  //
  // if (keyStates.KeyS?.pressed || keyStates.ArrowDown?.pressed) {
  //   playerVelocity.add(getForwardVector().multiplyScalar(-speedDelta));
  // }
  //
  // if (keyStates.KeyA?.pressed || keyStates.ArrowLeft?.pressed) {
  //   playerVelocity.add(getSideVector().multiplyScalar(-0.8 * speedDelta));
  // }
  //
  // if (keyStates.KeyD?.pressed || keyStates.ArrowRight?.pressed) {
  //   playerVelocity.add(getSideVector().multiplyScalar(0.8 * speedDelta));
  // }

  // if (keyStates.KeyV?.down) {
  //   scene.playerWatchingVideo = true;
  //   APP.sceneManager.loadScene(videoContainerScene);
  // }
  // if (keyStates.KeyB?.down) {
  //   scene.playerWatchingVideo = false;
  //   APP.sceneManager.loadScene(mainScene);
  // }
  // GetPlayerPosition

  // if (playerOnFloor) {
  //   if (keyStates.Space?.pressed) {
  //     playerVelocity.y = 10;
  //   }
  // }

  // keyStates = {};
}

function addSelectedObject(object) {
  selectedObjects.forEach((obj) => (obj.material?.color?.fromArray([1, 1, 1])));
  selectedObjects = [];
  if (object) {
    selectedObjects.push(object);
  }
}

// Ne smog vinesti v module i ostavil zdes'
function checkControllersEvents() {
  let handedness = 'unknown';

  const session = renderer.xr.getSession();
  let i = 0;

  if (session) {
    // playerVelocity.set(0, 0, 0);
    for (const source of session.inputSources) {
      if (source && source.handedness) {
        handedness = source.handedness; // left or right controllers
      }
      if (!source.gamepad) continue;
      const controller = renderer.xr.getController(i++);
      const old = prevGamePads.get(source);
      const data = {
        handedness,
        buttons: source.gamepad.buttons.map((b) => b.value),
        axes: source.gamepad.axes.slice(0),
      };
      if (old) {
        data.buttons.forEach((value, i) => {
          // handlers for buttons
          if (value !== old.buttons[i] || Math.abs(value) > 0.8) {
            // check if it is 'all the way pushed'
            if (value === 1) {
              // button down
              if (data.handedness === 'left') {
                if (i === 5) {
                  if (scene.playerSeated) offASeat();
                  if (scene.playerWatchingVideo) {
                    scene.playerWatchingVideo = false;
                    APP.sceneManager.loadScene(mainScene);
                  }
                }
              } else if (i === 5) {
                if (scene.playerSeated) offASeat();
                if (scene.playerWatchingVideo) {
                  scene.playerWatchingVideo = false;
                  APP.sceneManager.loadScene(mainScene);
                }
              }
            } else {
              // button up

              // if (i == 1) {
              // 	if (data.handedness == "left") {

              // 	} else {

              // 	}
              // }
            }
          }
        });

        data.axes.forEach((value, i) => {
          // handlers for thumbsticks
          if (i === 2) {
            // left and right axis on thumbsticks
            if (data.handedness === 'right') {
              if (scene.playerSeated === true || scene.playerWatchingVideo === true) return;
              // playerVelocity.add(getSideVector().multiplyScalar(speedFactor[i] * data.axes[2]));

              // if (source.gamepad.hapticActuators && source.gamepad.hapticActuators[0] && value > 0.2) {
              // 	var didPulse = source.gamepad.hapticActuators[0].pulse(0.05,100);
              // }
            } else {
              // mainScene.cameraEntity.rotation.y -= data.axes[2] * 10 / 500;
              // if (source.gamepad.hapticActuators && source.gamepad.hapticActuators[0]) {
              // 	var didPulse = source.gamepad.hapticActuators[0].pulse(0.05,100);
              // }
            }
          }

          if (i == 3) {
            // up and down axis on thumbsticks
            if (data.handedness == 'right') {
              if (scene.playerSeated === true || scene.playerWatchingVideo === true) return;
              // playerVelocity.add(getForwardVector().multiplyScalar(-speedFactor[i] * data.axes[3]));

              // if (source.gamepad.hapticActuators && source.gamepad.hapticActuators[0] && value > 0.2) {
              // 	var didPulse = source.gamepad.hapticActuators[0].pulse(0.05,100);
              // }
            } else {

              // if (source.gamepad.hapticActuators && source.gamepad.hapticActuators[0]) {
              // 	var didPulse = source.gamepad.hapticActuators[0].pulse(0.05,100);
              // }
            }
          }
        });
      }
      prevGamePads.set(source, data);
    }
  }
}

function updateKeyboard() {
  for (const key in keyStates) {
    // insure that every keypress has "down" status exactly once
    if (!keyStates[key].updatedPreviously) {
      keyStates[key].down = true;
      keyStates[key].pressed = true;
      keyStates[key].updatedPreviously = true;
    } else {
      keyStates[key].down = false;
    }

    // key has been flagged as "up" since last update
    if (keyStates[key].up) {
      delete keyStates[key];
      continue; // move on to next key
    }

    if (!keyStates[key].pressed) {
      keyStates[key].up = true;
    }
  }
}

function attachVideo() {
  APP.componentManager.getComponentsByType(VideoTextureComponent).forEach((videoComponent) => {
    if (videoComponent.targetMesh && !videoComponent.targetMesh.addedVideo) {
      scene.rayCastGroup.push(videoComponent.targetMesh);
      // const newVideo = new CreateVideo(videoComponent.targetMesh, videoComponent);
    }
  });
}

// страшно
let currentPositionZ = 0;
function getRadius() {
  const characterEntitys = APP.sceneManager.currentScene?.threeScene.children.find((item) => item.name === 'CharacterEntity');
  const array = demoShop.pos.filter((value) => {
    const positionX = characterEntitys.position.x - value[0][0];
    const positionZ = characterEntitys.position.z - value[0][1];
    if (positionX < 4 && positionX > -4 && positionZ < 4 && positionZ > -4) {
      if (currentPositionZ !== value[0][1]) {
        currentPositionZ = value[0][1];
        wallOfEcommerce.container.visible = true;
        wallOfEcommerce.container2.visible = true;
        wallOfEcommerce.shopCatalog.visible = false;
        wallOfEcommerce.productInfo.visible = false;
        if (value[1] === 'left') {
          // todo: всунуть в массив
          wallOfEcommerce.container.rotation.y = -1.6;
          wallOfEcommerce.container2.rotation.y = -1.6;
          wallOfEcommerce.shopCatalog.rotation.y = -1.6;
          wallOfEcommerce.productInfo.rotation.y = -1.6;
          wallOfEcommerce.changePositionLeft({
            x: value[0][0],
            y: value[0][2],
            z: value[0][1],
          });
        } if (value[1] === 'right') {
          // todo: всунуть в массив
          wallOfEcommerce.container.rotation.y = 1.6;
          wallOfEcommerce.container2.rotation.y = 1.6;
          wallOfEcommerce.shopCatalog.rotation.y = 1.6;
          wallOfEcommerce.productInfo.rotation.y = 1.6;
          wallOfEcommerce.changePositionRight({
            x: value[0][0],
            y: value[0][2],
            z: value[0][1],
          });
        }
      }
      return value;
    }
  });
  if (array.length < 1) {
    wallOfEcommerce.container.visible = false;
    wallOfEcommerce.container2.visible = false;
    wallOfEcommerce.shopCatalog.visible = false;
    wallOfEcommerce.productInfo.visible = false;
    currentPositionZ = 0;
  }
}

class RenderSystem extends System {
  static get code() {
    return 'render';
  }

  onUpdate(dt) {
    super.onUpdate(dt);
    updateKeyboard();
    attachVideo();

    if (scene.inVR === false) ThreeMeshUI.update();

    const deltaTime = Math.min(0.05, dt); // / STEPS_PER_FRAME;
    const dtProductsInCenter = Math.min(0.005, dt);
    // we look for collisions in substeps to mitigate the risk of
    // an object traversing another too quickly for detection.
    if (scene.inVR === false) {
      toVidDist = 25;
      toAnnoDist = 10;
    } else {
      toVidDist = 15;
      toAnnoDist = 5;
    }
    if (!scene.block) playerControls(deltaTime);
    // updatePlayer(deltaTime);

    // for (let i = 0; i < STEPS_PER_FRAME; i++) {
    // playerControls(deltaTime);
    // updatePlayer(deltaTime);
    // teleportPlayerIfOob();
    // }
    getRadius();
    wallOfEcommerce.updateButtons();

    if (meshUIarr.length > 0) {
      meshUIarr.forEach((item, i) => {
        meshUIarr[i].updateButtons();
      });
    }
    if (annoUIarr.length > 0) {
      annoUIarr.forEach((item, i) => {
        annoUIarr[i].updateButtons();
      });
    }

    stats.update();

    // scene.videoScene.render();
    productsInCenter.update(dtProductsInCenter);
    // randomSportStuff.update(deltaTime);

    // seatingPlace.update(clock.getElapsedTime(), characterEntity.getWorldPosition(new Vector3()));

    vrControllersMove(controller1);

    checkControllersEvents();

    // playerCollider.start.copy(characterEntity.position);
    // playerCollider.end.set(characterEntity.position.x, characterEntity.position.y + 1, characterEntity.position.z);
  }

  destroy() {
    // this.app.camera = null;
  }
}

APP.systems.push(new RenderSystem({ app: APP }));
